import LearnosityQuestionEditor from './component';
import { connect } from 'react-redux';
import { postData } from '../../../../actions/api';
import { ImplementationType } from '../../../../utils/constants';

const mapStateToProps = (state: any, ownProps: any) => {
  const showingProblem = ownProps.type === ImplementationType.PROBLEM;
  const { currentProblemId, currentStepId } = state.readerNavigation;
  const { learnosityJson, graphJson } = showingProblem ? state.problemData : state.stepData;

  return {
    id: showingProblem ? currentProblemId : currentStepId,
    learnosityJson: ownProps.isGraphingTool ? graphJson : learnosityJson,
  }
}

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    saveQuestionJsonEdits: (id: number, newQuestionJson: any) => {
      const newQuestionJsonToSave = {
        ...newQuestionJson,
        response_id: undefined,
        is_math: undefined,
        instant_feedback: undefined,
      };
      const type = ownProps.isGraphingTool ? 'GRAPH' : ownProps.type.toUpperCase();
      const jsonFieldName = ownProps.isGraphingTool ? 'graphJson' : 'questionJson';

      dispatch(postData({
        type: `SAVE_${type}_EDITS`,
        params: {
          [`${ownProps.type}Id`]: id,
          [jsonFieldName]: JSON.stringify(newQuestionJsonToSave),
        },
        mutators: {
          onResolve: (resolveAction: any) => {
            return {
              ...resolveAction,
              body: newQuestionJson,
            }
          }
        }
      }));
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LearnosityQuestionEditor);
