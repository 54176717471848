import React from 'react';
import Switch, { SwitchProps } from '@material-ui/core/Switch';
import { createMuiTheme, MuiThemeProvider, makeStyles } from '@material-ui/core/styles';

interface IProps extends SwitchProps {
  leftOption: string;
  rightOption: string;
  checked: boolean;
  onChange: (event: any) => void;
}

const activelyLearnTheme = createMuiTheme({
  overrides: {
    MuiSwitch: {
      thumb: {
        backgroundColor: '#016cc6'
      },
      track: {
        backgroundColor: 'var(--al-blue) !important',
      }
    }
  }
});

const useStyles = makeStyles({
  switchBase: {
    '&.Mui-focusVisible': {
      '& > span > span': {
        outline: '2px solid var(--al-blue)',
        outlineOffset: '2px'
      }
    }
  }
});

const RadioButtonSwitch = (props: IProps): JSX.Element => {
  const {
    leftOption,
    rightOption,
    checked,
    ...rest
  } = props;
  const styles = useStyles();

  return (
    <MuiThemeProvider theme={activelyLearnTheme}>
      <label onClick={props.onChange}>{leftOption}</label>
      <Switch
        checked={checked}
        color="primary"
        disableRipple={true}
        inputProps={{
          'role': 'button',
          'aria-label': checked ? 'Completion Selected' : 'Grades Selected'
        }}
        classes={{
          switchBase: styles.switchBase
        }}
        {...rest} />
      <label onClick={props.onChange}>{rightOption}</label>
    </MuiThemeProvider>
  );
};

export default RadioButtonSwitch;
