import React from 'react'
import Strand from './strand';

const Scroll = ({
  data, favoriteProblemSetIds = [], toggleFavorite,
}: { 
  data: any;
  favoriteProblemSetIds?: number[];
  toggleFavorite: Function;
}): JSX.Element => {
  return data.map((x: any) =>
    <Strand
      key={x.id + x.name}
      {...x}
      favoriteProblemSetIds={favoriteProblemSetIds}
      toggleFavorite={toggleFavorite}
    />
  );
};

export default Scroll;