import React, { useState } from 'react';
import styles from './styles.module.css';
import Loading from '../common/loading/loading.component';
import { colors } from '../../utils/style';
import { Title, TabContext, ChartTabs, Tab, TabPanel } from 'sharedfrontend';
import LineChart from 'sharedfrontend/src/components/dataViz/LineChart';

const StudentKeyUsageChart = (props: any): JSX.Element => {
  const {
    classId,
    data,
    fetchData,
    selectedGradeLevel,
    startDate,
    selectedSchoolIds,
    endDate,
    chartTitle,
    benchmarkSource,
  } = props;
  const [value, setValue] = useState('0');

  React.useEffect(() => {
    fetchData({
      classId,
      gradeLevel: selectedGradeLevel,
      schoolIds: selectedSchoolIds,
      startDate,
      endDate,
      benchmarkSource,
    });
  }, [
    classId,
    fetchData,
    selectedGradeLevel,
    selectedSchoolIds,
    startDate,
    endDate,
    benchmarkSource,
  ]);

  if (!data) {
    return <Loading text="Key Usage Information" />;
  }

  const xAxisLabel = data.groupedBy;
  const { studentLogins, averageTimePerStudent, totalSkillsMastered, numTotalStudentLogins } = data.data;
  const totalStudentsGroupedByDate = {}, studentLoginsData = [], studentLoginsLabelData = [];

  studentLogins.forEach(logins => {
    totalStudentsGroupedByDate[logins.date] = logins.value;
    studentLoginsData.push(logins.value);
    studentLoginsLabelData.push(logins.date);
  });

  const getStudentLoginChart = () => {
    const loginsDatasets = [{
      label: '# of Students Logged In',
      fill: false,
      data: studentLoginsData,
      borderColor: colors.blue,
    }];

    const config = {
      data: loginsDatasets,
      labels: studentLoginsLabelData,
      options: {
        xAxisLabel,
        yAxisLabel: 'STUDENTS LOGGED IN',
        plugins: { datalabels: { display: false } },
      },
    }

    return {
      config,
      tabLabel: numTotalStudentLogins,
    };
  }

  const getAverageTimeChart = () => {
    const averageTimeDatasets = [{
      fill: false,
      data: averageTimePerStudent.map((item: any) => (item.value / 3600).toFixed(2)),
      borderColor: colors.borderBlue,
    }];

    const config = {
      data: averageTimeDatasets,
      labels: averageTimePerStudent.map((item: any) => item.date),
      options: {
        xAxisLabel,
        yAxisLabel: 'TIME (HOURS)',
        plugins: { datalabels: { display: false } },
      },
      tooltipOptionOverrides: {
        callbacks: {
          label: (tooltipItem: any) => {
            const label = `Average Time: ${new Date(tooltipItem.yLabel * 3600 * 1000).toISOString().substring(11, 16)}`;
            return label;
          },
        },
      },
    };

    const totalTimeSpentPerStudent = averageTimePerStudent.reduce((timeSpentData, averageTimeData) => {
      timeSpentData += averageTimeData.value * totalStudentsGroupedByDate[averageTimeData.date];

      return timeSpentData;
    }, 0);
    const totalTimeSpentPerStudentInHours =
      new Date((totalTimeSpentPerStudent / (numTotalStudentLogins || 1)) * 1000).toISOString().substring(11, 16);

    return {
      config,
      tabLabel: totalTimeSpentPerStudentInHours,
    };
  }

  const getSkillsMasteredChart = () => {
    const skillMasteredDatasets = [{
      label: '# of Skills Mastered',
      fill: false,
      data: totalSkillsMastered.map((item: any) => item.value),
      borderColor: colors.borderBlue,
    }];

    const config = {
      data: skillMasteredDatasets,
      labels: totalSkillsMastered.map((item: any) => item.date),
      options: {
        xAxisLabel,
        yAxisLabel: 'SKILLS MASTERED',
        plugins: { datalabels: { display: false } },
      }
    };

    return {
      config,
      tabLabel: totalSkillsMastered.reduce((total, skillsMastered) => {
        total += skillsMastered.value;

        return total;
      }, 0),
    };
  }

  const handleChange = (props: any, value?: any): void => {
    setValue(value);
  };

  const studentLoginsChart = getStudentLoginChart();
  const averageTimeChart = getAverageTimeChart();
  const skillsMasteredChart = getSkillsMasteredChart();

  return (
    <div className="keyUsage">
      {chartTitle && <Title className={styles.title} value={chartTitle} />}
      <TabContext value={value.toString()}>
        <ChartTabs variant="fullWidth" value={value} onChange={handleChange} aria-label={'aria-label'}>
          <Tab
            labelData={studentLoginsChart.tabLabel}
            label={'Total students logged in'}
            value="0"
          />
          <Tab
            labelData={averageTimeChart.tabLabel}
            label={'Total time spent per student'}
            value="1"
          />
          <Tab
            labelData={skillsMasteredChart.tabLabel}
            label={'Total skills mastered'}
            value="2"
          />
        </ChartTabs>
        <TabPanel value={'0'}>
          <LineChart config={studentLoginsChart.config} />
        </TabPanel>
        <TabPanel value={'1'}>
          <LineChart config={averageTimeChart.config} />
        </TabPanel>
        <TabPanel value={'2'}>
          <LineChart config={skillsMasteredChart.config} />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default StudentKeyUsageChart;
