import AssignmentTitle from './component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  const isOnStep = !!state.readerNavigation.currentStepId;
  const problemSetName = isOnStep ? state.stepData.problemSetName : state.problemSetData.problemSetName;

  return {
    problemSetName,
    problemNumber: state.problemSetData.problemIds.indexOf(state.readerNavigation.currentProblemId) + 1,
    isOnStep,
    isIntervention: state.problemSetData.isIntervention,
  }
}

export default connect(
  mapStateToProps,
)(AssignmentTitle);
