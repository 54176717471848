import Toolbar from './component';
import { connect } from 'react-redux';
import { ImplementationType } from '../../../../utils/constants';

const mapStateToProps = (state: any, ownProps: any) => {
  const isOnStep = !!state.readerNavigation.currentStepId;
  const { ttsOnly } = ownProps;

  const { formulaSheetUrl, graphJson, learnosityJson, showRuler, showProtractor } = isOnStep
    ? state.stepData
    : state.problemData;
  const questionType = isOnStep ? ImplementationType.STEP : ImplementationType.PROBLEM;

  return {
    features: ttsOnly ? [] : learnosityJson.features,
    formulaSheetUrl: ttsOnly ? null : formulaSheetUrl,
    showGraphingTool: ttsOnly ? false : !!graphJson,
    questionType: ttsOnly ? undefined : questionType,
    role: state.userData.role,
    showRuler,
    showProtractor,
    stepId: state.readerNavigation.currentStepId,
    problemId: state.readerNavigation.currentProblemId,
  };
};

export default connect(
  mapStateToProps,
  null,
)(Toolbar);
