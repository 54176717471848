import PrivateRoute from './component';
import { connect } from 'react-redux';
import { getData } from '../../actions/api';

const mapStateToProps = (state: any) => {
  return {
    userId: state.userData.userId
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchData: () => {
      dispatch(getData({
        type: 'USER_DATA',
      }));
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivateRoute);
