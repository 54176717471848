import React from 'react';
import { ReactSelect, SwitchTabs as TabSwitch, Tooltip } from 'sharedfrontend';
import MasteryData from './mastery/masteryProgress.container';
import Interventions from '../interventions/interventions.container';
import { updateHash } from '../../utils/iframe';
import { getDataPlain } from '../../actions/api';
import QuantileMeasure from './QuantileMeasure.component';
import styles from './styles.module.css';
import { ReactSelectStyles } from '../../utils/style';
import { parseQueryString, getStudentDisplayName } from '../../utils/common';
import DateRangePicker from '../common/dateRangePicker/dateRangePicker.container';
import Button from '../common/button/component';
import { FaPrint } from 'react-icons/fa';
import { ClassSource } from '../../utils/constants';

const tabs = [
  { label: 'Mastery Progress', key: 'mastery' },
  { label: 'Prerequisites', key: 'prerequisites' },
];

const View = (props: any) => {
  const { classId, studentUserId, selectedTab } = props;

  if (selectedTab === tabs[0].key) {
    return <MasteryData classId={classId} studentUserId={studentUserId} />;
  }
  else {
    return (
      <Interventions
        classId={classId}
        studentUserId={studentUserId}
        canFlip={false}
      />
    );
  }
};

const StudentView = (props: any) => {
  const {
    classId,
    selectedTab,
    studentUserId,
  } = props;

  return (
    <div className={styles.studentDataWrapper}>
      <div className={styles.filterContainer}>
        <QuantileMeasure classId={classId} studentUserId={studentUserId} />
      </div>
      <View
        selectedTab={selectedTab}
        classId={classId}
        studentUserId={studentUserId}
      />
    </div>
  );
};

class StudentData extends React.Component<any, any> {
  state = {
    selectedTab: this.props.match.params.tabName || tabs[0].key,
    selectedStudent: { value: 0, label: '' },
    suggestions: [],
    loading: true,
  }

  async componentDidMount() {
    const { studentUserId } = parseQueryString(this.props.location.search);
    const { classId } = this.props.match.params;

    if (this.props.isStudent) {
      this.setState({
        selectedStudent: { value: studentUserId, label: '' },
        loading: false
      });
      return;
    }

    const students = await getDataPlain({
      type: 'CLASS_STUDENT_LIST',
      params: { classId, isGoogleCourse: false },
      isALAPI: true,
    });
    const suggestions = students.map((student: any) => ({
      value: student.UserId,
      label: getStudentDisplayName(student.FirstName, student.LastName),
    }));

    const preSelectedStudent = studentUserId
      ? suggestions.find((student: any) => student.value === studentUserId)
      : suggestions[0];

    this.setState({ suggestions, selectedStudent: preSelectedStudent, loading: false });
    updateHash({ path: `/classes/${classId}/studentdata/${this.state.selectedTab}/?studentuserid=${studentUserId}` });
  }

  handleTabChange = (newTab: string) => {
    const { classId } = this.props.match.params;
    const { selectedTab, selectedStudent } = this.state;

    if (selectedTab !== newTab) {
      this.setState({ selectedTab: newTab });
      updateHash({ path: `/classes/${classId}/studentdata/${newTab}/?studentuserid=${selectedStudent.value}` });
    }
  }

  handleSelectStudentChange = (selectedStudent: any) => {
    this.setState({ selectedStudent });

    const { classId } = this.props.match.params;
    const { selectedTab } = this.state;
    updateHash({ path: `/classes/${classId}/studentdata/${selectedTab}/?studentuserid=${selectedStudent.value}` });
  }

  render() {
    const { ltiAccountSource } = this.props;
    const source = ltiAccountSource.find((s: any) => s === ClassSource.OPENLEARNING);
    const { classId } = this.props.match.params;
    const { loading, selectedStudent, selectedTab, suggestions } = this.state;
    const studentUserId = selectedStudent ? parseInt((selectedStudent as any).value, 10) : null;

    if (loading) {
      return (
        <div className={styles.studentDataWrapper}>
          <div className="ctaInfo">Loading...</div>
        </div>
      );
    }

    if (this.props.isStudent) {
      return (
        <StudentView
          classId={classId}
          selectedTab={selectedTab}
          studentUserId={studentUserId}
        />
      );
    }

    return suggestions.length > 0
      ? (
        <div className={styles.studentDataWrapper}>
          <div className={styles.filterContainer}>
            <div>
              <ReactSelect
                value={selectedStudent}
                suggestions={suggestions}
                onChange={this.handleSelectStudentChange}
                inputId="select-student"
                customSelectStyles={ReactSelectStyles}
              />
              <QuantileMeasure classId={classId} studentUserId={studentUserId} />
            </div>
            <div className={styles.buttonContainer}>
              <Button
                iconSize="small"
                additionalStyles={styles.hideForPrint}
                onClick={window.print}
              >
                <Tooltip title="Print"><span><FaPrint /></span></Tooltip>
              </Button>
              {selectedTab === tabs[1].key && <DateRangePicker />}
            </div>
          </div>
          <div className={`${styles.mathStudentDataHeader} ${styles.hideForPrint}`}>
            <TabSwitch
              tabs={tabs}
              selectedTab={selectedTab}
              onChange={this.handleTabChange}
            />
          </div>
          {studentUserId &&
            <View
              selectedTab={selectedTab}
              classId={classId}
              studentUserId={studentUserId}
            />
          }
        </div >
      )
      : (
        <div className={styles.studentDataWrapper}>
          {
            source === ClassSource.OPENLEARNING
              ? 
              <div className="ctaInfo">
                No assignments!
              </div>
              :
              <div className="ctaInfo">
                No students are in this class! Please go to the Roster tab and select &#34;Invite Students&#34;.
              </div>
          }
          
        </div>
      )
  }
};

export default StudentData;
