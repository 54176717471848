import { colors } from './style';
import { addDays } from './common';

export const masteryPercentCeilings = {
  insufficient: 0,
  beginning: 0.6,
  developing: 0.8,
  approaching: 0.9,
  mastery: 1,
};

export const masterySegments = [{
  label: 'Insufficient Data',
  ceiling: masteryPercentCeilings.insufficient,
  backgroundColor: colors.placeHolderGray,
  textColor: colors.black,
}, {
  label: 'Beginning',
  ceiling: masteryPercentCeilings.beginning * 100,
  backgroundColor: colors.beginningRed,
  textColor: colors.white,
}, {
  label: 'Developing',
  ceiling: masteryPercentCeilings.developing * 100,
  backgroundColor: colors.developingRed,
  textColor: colors.white,
}, {
  label: 'Approaching',
  ceiling: masteryPercentCeilings.approaching * 100,
  backgroundColor: colors.approachingGreen,
  textColor: colors.white,
}, {
  label: 'Mastery',
  ceiling: masteryPercentCeilings.mastery * 100,
  backgroundColor: colors.masteryGreen,
  textColor: colors.white,
}];

export const getMasterySegment = (mastery: number, numProblemsCompleted: number) => {
  if (isNaN(mastery) || numProblemsCompleted < 3) {
    return masterySegments[0];
  }
  else {
    const ceilings = masterySegments.map(x => x.ceiling);

    if (mastery < ceilings[1]) {
      return masterySegments[1];
    }
    else if (mastery < ceilings[2]) {
      return masterySegments[2];
    }
    else if (mastery < ceilings[3] || (mastery < ceilings[4] && numProblemsCompleted < 5)) {
      return masterySegments[3];
    }
    else {
      return masterySegments[4];
    }
  }
};

export const getStudentPercentInEachMasteryRange = (masteryData: any[]) => {
  const range: any = { noData: 0, needSupport: 0, struggling: 0, approaching: 0, mastered: 0 };
  const ceilings = masterySegments.map(x => x.ceiling);

  for (const { mastery, numProblemsCompleted } of masteryData) {
    if (isNaN(mastery)) {
      continue;
    }

    const masteryPercent = percent(mastery, 1);
    
    if (masteryPercent === null || numProblemsCompleted < 3) {
      range.noData += 1;
    }
    else if (masteryPercent < ceilings[1]) {
      range.needSupport += 1;
    }
    else if (masteryPercent < ceilings[2]) {
      range.struggling += 1;
    }
    else if (masteryPercent < ceilings[3] || (masteryPercent < ceilings[4] && numProblemsCompleted < 5)) {
      range.approaching += 1;
    }
    else {
      range.mastered += 1;
    }
  }

  const dataCount = sumObjValue(range);

  for (const key in range) {
    range[key] = percent(range[key], dataCount);
  }

  return [range.noData, range.needSupport, range.struggling, range.approaching, range.mastered];
};

export const convertFloatsToPercentages = (floatArray: number[]) => floatArray.map(x => percent(x, 1));

export const percent = (numerator: number, denominator: number) => {
  if (denominator === 0 || isNaN(numerator) || numerator === null) { 
    return null;
  }

  return Math.round((numerator / denominator) * 100);
};

const sumObjValue = (obj: any) => {
  let sum = 0;

  for (const key in obj) {
    sum += obj[key];
  }

  return sum;
};

export const replaceQuoteInCSVData = (value: any) => {
  return isNaN(value) ? '"' + value.replace(new RegExp('"', 'g'), '\\"') + '"' : value;
};

export const uniqBy = (arr: any[], predicate: any) => {
  const cb = typeof predicate === 'function' ? predicate : (o: any) => o[predicate];
  
  return [...arr.reduce((map, item) => {
    const key = (item === null || item === undefined) ? 
      item : cb(item);
    
    map.has(key) || map.set(key, item);
    
    return map;
  }, new Map()).values()];
};


export const getAssignmentsStartDueDate = () => {
  const startDate = new Date();
  const endDate = addDays(startDate, 14);

  return { 
    startDate: startDate.toISOString(), 
    dueDate: endDate.toISOString(),
  };
};