import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import MuiTabContext from '@material-ui/lab/TabContext';

interface TabContextProps {
  classes?: any;
  value: string;
}

const useStyles = makeStyles({
  root: {
    color: '#a1a1a1',
  }
})

const TabContext: typeof MuiTabContext = ((props: TabContextProps) => {
  const styles = useStyles();

  return <MuiTabContext classes={styles} {...props} />
});

export default TabContext
