import React, { useRef, useEffect, useState } from "react";
import { uniqBy } from "lodash";
import {
  weThePeoplePublisher,
  paidPublishers,
  defaultReadingSettings,
  defaultQuizAssessmentSettings,
  wtpTextWithFreeAccess,
  nonTextLearnEntityType,
  WritingCoachFeedbackStrategy,
} from "../../../../helpers/constants";
import { getNumOfLicensesParamsForWTP } from "../../Payment/PaymentModal/helper";
import ExcerptAndClassSelector from "./ExcerptAndClassSelector";
import LoadingWrapper from "../../../LoadingWrapper";
import { getLTISelectAssignmentAssignPopupTitle } from "../../../../helpers/lti";
import styles from "./styles.module.css";
import { formatIntoIsoString } from "../../../../helpers/dateTimeHelpers";
import {
  uniqueValues,
  groupByValue,
  getParamsInPascalCase,
} from "../../../../helpers/objectHelpers";
import { Modal } from "../../../modal";
import { showAlert } from "../../../alert";
import { fetchAssignLayerPopupData } from "../api";
import {
  LearnEntityInfo,
  UserData,
  ClassData,
  AssignLayerData,
  StudentAccessInfo,
  ClassStudentMap,
  ReadingSettings,
} from "../../types";
import StartAndDueDateSelector from "./StartAndDueDateSelector";
import AssignmentSettings from "./AssignmentSettings";
import AssignmentSettingsSelector from "../../AssignmentSettingsSelector";
import { Divider } from "../../../divider";
import AssignLayerJoyride from "./AssignLayerJoyride";

interface Props {
  hideAssignLayerPopup: Function;
  setUnAssignModalState: Function;
  setCreateExcerptPopupState: Function;
  setShowBehindPaywallModal: Function;
  setPurchaseLicensesPopupState: Function;
  assignToClasses: Function;
  setPaidTextToFreeClassConfirmationModal: Function;
  learnEntityInfo: LearnEntityInfo;
  userData: UserData;
  teacherClasses: ClassData[];
  handleReaderAccessError: Function;
  setPremiumBannerModal: Function;
  unAssignCallback?: Function;
  updatePopupSettings: Function;
}

function AssignLayerPopup({
  hideAssignLayerPopup,
  setUnAssignModalState,
  setCreateExcerptPopupState,
  setShowBehindPaywallModal,
  setPurchaseLicensesPopupState,
  assignToClasses,
  setPaidTextToFreeClassConfirmationModal,
  userData,
  learnEntityInfo,
  teacherClasses,
  handleReaderAccessError,
  setPremiumBannerModal,
  unAssignCallback,
  updatePopupSettings,
}: Props): JSX.Element {
  const [assignLayerData, setAssignLayerData] = useState<{
    fetched: boolean;
    data: AssignLayerData;
  }>({
    fetched: false,
    data: {
      isLicenseAvailableOnUnlimited: false,
      isFreeWTPAccess: false,
      sections: [],
      excerpts: [],
      classStudentMap: [],
      studentsData: [],
      isBehindPaywall: false,
      numOfLicensesNeeded: 0,
      shouldCopyLayer: false,
      existingAssignedStudentsCount: {},
      numTotalLicensesAvailable: null,
      assignedStudentLicenses: null,
      layerId: learnEntityInfo.layerId,
      recentAssignmentReadingSettingsPerClass: [],
    },
  });
  const [students, setStudents] = useState<
    {
      isSelected: boolean;
      classId: number;
      firstName: string;
      lastName: string;
      userId: number;
      teacherUserId?: number;
    }[]
  >([]);
  const [readingSettings, setReadingSettings] = useState(
    getDefaultReadingSettings()
  );
  const [
    useRecentAssignmentSettings,
    setUseRecentAssignmentSettings,
  ] = useState(false);
  const [recentAssignment, setRecentAssignment] = useState<{
    classId: number;
    readingSettings: ReadingSettings;
    assignmentId: number;
    assignmentTitle: string;
  } | null>(null);
  const modalEl = useRef<any>(null);

  const [selectedClassIds, setSelectedClassIds] = useState<number[]>([]);
  const [
    selectedTextSectionExcerptId,
    setSelectedTextSectionExcerptId,
  ] = useState<number | null>(null);
  const [newlySelectedClasses, setNewlySelectedClasses] = useState<any[]>([]);
  const [datePickerErrorText, setDatePickerErrorText] = useState<any>("");
  const [utcNow] = useState<any>(new Date().toISOString());
  const [assignmentDate, setAssignmentDate] = useState({
    startDate: utcNow,
    dueDate: null,
  });
  const [
    showRecentAssignmentSettings,
    setShowRecentAssignmentSettings,
  ] = useState(false);

  useEffect(() => {
    fetchAssignLayerPopupData({
      data: {
        layerId: learnEntityInfo.layerId,
        isUserLayer: learnEntityInfo.isUserLayer,
        learnEntityId: learnEntityInfo.learnEntityId,
      },
      isUserUnlimitedAndTextPaid:
        userData.isOnPrimeUnlimited && learnEntityInfo.rentalPrices,
      callback: fetchAssignLayerCallback,
      handleReaderAccessError,
      onError: () => hideAssignLayerPopup(),
    });
  }, []);

  useEffect(() => {
    if (assignLayerData.fetched) {
      toggleSubmitDisabled(
        !!datePickerErrorText ||
          newlySelectedClasses.length === 0 ||
          (assignLayerData.data.sections.length > 1 &&
            selectedTextSectionExcerptId === null)
      );
    }
  }, [newlySelectedClasses, assignLayerData, datePickerErrorText]);

  useEffect(() => {
    if (
      assignLayerData.fetched &&
      assignLayerData.data.recentAssignmentReadingSettingsPerClass.length > 0 &&
      useRecentAssignmentSettings
    ) {
      let readingSettingsToSet =
        assignLayerData.data.recentAssignmentReadingSettingsPerClass[0]
          .readingSettings;
      let recentAssignmentToSet =
        assignLayerData.data.recentAssignmentReadingSettingsPerClass[0];

      if (newlySelectedClasses.length === 1) {
        const newlySelectedClassId = newlySelectedClasses[0].classId;
        const newSelectedClassWithSettings = assignLayerData.data.recentAssignmentReadingSettingsPerClass.find(
          (s) => s.classId === newlySelectedClassId
        );

        if (newSelectedClassWithSettings) {
          readingSettingsToSet = newSelectedClassWithSettings.readingSettings;
          recentAssignmentToSet = newSelectedClassWithSettings;
        }
      }
      setReadingSettings(readingSettingsToSet);
      setRecentAssignment(recentAssignmentToSet);
    } else {
      setReadingSettings(getDefaultReadingSettings());
    }
  }, [newlySelectedClasses, useRecentAssignmentSettings, assignLayerData]);

  useEffect(() => {
    if (showRecentAssignmentSettings && recentAssignment) {
      setTimeout(() => {
        const elm = document.getElementById("assignmentSettingModal");
        if (elm) {
          elm?.querySelector<HTMLInputElement>(".closeButton")?.focus();
        }
      }, 300);
    }
  });

  function getDefaultReadingSettings() {
    defaultReadingSettings.writingCoachFeedbackStrategy =
      WritingCoachFeedbackStrategy.AUTOMATICALLY_SEND_FEEDBACK;

    if (readingSettings) {
      defaultReadingSettings.enableWritingCoach = readingSettings.enableWritingCoach;
      defaultReadingSettings.writingCoachFeedbackStrategy = readingSettings.writingCoachFeedbackStrategy;
    }

    defaultReadingSettings.enableWritingCoach = !(
      learnEntityInfo.textType === 'Performance Task' || getIsQuiz()
    );


    return {
      ...defaultReadingSettings,
      ...(getIsQuiz() || learnEntityInfo.isSummativeAssessment
        ? defaultQuizAssessmentSettings
        : {}),
    };
  }

  function getIsQuiz() {
    return learnEntityInfo.isQuiz || learnEntityInfo.isAssessment;
  }

  function fetchAssignLayerCallback(
    assignedLayerData,
    isLicenseAvailableOnUnlimited
  ): void {
    const {
      assignedClassesData,
      classStudentLicenseCount,
      studentAccessList,
      isBehindPaywall,
      recentAssignmentReadingSettingsPerClass,
    } = assignedLayerData;
    const {
      numTotalLicensesAvailable,
      assignedStudentLicenses,
    } = classStudentLicenseCount;
    const componentData: any = {};

    componentData.isLicenseAvailableOnUnlimited = isLicenseAvailableOnUnlimited;
    componentData.isFreeWTPAccess =
      wtpTextWithFreeAccess.includes(learnEntityInfo.learnEntityId) &&
      userData.shouldAllowFreeWTPAccessForGrandfatheredOrg;
    componentData.sections = assignedLayerData.assignedClassesData.sections;
    componentData.excerpts =
      assignedLayerData.assignedClassesData.excerpts || [];
    componentData.classStudentMap =
      assignedLayerData.assignedClassesData.classStudentMap;
    componentData.isBehindPaywall = isBehindPaywall;
    componentData.studentsData =
      assignedLayerData.assignedClassesData.studentsData;
    componentData.recentAssignmentReadingSettingsPerClass = recentAssignmentReadingSettingsPerClass;

    if (learnEntityInfo.publisher === weThePeoplePublisher) {
      componentData.numOfLicensesNeeded = getNumOfLicensesNeededForWTP(
        studentAccessList,
        numTotalLicensesAvailable,
        assignedStudentLicenses
      );
    } else if (
      learnEntityInfo.rentalPrices &&
      !isLicenseAvailableOnUnlimited &&
      !componentData.isFreeWTPAccess
    ) {
      componentData.numTotalLicensesAvailable = numTotalLicensesAvailable;
      componentData.assignedStudentLicenses = assignedStudentLicenses;
    }

    componentData.shouldCopyLayer = !assignedClassesData.layerIdToAssign;
    componentData.layerId =
      assignedClassesData.layerIdToAssign || learnEntityInfo.layerId;

    componentData.existingAssignedStudentsCount = assignedClassesData.studentsCountResp.reduce(
      (studentCountObj, data) => {
        studentCountObj[data.classId] = data.count;
        return studentCountObj;
      },
      {}
    );

    const shouldShowExcerptDropdown = getShouldShowExcerptDropdown(
      assignedClassesData.sections
    );

    const selectedClassIds = shouldShowExcerptDropdown
      ? []
      : getAssignedClassIdsFromClassStudentMap(
          componentData.classStudentMap,
          selectedTextSectionExcerptId
        );

    setAssignLayerData({
      fetched: true,
      data: componentData,
    });

    handleSelectedClassesUpdate(selectedClassIds, {
      classStudentMap: componentData.classStudentMap,
      layerTextSectionExcerptId: getShouldShowExcerptDropdown(
        assignedLayerData.assignedClassesData.sections
      )
        ? -1
        : null,
      studentsData: componentData.studentsData,
      initStudentsFromClassesData: true,
    });

    if (recentAssignmentReadingSettingsPerClass.length > 0) {
      setRecentAssignment(recentAssignmentReadingSettingsPerClass);
      setReadingSettings(
        recentAssignmentReadingSettingsPerClass[0].readingSettings
      );
      setUseRecentAssignmentSettings(true);
    }
  }

  function getAssignedClassIdsFromClassStudentMap(
    classStudentMap: ClassStudentMap[],
    layerTextSectionExcerptId: number | null
  ): number[] {
    const uniqClassIds = uniqueValues(classStudentMap.map((c) => c.classId));

    return uniqClassIds.filter((classId) =>
      classStudentMap.some(
        (sm) =>
          classId === sm.classId &&
          sm.isAssigned &&
          (layerTextSectionExcerptId
            ? sm.layerTextSectionExcerptId === layerTextSectionExcerptId
            : sm.layerTextSectionExcerptId === null)
      )
    );
  }

  function getShouldShowExcerptDropdown(sections): boolean {
    return (
      sections.length > 1 &&
      !paidPublishers.disallowExcerpting.includes(learnEntityInfo.publisher)
    );
  }

  function getNumOfLicensesNeededForWTP(
    accessList: StudentAccessInfo[],
    numOfLicenses: number,
    numOfLicensesUsedByStudents: number
  ): number {
    const {
      numOfStudentsWithoutLicense,
      numOfStudentLicensesUsedSchoolContentCredits,
    } = getNumOfLicensesParamsForWTP(accessList, userData.userId);
    const numOfStudentLicenses =
      numOfLicenses + numOfStudentLicensesUsedSchoolContentCredits;

    return (
      numOfStudentsWithoutLicense -
      (numOfStudentLicenses - numOfLicensesUsedByStudents)
    );
  }

  function onChooseSectionClick(currentTextSectionExcerptId): void {
    setCreateExcerptPopupState({
      layerId: assignLayerData.data.layerId,
      textSections: assignLayerData.data.sections,
      textTitle: learnEntityInfo.title,
      shouldCopyLayer: assignLayerData.data.shouldCopyLayer,
      cancelCallback: () => {
        // Restore currently selected excerpt on clicking cancel
        excerptSelected(currentTextSectionExcerptId);
      },
      callback: (newExcerptObj) => {
        if (newExcerptObj) {
          const excerptsData = [
            newExcerptObj,
            ...assignLayerData.data.excerpts,
          ];

          const updatedData = {
            ...assignLayerData.data,
            excerpts: excerptsData,
          };

          if (newExcerptObj.layerId) {
            updatedData.layerId = newExcerptObj.layerId;
            updatedData.shouldCopyLayer = false;
          }

          setAssignLayerData({
            fetched: assignLayerData.fetched,
            data: updatedData,
          });

          excerptSelected(newExcerptObj.layerTextSectionExcerptId);
        }
      },
    });
  }

  function excerptSelected(layerTextSectionExcerptId): void {
    setSelectedTextSectionExcerptId(layerTextSectionExcerptId);

    if (layerTextSectionExcerptId !== -1) {
      const { classStudentMap } = assignLayerData.data;

      handleSelectedClassesUpdate(
        getAssignedClassIdsFromClassStudentMap(
          classStudentMap,
          layerTextSectionExcerptId
        ),
        {
          classStudentMap,
          layerTextSectionExcerptId,
          initStudentsFromClassesData: true,
        }
      );
    }
  }

  function getNumOfUniqueStudentsNeedingLicense(classIds: number[]): number {
    const studentModeUserIds = students
      .filter((s) => !!s.teacherUserId)
      .map((s) => s.userId);

    if (learnEntityInfo.rentalPrices && !userData.isOnPrimeUnlimited) {
      return uniqueValues(
        assignLayerData.data.classStudentMap
          .filter(
            (s) =>
              s.studentUserId &&
              !studentModeUserIds.includes(s.studentUserId) &&
              classIds.includes(s.classId) &&
              !s.hasLicense
          )
          .map((s) => s.studentUserId)
      ).length;
    }

    return 0;
  }

  function getNewSelectedClassIds({
    selectedClassIds,
    classStudentMap,
    layerTextSectionExcerptId,
  }): number[] {
    return uniqueValues(
      selectedClassIds.filter(
        (classId) =>
          !classStudentMap.some(
            (classData) =>
              classData.classId === classId &&
              classData.isAssigned &&
              classData.layerTextSectionExcerptId ===
                (layerTextSectionExcerptId || null)
          )
      )
    );
  }

  function assignClass(modal): void {
    const {
      numTotalLicensesAvailable = 0,
      sections,
      classStudentMap,
      isLicenseAvailableOnUnlimited,
      isFreeWTPAccess,
    } = assignLayerData.data;

    if (document.querySelector(".MuiFormHelperText-root.Mui-error")) {
      showAlert({ message: "Please enter valid date.", type: "error" });
      modalEl.current.enableOkButton();
      return;
    }
    if (learnEntityInfo.isSummativeAssessment) {
      const { startDate, dueDate } = assignmentDate;
      if (!startDate || !dueDate) {
        showAlert({
          message: "Please select start and due date before assigning!",
          type: "error",
        });
        modalEl.current.enableOkButton();
        return;
      }
    }

    if (
      getShouldShowExcerptDropdown(sections) &&
      selectedTextSectionExcerptId === null
    ) {
      showAlert({
        message: "Please select sections to assign!",
        type: "error",
      });
      return;
    }

    // New selected classes
    const newSelectedClassIds = getNewSelectedClassIds({
      selectedClassIds,
      classStudentMap,
      layerTextSectionExcerptId: selectedTextSectionExcerptId,
    });

    if (
      userData.isOnPrimeUnlimited &&
      learnEntityInfo.rentalPrices &&
      !isFreeWTPAccess
    ) {
      const selectedNonUnlimitedClasses = teacherClasses.filter(
        (teacherClass) =>
          selectedClassIds.includes(teacherClass.classId) &&
          !teacherClass.isOnUnlimitedPlan
      );

      if (selectedNonUnlimitedClasses.length > 0) {
        setPaidTextToFreeClassConfirmationModal({
          selectedNonUnlimitedClasses,
          classIds: newSelectedClassIds,
          callback: () => {
            setPaidTextToFreeClassConfirmationModal(false, {});
            addAssignments();
          },
        });

        return;
      }
    }

    const assignedStudents = classStudentMap.filter((s) => {
      return s.studentUserId && selectedClassIds.includes(s.classId);
    });

    let studentsWithNoLicense = assignedStudents.filter(
      (s) => s.hasOwnProperty("hasLicense") && !s.hasLicense
    );
    studentsWithNoLicense = uniqBy(studentsWithNoLicense, "studentUserId");

    const shouldShowPurchasePopup =
      (numTotalLicensesAvailable || 0) - studentsWithNoLicense.length < 0 &&
      learnEntityInfo.rentalPrices &&
      !isLicenseAvailableOnUnlimited &&
      !isFreeWTPAccess;

    if (shouldShowPurchasePopup) {
      setPurchaseLicensesPopupState({
        layerId: learnEntityInfo.layerId,
        shouldCopyLayer: assignLayerData.data.shouldCopyLayer,
        classIds: selectedClassIds,
        layerTextSectionExcerptId: selectedTextSectionExcerptId || undefined,
        afterPurchaseCallback: (quantityPurchased) => {
          modal.enableOkButton();
          setAssignLayerData({
            fetched: true,
            data: {
              ...assignLayerData.data,
              numTotalLicensesAvailable:
                (assignLayerData.data.numTotalLicensesAvailable || 0) +
                (quantityPurchased || 0),
            },
          });
        },
        cancelPurchaseCallback: () => modal.enableOkButton(),
        onPurchaseLicenseLaterClick: () => {
          setPurchaseLicensesPopupState(null);
          addAssignments();
        },
      });
    } else {
      modal.disableOkButton("Assigning...");
      addAssignments();
    }
  }

  function addAssignments(): void {
    const data: any = {
      layerId: assignLayerData.data.layerId || learnEntityInfo.layerId,
      shouldCopyLayer: assignLayerData.data.shouldCopyLayer,
      classIds: selectedClassIds,
      layerTextSectionExcerptId: selectedTextSectionExcerptId || undefined,
      individualAssignees: getIndividualAssignees(),
      readingSettings: getParamsInPascalCase(readingSettings),
    };

    data.startDate = formatIntoIsoString(assignmentDate.startDate || utcNow);

    if (assignmentDate.dueDate) {
      data.dueDate = formatIntoIsoString(assignmentDate.dueDate || "");
    }

    assignToClasses(data);
  }

  function getIndividualAssignees() {
    const groupedStudentByClass = groupByValue(students, "classId");
    const individualAssignees = {};
    Object.keys(groupedStudentByClass).forEach((classId) => {
      const studentsInClass = groupedStudentByClass[classId];
      const assignedStudentIds = studentsInClass
        .filter((x) => x.isSelected)
        .map((x) => x.userId);

      if (
        assignedStudentIds.length > 0 &&
        assignedStudentIds.length < studentsInClass.length
      ) {
        individualAssignees[classId] = assignedStudentIds;
      }
    });

    return individualAssignees;
  }

  function handleSelectedClassesUpdate(
    selectedClassIds,
    {
      classStudentMap = assignLayerData.data.classStudentMap,
      studentsData = assignLayerData.data.studentsData,
      layerTextSectionExcerptId,
      initStudentsFromClassesData = false,
    }
  ): void {
    const newlySelectedClassIds = getNewSelectedClassIds({
      selectedClassIds,
      classStudentMap,
      layerTextSectionExcerptId,
    });

    const newlySelectedClasses = teacherClasses.filter((c) =>
      newlySelectedClassIds.includes(c.classId)
    );
    setNewlySelectedClasses(newlySelectedClasses);
    setSelectedClassIds(selectedClassIds);
    // This will set students on selecting an excerpt & on initializing the modal with fetched data
    if (initStudentsFromClassesData) {
      const uniqClassStudentMapByClassAndStudent = {};
      classStudentMap.forEach((s) => {
        if (
          s.studentUserId &&
          !uniqClassStudentMapByClassAndStudent[
            s.classId + "-" + s.studentUserId
          ]
        ) {
          uniqClassStudentMapByClassAndStudent[
            s.classId + "-" + s.studentUserId
          ] = s;
        }
      });
      setStudents(
        Object.values(uniqClassStudentMapByClassAndStudent).map((s: any) => ({
          isSelected: classStudentMap.some(
            (csm) =>
              csm.isAssigned &&
              csm.studentUserId === s.studentUserId &&
              csm.classId === s.classId &&
              (layerTextSectionExcerptId
                ? csm.layerTextSectionExcerptId === layerTextSectionExcerptId
                : csm.layerTextSectionExcerptId === null)
          ),
          classId: s.classId,
          ...(studentsData.find((x) => x.userId === s.studentUserId) || {
            firstName: "",
            lastName: "",
            userId: s.studentUserId,
          }),
        }))
      );
    }
  }

  function toggleSubmitDisabled(isDisabled) {
    if (modalEl.current) {
      if (isDisabled) {
        modalEl.current.disableOkButton();
      } else {
        modalEl.current.enableOkButton();
      }
    }
  }

  function getFormattedDate(dateVal): string {
    const date = new Date(dateVal).toDateString();
    const dateStr = date.substr(date.indexOf(' ') + 1);
    const index = dateStr.lastIndexOf(' ');

    return dateStr.substring(0, index) + ', ' + dateStr.substring(index + 1);
  }

  return (
    <>
      <Modal
        title={
          getLTISelectAssignmentAssignPopupTitle() ||
          (learnEntityInfo.isSummativeAssessment
            ? 'Assign this performance task to classes'
            : `Assign ${learnEntityInfo.title} to classes`)
        }
        okText="Assign"
        cancelText="Cancel"
        cancelCallback={hideAssignLayerPopup}
        okCallback={assignClass}
        ref={modalEl}
        classes={{
          paper: styles.modalTitle,
        }}
        cssClass="modal-al-assign-layer"
        onOpenCallback={(modal) => modal.disableOkButton()}
        preventSubmitOnEnter
        headerMessage={
          <>
            {userData.isOnPromotion && assignLayerData.data.isBehindPaywall && (
              <p className={styles.headerMessage}>This assignment is only available on an Actively Learn Prime plan. 
                You and your students will lose access to it when your Prime Trial 
                expires on {userData.expireDate ? getFormattedDate(userData.expireDate) : ''}.
              </p>
            )}
          </>
        }
        id="assignLayerModal"
        maxWidth="mdx"
      >
        <LoadingWrapper isLoading={!assignLayerData.fetched}>
          <ExcerptAndClassSelector
            shouldShowExcerptDropdown={getShouldShowExcerptDropdown(
              assignLayerData.data.sections
            )}
            learnEntityInfo={learnEntityInfo}
            userData={userData}
            teacherClasses={teacherClasses}
            assignLayerData={assignLayerData.data}
            onExcerptsDropdownChange={excerptSelected}
            onChooseSectionClick={onChooseSectionClick}
            selectedTextSectionExcerptId={selectedTextSectionExcerptId}
            selectedClassIds={selectedClassIds}
            newlySelectedClassIds={newlySelectedClasses.map((c) => c.classId)}
            handleSelectedClassesUpdate={handleSelectedClassesUpdate}
            setUnAssignModalState={setUnAssignModalState}
            students={students}
            setStudents={setStudents}
            setPremiumBannerModal={setPremiumBannerModal}
            setAssignLayerData={setAssignLayerData}
            unAssignCallback={unAssignCallback}
          />
          <Divider />
          <StartAndDueDateSelector
            learnEntityInfo={learnEntityInfo}
            datePickerErrorText={datePickerErrorText}
            setAssignmentDate={setAssignmentDate}
            assignmentDate={assignmentDate}
            setDatePickerErrorText={setDatePickerErrorText}
            utcNow={utcNow}
          />
          <AssignmentSettings
            readingSettings={readingSettings}
            setReadingSettings={setReadingSettings}
            recentAssignment={recentAssignment}
            useRecentAssignmentSettings={useRecentAssignmentSettings}
            setUseRecentAssignmentSettings={setUseRecentAssignmentSettings}
            setShowRecentAssignmentSettings={setShowRecentAssignmentSettings}
            isQuiz={getIsQuiz()}
            learnEntityInfo={learnEntityInfo}
            isWritingCoachAccessible={userData.isWritingCoachAccessible}
            isWritingCoachLocked={userData.isWritingCoachLocked}
          />
          <p className="info">
            Note: You can always edit the dates and settings for each assignment
            in class &rarr; assignments. These settings and dates will not
            change for any assignments that are already assigned.
          </p>
        </LoadingWrapper>
      </Modal>
      {showRecentAssignmentSettings && recentAssignment && (
        <Modal
          title={`Assignment settings for "${recentAssignment.assignmentTitle}"`}
          okCallback={() => setShowRecentAssignmentSettings(false)}
          cancelText=""
          maxWidth="md"
          classes={{ paper: styles.modalTitle }}
          id="assignmentSettingModal"
          cancelCallback={() => setShowRecentAssignmentSettings(false)}
        >
          <AssignmentSettingsSelector
            readingSettings={recentAssignment.readingSettings}
            isQuiz={getIsQuiz()}
            isNonTextAssignment={nonTextLearnEntityType.includes(learnEntityInfo.textType || '')}
            isPerformanceTask={learnEntityInfo.textType === 'Performance Task'}
            isSummativeAssessment={learnEntityInfo.isSummativeAssessment}
            isTextToSpeechDisabled={learnEntityInfo.isTextToSpeechDisabled}
            isTranslationDisabled={learnEntityInfo.isTranslationDisabled}
            isWritingCoachAccessible={userData.isWritingCoachAccessible}
            isWritingCoachLocked={userData.isWritingCoachLocked}
          />
        </Modal>
      )}
      <AssignLayerJoyride
        onComplete={() => {
          const modalBody = document.querySelector(
            ".modal-al-assign-layer .modal-body"
          );

          if (modalBody) {
            // Scroll to top of the modal once joyride ends
            modalBody.scrollTop = 0;
          }
        }}
        updatePopupSettings={updatePopupSettings}
        show={
          assignLayerData.fetched && userData.showPopup("NewAssignFlowIntro")
        }
      />
    </>
  );
}

export default AssignLayerPopup;
