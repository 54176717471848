import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { PopoverButton } from "../buttonPopover";
import { Fonts } from "../../helpers/constants";
import { IPropsPopover } from "../buttonPopover/PopoverButton";

const useStyles = makeStyles({
  root: {
    fontSize: 16,
  },
  header: {
    height: 65,
    backgroundColor: "#016CC6",
    color: "#FFF",
    fontFamily: Fonts.BOLD,
    fontSize: 18,
    padding: "1rem 1.2rem",
    textTransform: "uppercase",
    position: "relative",
    '& h2': {
      fontSize: 'inherit'
    }
  },
  cardHighlight: {
    position: "absolute",
    margin: 0,
    top: "0.9rem",
    right: "1rem",
    fontSize: "24px",
  },
  textSection: {
    backgroundColor: "#FFF",
    fontFamily: Fonts.REGULAR,
    overflow: "auto",
    padding: "1rem",

    "& ul": {
      padding: "0 1.5rem",
      listStyleType: "initial",

      "& li": {
        marginBottom: "0.3rem",
      },
    },
  },
  mainText: {
    marginTop: 0,
  },
  infoButton: {
    cursor: "pointer",
    fontSize: 18,
    fontWeight: 600,
    backgroundColor: "#000",
    color: "#FFF",
    borderRadius: "50%",
    right: "1rem",
    bottom: "1rem",
    position: "absolute",
    width: 24,
    height: 24,
    border: 0,
    lineHeight: 1,

    "&:before": {
      fontSize: "16px",
      lineHeight: "1",
    },
  },
  infoButtonLight: {
    backgroundColor: "#FFF",
    color: "#2F2F2F",
  },
});

interface CardTextListProps {
  backgroundColor?: string;
  buttonColor?: string;
  buttonTitle?: string;
  buttonAriaLabel?: string;
  className?: string;
  color?: string;
  helpText?: string;
  highlightValue?: string;
  mainText?: string;
  popoverOptions?: IPropsPopover;
  textItems?: string[];
  title?: string;
  [key: string]: any;
}

const CardTextList: React.FunctionComponent<CardTextListProps> = (
  props
): JSX.Element => {
  const {
    backgroundColor,
    buttonColor,
    buttonTitle,
    buttonAriaLabel,
    color,
    className = "",
    helpText,
    highlightValue,
    mainText,
    popoverOptions,
    textItems,
    title,
    ...rest
  } = props;
  const styles = useStyles();

  return (
    <div className={classnames(styles.root, className)} {...rest}>
      <div className={styles.header} style={{ backgroundColor, color }}>
        <h2>{title}</h2>
        {highlightValue && (
          <p className={styles.cardHighlight}>{highlightValue}</p>
        )}
        {helpText && (
          <PopoverButton
            popoverId={popoverOptions && popoverOptions.id}
            className={classnames(
              styles.infoButton,
              buttonColor === "light" && styles.infoButtonLight,
              popoverOptions && popoverOptions.className
            )}
            content={(popoverOptions && popoverOptions.content) || "?"}
            placement={(popoverOptions && popoverOptions.placement) || "left"}
            title={buttonTitle ? buttonTitle : "More info"}
            aria-label={
              buttonAriaLabel ? `${buttonAriaLabel} ${title}` : "More info"
            }
          >
            {helpText}
          </PopoverButton>
        )}
      </div>
      <div className={styles.textSection}>
        {mainText && (
          <div>
            <p className={styles.mainText}>{mainText}</p>
          </div>
        )}
        {textItems && (
          <div>
            <ul>
              {textItems.map((text, index) => (
                <li key={index}>{text}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardTextList;
