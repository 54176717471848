import React from 'react';
import TextField from '@material-ui/core/TextField';
import { TextValidator } from '../formValidator/FormValidator';
import CreatableSelect from 'react-select/creatable';
import Select, { components } from 'react-select';
import { Fonts } from '../../helpers/constants';
import { withStyles } from '@material-ui/core/styles';
import naturalCompare from 'natural-compare-lite';
import { Props } from 'react-select/src/Select';
import { ControlProps } from 'react-select/src/components/Control';

const styles = theme => ({
  input: {
    padding: '5px',
    height: 'auto',
    minWidth: 220,

    '& > div': {
      padding: 0,
    }
  },
});

const selectStyles = {
  input: (base) => ({
    ...base,
    height: 30,
    '& input': {
      fontFamily: Fonts.SEMI_BOLD,
      minWidth: 220,
    },
  }),
  indicatorsContainer: () => ({
    display: 'none'
  }),
  placeholder: (base) => ({
    ...base,
    fontFamily: Fonts.MEDIUM,
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused ? 'rgba(0, 0, 0, 0.08)' : 'inherit',
    lineHeight: 2,
  }),
  menu: (base) => ({
    ...base,
    zIndex: 2,
    margin: 0,
    fontFamily: Fonts.MEDIUM,
    fontSize: 16,
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 9999
  }),
  multiValue: (base) => ({
    ...base,
    borderRadius: '16px',
    height: '32px',
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '13px',
    padding: '0 5px',
    fontFamily: Fonts.MEDIUM,
    '& div': {
      backgroundColor: 'inherit',
    },
    '& div:hover': {
      backgroundColor: 'inherit',
    },
    '& svg': {
      background: 'rgba(0, 0, 0, 0.45)',
      borderRadius: '50%',
      width: '18px',
      height: '18px',
      color: '#e0e0e0',
      fill: '#e0e0e0',
      cursor: 'pointer',
    },
  }),
  multiValueLabel: (base) => ({
    ...base,
    paddingLeft: '7px',
    paddingRight: '7px',
    fontSize: '100%',
  }),
};

interface ISuggestion {
  value: string | number;
  label: string;
};
interface IProps extends Omit<Partial<Props>, 'value'> {
  suggestions: ISuggestion[];
  placeholder?: string;
  inputLabel?: string;
  defaultValues?: ISuggestion[];
  allowFreeTagging?: boolean;
  onChange: any;
  classes?: any;
  validators?: any;
  className?: string;
  maxLength?: number;
  renderMenuOutside?: boolean;
  multiChipInputId?: string;
  enableAutoFocus?: boolean;
};

class ReactSelectMultiple extends React.Component<IProps, any> {
  suggestions: any = this.props.suggestions.sort((a, b) => {
    return naturalCompare(a.label.toLowerCase().trim(), b.label.toLowerCase().trim());
  });

  handleChange = (selectedItems) => {
    this.props.onChange(selectedItems || []);
  };

  render() {
    const { placeholder = '', allowFreeTagging, defaultValues = [], classes, inputLabel,
      validators, suggestions, onChange, maxLength, className = '', renderMenuOutside, multiChipInputId, ...rest } = this.props;

    const components = { Control, Menu };

    if (allowFreeTagging) {
      return (
        <CreatableSelect
          classes={classes}
          styles={selectStyles}
          inputId={multiChipInputId || "multiple-react-select"}
          components={components}
          defaultValue={defaultValues as any}
          placeholder={placeholder}
          aria-label={inputLabel || placeholder}
          options={this.suggestions}
          onChange={this.handleChange}
          validators={validators}
          openMenuOnClick={false}
          isMulti
          autoFocus={!this.props.enableAutoFocus}
          menuPortalTarget={renderMenuOutside ? document.body : undefined}
          menuPlacement={renderMenuOutside ? 'auto' : undefined}
          onInputChange={inputValue => (
            (maxLength && inputValue.length > maxLength)
              ? inputValue.substr(0, maxLength)
              : inputValue
          )}
          noOptionsMessage={({ inputValue }) =>
            `No results ${inputValue.trim().length > 0 ? 'matched for "' + inputValue + '"' : ''}`
          }
          className={`react-select ${className}`}
          {...rest}
        />
      );
    }
    else {
      return (
        <Select
          styles={selectStyles}
          classes={classes}
          components={components}
          defaultValue={defaultValues as any}
          placeholder={placeholder}
          aria-label={inputLabel || placeholder}
          options={this.suggestions}
          onChange={this.handleChange}
          validators={validators}
          isMulti
          autoFocus={!this.props.enableAutoFocus}
          menuPortalTarget={document.body}
          menuPlacement="auto"
          noOptionsMessage={({ inputValue }) =>
            `No results ${inputValue.trim().length > 0 ? 'matched for "' + inputValue + '"' : ''}`
          }
          className={`react-select ${className}`}
          onInputChange={inputValue => (
            (maxLength && inputValue.length > maxLength)
              ? inputValue.substr(0, maxLength)
              : inputValue
          )}
          {...rest}
        />
      );
    }
  }
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

const Menu = (props) => {
  return (
    <components.Menu className="react-select-menu" {...props}>
      {props.children}
    </components.Menu>
  );
};

function Control(props: ControlProps<any>) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps,
  } = props;

  return (selectProps.validators
    ? <TextValidator
      fullWidth
      name="react-select-multiple"
      id="react-select-multiple"
      validators={selectProps.validators}
      label=""
      margin="dense"
      height={30}
      InputProps={{
        inputComponent,
        inputProps: {
          className: selectProps.classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
    />
    : <TextField
      fullWidth
      name="react-select-multiple"
      id="react-select-multiple"
      variant="outlined"
      InputProps={{
        inputComponent: inputComponent as any,
        inputProps: {
          className: selectProps.classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
    />
  );
}

export default withStyles(styles)(ReactSelectMultiple) as React.ComponentClass<IProps, any>;
