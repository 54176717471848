import React from 'react';
import { Line } from 'react-chartjs-2';

const gray = '#a1a1a1';

const defaultLineChartDataSetOptions = {
  borderColor: '#1878D2',
  pointsColor: '#FFF',
  borderWidth: 4,
  pointRadius: 8,
  borderDash: 0,
  borderDashOffset: 0,
  pointHoverRadius: 10,
  pointBorderColor: '#1878D2',
  pointBackgroundColor: '#FFF',
  pointHoverBorderWidth: 4,
  lineTension: 0,
  fill: false,
}

const LineChart = ({ config = {} }: any) => {
  const {
    options: additionalOptions = {},
    labels,
    data,
    tooltipOptionOverrides,
    plugins,
  } = config;

  const chartData = {
    labels,
    datasets: data.map(dataset => Object.assign({}, defaultLineChartDataSetOptions, dataset)),
  };

  const options = {
    legend: { display: false },
    layout: { padding: { top: 15, bottom: 0, right: 40, left: 0 } },
    scales: {
      yAxes: [
        {
          gridLines: {
            borderDash: [10, 4],
            drawBorder: false,
            zeroLineWidth: 0
          },
          scaleLabel: {
            display: true,
            fontColor: gray,
            fontSize: 13,
            labelString: additionalOptions.yAxisLabel,
            padding: 10
          },
          ticks: {
            fontColor: gray,
            min: 0,
          }
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
            zeroLineWidth: 0
          },
          scaleLabel: {
            display: true,
            fontColor: gray,
            fontSize: 13,
            labelString: additionalOptions.xAxisLabel,
            padding: 10,
          },
          ticks: {
            fontColor: gray,
          }
        },
      ],
    },
    tooltips: {
      backgroundColor: '#fff',
      borderColor: '#0077d1',
      borderWidth: 2,
      titleFontColor: '#0077d1',
      titleFontSize: 15,
      bodyFontSize: 15,
      displayColors: false,
      bodyFontColor: '#253945',
      bodyFontStyle: 'bold',
      xPadding: 15,
      yPadding: 15,
      titleMarginBottom: 10,
      ...tooltipOptionOverrides,
    },
    ...additionalOptions,
  };

  return <Line options={options} data={chartData} plugins={plugins} />;
};

export default LineChart;
