import { createMuiTheme } from '@material-ui/core/styles';
import { colors } from './style';
import '../../src/colors.css';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'AvenirNext-Medium, "Helvetica", "Arial", sans-serif',
  },
  palette: {
    primary: {
      light: colors.blue,
      main: colors.darkBlue,
      dark: colors.grayBlue,
      contrastText: colors.white,
    },
  },
  overrides: {
    MuiDialogContent: {
      root: {
        borderBottom: 'solid 1px var(--light-gray1)',
        fontFamily: 'AvenirNext-Medium',
        fontSize: 18,
        color: 'var(--dark-blue)',
      },
    },
    MuiDialogTitle: {
      root: {
        color: 'var(--dark-blue)',
      },
    },
    MuiButton: {
      root: {
        fontSize: 16,
        color: 'var(--dark-blue)',
      },
    },
    MuiCheckbox: {
      root: {
        color: 'var(--dark-blue)',
      },
    },
  },
} as any);

export default theme;