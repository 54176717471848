import React from 'react';
import image from '../../img/DogJumpDoodle.png';
import styles from './styles.module.css';

interface IProps {
  assignmentsCount: number;
}

const ZeroState = (props: IProps) => {
  const {
    assignmentsCount,
  } = props;

  if (assignmentsCount > 0) {
    return null;
  }

  return (
    <div className={styles.zeroStateContainer}>
      <img alt="" src={image} style={{ width: 333 }} />
      <div className={styles.zeroStateTextContainer}>
        <div className={styles.zeroStateTitle}>
          Nothing to see here!
        </div>
        <div className={styles.zeroStateText}>
          Skills will appear here when your teacher assigns them.
        </div>
      </div>
    </div>
  );
};

export default ZeroState;
