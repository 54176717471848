import SubmitResponseButton from './component';
import { connect } from 'react-redux';
import { submittedResponse } from '../../../../actions/readerNavigation';

const mapStateToProps = (state: any) => {
  return {
    role: state.userData.role,
    disableButton: state.readerNavigation.submittedResponse,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    submitResponse: () => {
      dispatch(submittedResponse());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubmitResponseButton);
