import React, { useEffect } from 'react';
import { ReactSelect, Tooltip } from 'sharedfrontend';
import { FaSearch, FaTimes, FaHeart } from 'react-icons/fa';
import classnames from 'classnames';
import Button from '../../common/button/component'
import styles from './styles.module.css';
import { setCookie } from '../../../utils/common';
import { updateHash } from '../../../utils/iframe';
import { colors } from '../../../utils/style';
import HeaderLayout from '../../common/header/headerLayout.component';

interface IProps {
  userId: number;
  grade: string;
  search: string;
  gradeLevel: string;
  hasSearched: boolean;
  hasFavorites: boolean;
  showingFavorites: boolean;
  shouldSelectAllGrades: boolean;
  gradeLevelsForDropdown: any[];
  setFilters: Function;
  fetchData: Function;
};

const Header = ({
  userId,
  grade,
  search,
  gradeLevel,
  hasSearched,
  hasFavorites,
  showingFavorites,
  shouldSelectAllGrades,
  gradeLevelsForDropdown,
  setFilters,
  fetchData,
}: IProps) => {
  useEffect(() => {
    setFilters({
      grade: gradeLevel,
      defaultGrade: gradeLevel,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (shouldSelectAllGrades) {
      onGradeLevelSelected('All Grades');
    }
    // eslint-disable-next-line
  }, [shouldSelectAllGrades]);

  function filterByDefaultGradeLevel() {
    fetchCatalogData({ gradeLevel });

    setFilters({
      search: '',
      grade: gradeLevel,
      hasSearched: false,
      showingFavorites: false,
    });
    resetForm();
  }

  function onGradeLevelSelected(gradeLevel: string) {
    fetchCatalogData({ searchTerm: search, gradeLevel });

    setFilters({
      grade: gradeLevel,
      showingFavorites: false,
      shouldSelectAllGrades: false,
    });

    window.sessionStorage.setItem('selectedGradeLevel', gradeLevel);
    setCookie(`${userId}-mathCatalogSelectedGradeLevel`, gradeLevel);
    resetForm();
  }

  function handleInput(e: any) {
    setFilters({ search: e.target.value });
  }

  function handleSearch(e: any) {
    e.preventDefault();

    if (e.target.txtSearch.value.trim() !== '') {
      const searchTerm = e.target.txtSearch.value.trim().toLowerCase();
      fetchCatalogData({ searchTerm, gradeLevel: grade });

      setFilters({
        hasSearched: true,
        showingFavorites: false,
      });

      window.sessionStorage.setItem('searchTerm', searchTerm);
    }
  }

  function handleClearSearch() {
    fetchCatalogData({ gradeLevel: grade });

    setFilters({
      search: '',
      hasSearched: false,
      showingFavorites: false,
    });
  
    window.sessionStorage.removeItem('searchTerm');
    resetForm();
  }

  function fetchCatalogData({
    searchTerm, gradeLevel,
  }: {
    searchTerm?: string;
    gradeLevel?: string;
  }) {
    let query = '';
    const params: any = {};

    if (searchTerm) {
      params.searchTerm = searchTerm;
      query = `search=${encodeURIComponent(searchTerm)}`;
    }
  
    if (gradeLevel) {
      params.gradeLevel = gradeLevel;
      query += `${searchTerm ? '&' : ''}grade=${encodeURIComponent(gradeLevel)}`;
    }

    updateHash({ path: `/catalog?${query}`, addToHistory: true });
    fetchData(params);
  }

  function onClickFavorite() {
    if (hasFavorites && !showingFavorites) {
      updateHash({ path: '/catalog/favorites', addToHistory: true });
      fetchData({});

      setFilters({
        search: '',
        grade: '',
        hasSearched: false,
        showingFavorites: true,
      });

      window.sessionStorage.removeItem('searchTerm');
      window.sessionStorage.removeItem('selectedGradeLevel');
      resetForm();
    }
    else if (showingFavorites) {
      filterByDefaultGradeLevel();
    }
  }

  function renderFavoriteFilter() {
    const favoriteClassNames = classnames({
      [styles.favorite]: true,
      [styles.redFavorite]: hasFavorites && showingFavorites,
      [styles.blueFavorite]: hasFavorites && !showingFavorites,
    });

    if (hasFavorites) {
      return (
        <Button onClick={onClickFavorite} additionalStyles={favoriteClassNames}><FaHeart /></Button>
      );
    }

    return (
      <Tooltip title="Create a favorites list by selecting hearts." tooltipColor="white">
        <span>
          <Button onClick={onClickFavorite} additionalStyles={favoriteClassNames}><FaHeart /></Button>
        </span>
      </Tooltip>
    );
  }

  return (
    <HeaderLayout title="Content Catalog">
      <form className={styles.form} onSubmit={handleSearch}>
        <input
          className={styles.txtSearch}
          name="txtSearch"
          placeholder="Search"
          value={search}
          onChange={handleInput}
        />
        {hasSearched &&
            <Button additionalStyles={styles.btnSearch} onClick={handleClearSearch} type="button">
              <FaTimes />
            </Button>
        }
        {!hasSearched &&
            <Button additionalStyles={styles.btnSearch} type="submit"><FaSearch /></Button>
        }
      </form>
      <ReactSelect
        id="gradeFilterSelector"
        placeholder="Filter by grade"
        value={grade ? { value: grade, label: grade } : null}
        suggestions={gradeLevelsForDropdown.map((x: string) => ({ value: x, label: x }))}
        onChange={(selectedGradeLevelOption: any) => onGradeLevelSelected(selectedGradeLevelOption.value)}
        isSearchable={false}
        styles={{
          container: (base: any) => ({
            ...base,
            boxShadow: `1px 1px 10px ${colors.boxShadowGray}`,
            width: 250,
          }),
          control: (base: any) => ({
            ...base,
            padding: '1px 6px',
            cursor: 'pointer',
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          placeholder: (base: any) => ({
            ...base,
            fontFamily: 'AvenirNext-Bold',
            textTransform: 'uppercase',
            fontSize: 12,
            color: colors.placeHolderGray,
            letterSpacing: 0.5,
          }),
        }}
      />
      {renderFavoriteFilter()}
    </HeaderLayout>
  );
};

const resetForm = () => document.forms[0].reset();

export default Header;
