export const goToProblem = (problemId: number) => {
  return {
    type: 'GO_TO_PROBLEM',
    body: { problemId },
  }
};

export const goToStep = (stepId: number) => {
  return {
    type: 'GO_TO_STEP',
    body: { stepId },
  }
};

export const submittedResponse = () => {
  return {
    type: 'SUBMITTED_RESPONSE',
  }
};

export const markAllHintsViewed = () => {
  return {
    type: 'ALL_HINTS_VIEWED',
  }
};

export const closeFeedbackBar = () => {
  return {
    type: 'CLOSE_FEEDBACK_BAR',
  }
};

export const exitReader = () => {
  return {
    type: 'EXIT_READER',
  }
};
