import React, { useState } from 'react';
import Modal from '../modal/Modal';
import MultiChipInputAutoComplete from '../autocompleteMultiChipInput/MultiChipInputAutoComplete';
import { makeStyles } from '@material-ui/core/styles';
import { Fonts } from "../../helpers/constants";
import { TextValidator } from '../formValidator';

const useStyles = makeStyles({
  text: {
    fontFamily: Fonts.MEDIUM,
  }
});

interface SharingModalProps {
  modalTitle: string;
  okText: string;
  disabledOkText?: string;
  cancelText: string;
  okCallback: Function;
  teachers: any[];
  headerContent: JSX.Element | string;
  cssClass?: string;
  cancelCallback?: Function;
  showAlert: Function;
  signupEmailBanner?: JSX.Element;
}

export default function SharingModal({
  modalTitle,
  okText,
  disabledOkText,
  cancelText,
  okCallback,
  teachers,
  headerContent,
  cssClass,
  cancelCallback,
  showAlert,
  signupEmailBanner,
}: SharingModalProps): JSX.Element {
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [noteText, setNoteText] = useState('');
  const styles = useStyles();
  function isSelected(): boolean {
    if (selectedUserIds.length === 0) {
      showAlert();
      return false;
    } else {
      return true;
    }
  }

  return (
    <Modal
      title={modalTitle}
      okText={okText}
      disabledOkText={disabledOkText}
      cancelText={cancelText}
      cssClass={cssClass}
      okCallback={(modal) => okCallback(modal, noteText, selectedUserIds)}
      cancelCallback={cancelCallback}
      customValidations={[{ name: 'isSelected', func: isSelected }]}
      maxWidth="md">
      <div className={styles.text}>
        {headerContent}
      </div>
      <MultiChipInputAutoComplete
        placeholder="Start typing a colleague's name..."
        suggestions={teachers.map((suggestion: any) => ({
          value: suggestion.userId,
          label: `${suggestion.firstName} ${suggestion.lastName}`,
        }))}
        onChange={(selections: Array<{ value: string; label: string }>) => {
          setSelectedUserIds(selections.map((selection) => selection.value));
        }}
        validators={{ isSelected: '' }}
        renderMenuOutside
      />
      <TextValidator
        id="inputAddNoteToSendToColleague"
        label="Add note to send to colleague (optional)"
        value={noteText}
        onChange={(ev) => setNoteText(ev.target.value)}
        multiline
        fullWidth
        margin="dense"
      />
      {signupEmailBanner}
    </Modal>
  );
}
