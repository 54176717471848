import GoToNextStepButton from './component';
import { connect } from 'react-redux';
import { goToStep } from '../../../../actions/readerNavigation';

const mapStateToProps = (state: any) => {
  const currentStepIndex =
    state.problemData.stepIds.findIndex((x: number) => x === state.readerNavigation.currentStepId);

  return {
    nextStepId: state.problemData.stepIds[currentStepIndex + 1],
    role: state.userData.role,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    goToStep: (stepId: number) => {
      dispatch(goToStep(stepId));
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoToNextStepButton);
