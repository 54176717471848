import React from 'react';
import classnames from 'classnames';
import striptags from 'striptags';
import Button from '../../common/button/component';
import GoToFirstStepButton from '../buttons/goToFirstStep/container';
import GoToNextStepButton from '../buttons/goToNextStep/container';
import GoToProblemButton from '../buttons/goToProblem/container';
import Toolbar from '../common/toolbar/container';
import styles from './styles.module.css';
import ShowSolutionVideoButton from '../buttons/showSolutionVideo/container';
import { Role } from '../../../utils/constants';

class GradeFeedbackBar extends React.Component<any, any> {
  componentDidUpdate() {
    const mathJaxHub = (window as any).MathJax.Hub;
    mathJaxHub.Queue(['Typeset', mathJaxHub, 'feedbackMessage'], () => mathJaxHub.getAllJax('feedbackMessage')[0]);

    // scroll window to bottom when this bar appears, since footer disappears
    const mainEl = document.querySelector('main');
    if (mainEl) {
      mainEl.scrollTop = mainEl.scrollHeight;
    }
  }

  render() {
    const {
      id,
      closeFeedbackBar,
      isOnStep,
      isProblemCompleted,
      responseExists,
      role,
      gradeFeedbackData: { feedbackMessage, isCorrect },
      isBenchmark,
      numAttempts,
      hideToolbar,
    } = this.props;

    const display = feedbackMessage ? 'block' : undefined;
    const cleanedUpFeedbackMessage = striptags(feedbackMessage, ['img'], ' ').replace(/&nbsp;/g, ' ');

    const classNames = classnames(styles.bar, {
      [styles.incorrect]: isCorrect === false,
      [styles.correct]: isCorrect === true,
    });

    const showButtonsConfig: any = getButtonsConfig(isCorrect, isOnStep, isProblemCompleted, role, responseExists,
      isBenchmark);

    return (
      <div className={classNames} style={{ display }}>
        {!hideToolbar &&
          <div className="tts">
            <Toolbar targetId={`feedback-${id}-${numAttempts}`} ttsOnly />
          </div>
        }
        <p
          id={`feedback-${id}-${numAttempts}`}
          className={styles.message}
          dangerouslySetInnerHTML={{ __html: cleanedUpFeedbackMessage }}
        ></p>
        {showButtonsConfig.ok && <Button orangeType="filled" onClick={closeFeedbackBar}>OK</Button>}
        <ShowSolutionVideoButton />
        {showButtonsConfig.tryAgain && <Button orangeType="filled" onClick={closeFeedbackBar}>Try again</Button>}
        {showButtonsConfig.nextStep && <GoToNextStepButton validRoles={[Role.STUDENT]} />}
        {showButtonsConfig.firstStep && <GoToFirstStepButton type="afterAnswering" />}
        {showButtonsConfig.problemCompleted && <GoToProblemButton type="next" validRoles={[Role.STUDENT]} />}
      </div>
    );
  }
};

const getButtonsConfig = (isCorrect: boolean | null, isOnStep: boolean, isProblemCompleted: boolean, role: string,
  responseExists: boolean, isBenchmark: boolean,
) => {
  if (role === Role.STUDENT) {
    if (isBenchmark) {
      return {
        tryAgain: !responseExists,
        problemCompleted: isProblemCompleted,
      };
    }
    else {
      return {
        tryAgain: !responseExists,
        nextStep: isOnStep && responseExists,
        firstStep: !isOnStep && isCorrect === false,
        problemCompleted: isProblemCompleted,
      };
    }
  }
  else if (role === Role.SUPERADMIN) {
    return {
      ok: true,
    };
  }
  else {
    return {};
  }
};

export default GradeFeedbackBar;
