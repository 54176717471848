import UpdateStepProblemSetModal from './updateStepProblemSet.component';
import { connect } from 'react-redux';
import { postData } from '../../../../actions/api';
import { closeModal } from '../../../../actions/eventEmitter';

const mapStateToProps = (state: any) => {
  return {
    stepId: state.readerNavigation.currentStepId,
    problemSetId: state.stepData.stepProblemSetId,
    problemSetName: state.stepData.problemSetName,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    saveStepProblemSet: async ({ stepId, problemSetId, problemSetName }: { 
      stepId: number;
      problemSetId: number;
      problemSetName: number;
    }) => {
      dispatch(postData({
        type: 'UPDATE_STEP_PROBLEMSET',
        params: { stepId, problemSetId },
        mutators: {
          onResolve: (resolveAction: any) => {
            closeModal();
            return {
              ...resolveAction,
              body: {
                stepId,
                problemSetId,
                problemSetName
              },
            }
          }
        }
      }));
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateStepProblemSetModal);