import React, { useEffect } from 'react';
import styles from './styles.module.css';
import { EditButton } from './hintEditorButtons';
import LearnosityQuestion from '../../common/learnosityQuestion/component';
import LearnosityHintEditor from './learnosityHintEditor.component';
import Toolbar from '../../common/toolbar/container';
import { Modal } from 'sharedfrontend';
import commonStyles from '../../../common/commonStyles.module.css';
import { closeModal } from '../../../../actions/eventEmitter';
import { ImplementationType, Role } from '../../../../utils/constants';
import ToggleButton from '../../buttons/toggleButton/container';

interface IProps {
  currentHintIndex: number;
  hints: any[];
  isEditing: boolean;
  role: string;
  stepId: number;
  problemSetId: number;
  classId: number;
  numViewedStepHints?: number;
  startEditing: Function;
  stopEditing: Function;
  goToHint: Function;
  track: Function;
  fetchData: Function;
  saveHintEdits: Function;
  markAllHintsViewed: Function;
};

export default function HintsModal({
  currentHintIndex,
  hints,
  isEditing,
  role,
  stepId,
  problemSetId,
  classId,
  numViewedStepHints = 0,
  startEditing,
  stopEditing,
  goToHint,
  track,
  fetchData,
  saveHintEdits,
  markAllHintsViewed,
}: IProps) {
  const hintToShow = hints[currentHintIndex];
  const showHintNavButtons = hints.length > 1;
  const actionButtons = showHintNavButtons
    ? [{
      text: 'Previous Hint',
      callback: () => handleGoToHint(-1),
      classes: currentHintIndex === 0 ? commonStyles.disabled : undefined
    },
    {
      text: 'Next Hint',
      callback: () => handleGoToHint(1),
      classes: currentHintIndex === hints.length - 1 ? commonStyles.disabled : undefined
    }]
    : undefined;
  // Disable delete hint toggle when there is only one non deleted hint left
  // and current hint is not deleted (so that super admin can undelete the hint)
  const isDisabled = !hintToShow?.isDeleted && hints.filter((hint: any) => !hint.isDeleted).length === 1;

  useEffect(() => {
    fetchData(stepId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (hints.length > 0 && numViewedStepHints !== hints.length) {
      handleGoToHint(numViewedStepHints);
    }
    // eslint-disable-next-line
  }, [numViewedStepHints, hints]);

  const handleGoToHint = (indexChange: number) => {
    stopEditing();

    const indexToGoTo = currentHintIndex + indexChange;

    goToHint(indexToGoTo);

    trackHintView(indexToGoTo);
  }

  const trackHintView = (index: number) => {
    if (hints.length > 0) {
      track({
        event: 'viewed',
        hintId: hints[index].hintId,
        problemSetId,
        classId,
      });

      if (index === hints.length - 1) {
        markAllHintsViewed();
      }
    }
  }

  const onEditorSave = (hintId: number, hintJson: string) => {
    saveHintEdits(hintId, hintJson);
  }

  const onEditorClose = () => {
    stopEditing();
  }

  const handleClose = () => {
    goToHint(0);
    closeModal();
  }

  return hintToShow
    ? (
      <Modal
        title=""
        maxWidth={false}
        actionButtons={actionButtons}
        okText=""
        okCallback={() => null}
        cancelText="Close"
        cancelCallback={handleClose}
        disableEnforceFocus={true}
        id={hintToShow.hintId}
      >
        <div className={styles.hints}>
          {/* since learnosity injects html that react doesn't know about, we need an unmutated parent to unmount */}
          <div className="tts">
            <Toolbar targetId={`container-${hintToShow.hintId}`} ttsOnly />
          </div>
          {isEditing &&
            <div className="hintEditor">
              <LearnosityHintEditor
                id={hintToShow.hintId}
                learnosityJson={hintToShow.learnosityJson}
                onSave={onEditorSave}
                onClose={onEditorClose}
              />
            </div>
          }
          {/* since learnosity injects html that react doesn't know about, we need an unmutated parent to unmount */}
          {!isEditing &&
            <div className="hintRenderer" key={hintToShow.hintId}>
              <LearnosityQuestion
                id={hintToShow.hintId}
                learnosityJson={hintToShow.learnosityJson}
              />
            </div>
          }
          {role === Role.SUPERADMIN && !isEditing &&
            <React.Fragment>
              <EditButton onClick={startEditing} />
              <ToggleButton
                id={hintToShow.hintId}
                type={ImplementationType.HINT}
                isDisabled={isDisabled}
                tooltipText={isDisabled ? 'Cannot remove the last hint' : ''}
              />
            </React.Fragment>
          }
        </div>
      </Modal>
    )
    : <></>;
};