import React, { useState, useEffect } from 'react';
import { Checkbox } from '../../../../inputs';
import ExternalLink from '../../../../ExternalLink';
import { getLTISelectAssignmentCookieData, isLTISelectAssignmentModeActive } from '../../../../../helpers/lti';
import classNames from 'classnames';
import styles from '../styles.module.css';
import { getDisabledFeaturesMessageForLearnEntity } from '../../../../../helpers/textHelpers';
import { infoTexts, ClassSource } from '../../../../../helpers/constants';
import { sanitize } from '../../../../../helpers/commonHelpers';
import { plural } from '../../../../../helpers/textHelpers';
import { SelectValidator } from '../../../../formValidator';
import IconQuestionMarkInverse from '../../../../../icons/question-mark-inverse.svg';
import { MenuItem, makeStyles, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { AssignLayerData, LearnEntityInfo, ClassData, UserData } from '../../../types';
import { ReactSelectComponents, ReactSelect } from '../../../../inputs';
import LockIcon from '../../../../../icons/lock.svg';
import { Tooltip } from '../../../../tooltip';
import ExpandMoreIcon from '../../../../../icons/chevron-down.svg';
import OnDemandLiveRegion from 'on-demand-live-region';

const getStudentSelectStyles = (isChecked, isAlreadyAssigned) => ({
  container: (base: any) => ({
    ...base,
    width: 250,
    flexShrink: 0,
  }),
  control: (base: any, state) => ({
    ...base,
    padding: '1px 6px',
    cursor: 'pointer',
    '& input': {
      position: state.hasValue ? 'absolute' : 'relative',
    },
    opacity: isAlreadyAssigned ? 0.5 : 1,
    backgroundColor: isAlreadyAssigned ? '#CDCDCD' : 'transparent',
    color: isAlreadyAssigned ? '#000000 !important' : base.color,
  }),
  placeholder: (base) => ({
    ...base,
    color: isChecked
      ? isAlreadyAssigned
        ? '#000000 !important'
        : 'var(--text-color, #000000) !important'
      : 'var(--gray_verydark, #000000)',
  }),
});

export const useAccordionStyles = makeStyles({
  accordionArrow: {
    '& > svg': {
      width: 18,
      height: 18
    }
  },
  accordion: {
    boxShadow: '0 0 4px -1px #969696',
    background: 'var(--lighter-accent, #f7f9f9)',
    color: 'inherit',
    marginBottom: '30px !important;',
  },
  expandIcon: {
    color: 'inherit',
  },
  accordionDetails: {
    padding: '0 16px',
  },
});

interface Props {
  shouldShowExcerptDropdown: boolean;
  onExcerptsDropdownChange: Function;
  handleSelectedClassesUpdate: (selectedClassIds: number[], {
    classStudentMap,
    layerTextSectionExcerptId,
  }) => void;
  setUnAssignModalState: Function;
  selectedClassIds: number[];
  newlySelectedClassIds: number[];
  selectedTextSectionExcerptId: number | null;
  assignLayerData: AssignLayerData;
  onChooseSectionClick: any;
  learnEntityInfo: LearnEntityInfo;
  teacherClasses: ClassData[];
  userData: UserData;
  students: {
    isSelected: boolean;
    classId: number;
    firstName: string;
    lastName: string;
    userId: number;
  }[],
  setStudents: Function;
  setPremiumBannerModal: Function;
  setAssignLayerData: Function;
  unAssignCallback?: Function;
}

const getStudentDisplayName = (firstName: string, lastName: string) => {
  return (lastName ? (sanitize(lastName) + ', ') : '') + sanitize(firstName);
};

function ExcerptAndClassSelector({
  shouldShowExcerptDropdown,
  onExcerptsDropdownChange,
  onChooseSectionClick,
  selectedClassIds,
  newlySelectedClassIds,
  selectedTextSectionExcerptId,
  assignLayerData,
  handleSelectedClassesUpdate,
  setUnAssignModalState,
  learnEntityInfo,
  teacherClasses,
  userData,
  students,
  setStudents,
  setPremiumBannerModal,
  setAssignLayerData,
  unAssignCallback,
}: Props): JSX.Element {
  const [showGoogleClassRoomInfo, setShowGoogleClassRoomInfo] = useState(false);
  const [showAlreadyAssignedClasses, setShowAlreadyAssignedClasses] = useState(false);
  const accordionStyles = useAccordionStyles();
  const disabledFeaturesMsg = getDisabledFeaturesMessageForLearnEntity(learnEntityInfo);
  const teacherClassesToShow = assignLayerData.isBehindPaywall
    ? teacherClasses.filter((classData) => classData.isOnPrimePlan)
    : teacherClasses;
  const ltiSelectData = getLTISelectAssignmentCookieData();
  const isLTISelectMode = isLTISelectAssignmentModeActive();
  const filteredTeacherClasses = isLTISelectMode
    ? teacherClassesToShow.filter((teacherClass) => ltiSelectData?.classIds.includes(teacherClass.classId))
    : teacherClassesToShow;
  const alreadyAssignedClasses = filteredTeacherClasses
    .filter(c => selectedClassIds.includes(c.classId) && !newlySelectedClassIds.includes(c.classId));
  const notAssignedClasses = filteredTeacherClasses
    .filter(c => !selectedClassIds.includes(c.classId) || newlySelectedClassIds.includes(c.classId));

  useEffect(() => {
    setShowGoogleClassRoomInfo(
      teacherClasses.some(cls => newlySelectedClassIds.includes(cls.classId) && cls.source === ClassSource.GOOGLE),
    );
  }, [newlySelectedClassIds, teacherClasses]);

  useEffect(() => {
    if (notAssignedClasses.length === 0) {
      // When no new classes to assign then expand the previously assigned classes automatically
      setShowAlreadyAssignedClasses(true);
    }
  }, [notAssignedClasses.length]);

  function onClassCheckboxChange(isChecked, classId, userIds?: number[]): void {
    const classData = teacherClasses.find((classData) => classData.classId === classId);

    if (isChecked) {
      handleSelectedClassesUpdate([...selectedClassIds, classId], {
        classStudentMap: assignLayerData.classStudentMap,
        layerTextSectionExcerptId: selectedTextSectionExcerptId,
      });

      setStudents(students.map(s => {
        if (s.classId === classId) {
          s.isSelected = userIds ? userIds.includes(s.userId) : true;
        }
        return s;
      }));
    }
    else {
      const assignment = assignLayerData.classStudentMap.find(
        (classData) =>
          !!classData.assignmentId &&
          classData.isAssigned &&
          classData.classId === classId &&
          classData.layerTextSectionExcerptId === (selectedTextSectionExcerptId || null),
      );

      if (assignment) {
        setUnAssignModalState({
          className: classData?.name || '',
          classId,
          assignmentId: assignment.assignmentId,
          callback: () => {
            unselectClass(classId, true);
          },
        });
      }
      else {
        unselectClass(classId);
      }
    }
  }

  function unselectClass(classId, isUnAssigning = false) {
    selectedClassIds.splice(selectedClassIds.indexOf(classId), 1);
    handleSelectedClassesUpdate([...selectedClassIds], {
      classStudentMap: assignLayerData.classStudentMap,
      layerTextSectionExcerptId: selectedTextSectionExcerptId,
    });

    setStudents(students.map(s => {
      if (s.classId === classId) {
        s.isSelected = false;
      }
      return s;
    }));

    if (isUnAssigning) {
      const classStudentMapToSet = assignLayerData.classStudentMap.map((classData) => {
        if (classData.classId === classId && classData.layerTextSectionExcerptId === (selectedTextSectionExcerptId || null)) {
          classData.isAssigned = false;
        }

        return classData;
      });

      setAssignLayerData({
        fetched: true,
        data: {
          ...assignLayerData,
          classStudentMap: classStudentMapToSet,
        },
      });
      if (unAssignCallback) {
        unAssignCallback({ isAssignedToSomeClass: classStudentMapToSet.some(c => c.isAssigned) });
      }
    }
  }

  function onStudentSelected({ classId, userIds }): void {
    if (userIds.length > 0 && !selectedClassIds.includes(classId)) {
      onClassCheckboxChange(true, classId, userIds);
    }
    else if (userIds.length === 0 && selectedClassIds.includes(classId)) {
      onClassCheckboxChange(false, classId);
    }
    else {
      setStudents(students.map(s => {
        if (s.classId === classId) {
          s.isSelected = userIds.includes(s.userId);
        }
        return s;
      }))
    }
  }

  return (
    <div>
      {learnEntityInfo.isSummativeAssessment && (
        <>
          <div className="semiBold">{infoTexts.summativeAssessment.welcomeTitle}</div>
          <p>{infoTexts.summativeAssessment.teacherWelcomeMessage}</p>
        </>
      )}
      {userData.isOnPrimeUnlimited &&
        !!learnEntityInfo.rentalPrices &&
        !assignLayerData.isLicenseAvailableOnUnlimited && (
          <div className="mb-lg">
            Your school has reached its limit on the number of students who can access Unlimited copyrighted books. If
          you assign this text,{' '}
            <span className="warning">some of your students may not be able to access it.&nbsp;</span>
            <div className="mt-md">To get more licenses, please reach out to your administrator.</div>
          </div>
        )}
      {disabledFeaturesMsg && <div className="semiBold mb-lg">Note: {disabledFeaturesMsg}</div>}
      {shouldShowExcerptDropdown && (
        <>
          <div id="ddlExcerptsContainer" className="flex alignCenter fullWidth mb-lg">
            <SelectValidator
              id="ddlExcerpts"
              label="Choose sections or chapters"
              value={selectedTextSectionExcerptId}
              className={styles.expertSelect}
              fullWidth
              onChange={(ev) => onExcerptsDropdownChange(ev.target.value)}>
              <MenuItem value={0}>Entire text</MenuItem>
              {assignLayerData.excerpts
                .filter((excerpt) => excerpt.title)
                .map((excerpt, i, arr) => (
                  <MenuItem
                    value={excerpt.layerTextSectionExcerptId}
                    key={excerpt.layerTextSectionExcerptId}
                    className={i === arr.length - 1 ? 'divider' : ''}>
                    {sanitize(excerpt.title)}
                  </MenuItem>
                ))}
              <MenuItem onClick={() => onChooseSectionClick(selectedTextSectionExcerptId)} value={-1}>
                Choose specific sections
              </MenuItem>
            </SelectValidator>
            <ExternalLink href="https://go.activelylearn.com/assignexcerpt" className="ml-lg">
              {/* @ts-ignore */}
              <IconQuestionMarkInverse width={20} height={20} fill="#0077D1" />
            </ExternalLink>
          </div>
          <div id="createExcerpt" />
        </>
      )}
      {alreadyAssignedClasses.length > 0 && (
        <Accordion
          expanded={showAlreadyAssignedClasses}
          onChange={() => setShowAlreadyAssignedClasses(!showAlreadyAssignedClasses)}
          classes={{ root: accordionStyles.accordion }}>
          <AccordionSummary
            aria-controls="alreadyAssignedClasses"
            id="alreadyAssignedClassesToggler"
            expandIcon={<span className={accordionStyles.accordionArrow}><ExpandMoreIcon /></span>}
            classes={{ expandIcon: accordionStyles.expandIcon }}>
            Classes where this is already assigned
        </AccordionSummary>
          <AccordionDetails classes={{ root: accordionStyles.accordionDetails }}>
            <ul className="fullWidth" id="alreadyAssignedClasses">
              {alreadyAssignedClasses.map((classData) => (
                <ClassSelector
                  key={classData.classId}
                  isAlreadyAssigned
                  classData={classData}
                  userData={userData}
                  learnEntityInfo={learnEntityInfo}
                  shouldShowExcerptDropdown={shouldShowExcerptDropdown}
                  selectedTextSectionExcerptId={selectedTextSectionExcerptId}
                  assignLayerData={assignLayerData}
                  setPremiumBannerModal={setPremiumBannerModal}
                  onStudentSelected={onStudentSelected}
                  onClassCheckboxChange={onClassCheckboxChange}
                  students={students}
                />
              ))}
            </ul>
          </AccordionDetails>
        </Accordion>
      )}
      <ul className="notAssignedClasses">
        {notAssignedClasses.map((classData) => (
          <ClassSelector
            key={classData.classId}
            isChecked={selectedClassIds.includes(classData.classId)}
            classData={classData}
            userData={userData}
            learnEntityInfo={learnEntityInfo}
            shouldShowExcerptDropdown={shouldShowExcerptDropdown}
            selectedTextSectionExcerptId={selectedTextSectionExcerptId}
            assignLayerData={assignLayerData}
            setPremiumBannerModal={setPremiumBannerModal}
            onStudentSelected={onStudentSelected}
            onClassCheckboxChange={onClassCheckboxChange}
            students={students}
          />
        ))}
      </ul>
      {showGoogleClassRoomInfo && (
        <div className="info mt-lg">Note: Assignment links will be saved as drafts in your Google course streams.</div>
      )}
    </div>
  );
}

const MultiValueContainer = (props: any) => {
  const indexInValue = props.selectProps.value.findIndex((x: any) => x === props.data);
  // When all students selected then we show 'All students' but for the 1st value
  return indexInValue === 0
    ? props.selectProps.value.length === props.selectProps.options.length || props.selectProps['data-locked']
      ? <span>All students</span>
      : <span>{plural(props.selectProps.value.length, '%count student', '%count students')}</span>
    : null;
};

const Option = (props: any) => {
  return (
    <ReactSelectComponents.Option {...props} className={styles.studentOption}>
      <span>{props.label}</span>
      {props.isSelected && <span className="ml-md">✔</span>}
    </ReactSelectComponents.Option>
  );
};

const Control = ({ children, ...rest }: any) => {
  return (
    <Tooltip title={rest.selectProps['data-assigned']
      ? 'Students assigned to already assigned texts can be changed from classes → assignments'
      : ''}>
      <div>
        <ReactSelectComponents.Control {...rest}>
          {children}
        </ReactSelectComponents.Control>
      </div>
    </Tooltip>
  );
};

const useStyles = makeStyles((theme) => ({
  checkBoxLabel: {
    '& .MuiIconButton-root ': {
      alignSelf: 'start',
    },
    wordBreak: 'break-word',
    [theme.breakpoints.up(480)]: {
      maxWidth: 'calc(100% - 270px)',
    },
    [theme.breakpoints.down(480)]: {
      width: '100%',
    },
  },
  alreadyAssignedCheckbox: {
    '& .MuiCheckbox-root.Mui-checked': {
      color: '#969696',
    },
  },
  checkboxFocus: {
    "& .Mui-focusVisible": {
      "& .MuiSvgIcon-root": {
        outline: '2px solid var(--al-blue-dark)',
        borderRadius: '4px',
      }
    },
  }
}));

const ClassSelector = ({
  classData,
  shouldShowExcerptDropdown,
  assignLayerData,
  userData,
  learnEntityInfo,
  isAlreadyAssigned = false,
  isChecked = isAlreadyAssigned,
  students,
  onStudentSelected,
  setPremiumBannerModal,
  selectedTextSectionExcerptId,
  onClassCheckboxChange,
}) => {
  let oneAssigned = false;
  const styles = useStyles();

  if (!shouldShowExcerptDropdown) {
    oneAssigned = assignLayerData.classStudentMap.some(
      (assignedClass) => assignedClass.classId === classData.classId && assignedClass.isAssigned
        && !assignedClass.layerTextSectionExcerptId,
    );
  }

  const isAssigningPaidTextToNonUnlimitedClass =
    userData.isOnPrimeUnlimited && !!learnEntityInfo.rentalPrices && !classData.isOnUnlimitedPlan;
  const studentsInClass = students
    .filter(x => x.classId === classData.classId)
    .map(s => ({ value: s.userId, label: getStudentDisplayName(s.firstName, s.lastName), isSelected: s.isSelected }));
  const selectedStudents = studentsInClass.filter(s => s.isSelected);

  useEffect(() => {
    const onDemandLiveRegion = new OnDemandLiveRegion({
      level: 'assertive',
      delay: 500,
    });
    setTimeout(() => {
      const elm = document.getElementById('assignLayerModal')?.querySelector('.closeButton');
      if (elm !== document.activeElement) {
        if (isChecked) {
          onDemandLiveRegion.say(`${classData.name} All Students Selected`);
        }
        else {
          onDemandLiveRegion.say(`${classData.name} All Students Deselected`);
        }
      }
    }, 10);
  }, [isChecked]);

  return (
    <li className="flex spaceBetween flexWrap mt-lg mb-xl">
      <Checkbox
        disableRipple
        checked={isChecked}
        className={classNames(styles.checkBoxLabel, styles.checkboxFocus, { [styles.alreadyAssignedCheckbox]: isAlreadyAssigned })}
        label={
          <div>
            {sanitize(classData.name)}
            {isAssigningPaidTextToNonUnlimitedClass && <span>(not unlimited)</span>}
            {!oneAssigned && assignLayerData.existingAssignedStudentsCount[classData.classId] > 0 && (
              <div className="info">
                ({assignLayerData.existingAssignedStudentsCount[classData.classId]} of your students have
                already been assigned this text)
              </div>
            )}
          </div>
        }
        disabled={shouldShowExcerptDropdown && selectedTextSectionExcerptId === null}
        setChecked={(isChecked) => onClassCheckboxChange(isChecked, classData.classId)}
      />
      <ReactSelect
        placeholder="All students"
        components={{ MultiValueContainer, Control, Option, ClearIndicator: null, IndicatorSeparator: null }}
        suggestions={classData.isOnPrimePlan
          ? studentsInClass
          : [{
            label: (
              <span>Assign to individuals
                {/* @ts-ignore */}
                <LockIcon width={14} height={14} />
              </span>
            ),
            value: 'locked',
          }]
        }
        value={selectedStudents}
        onChange={(selectedStudentOptions: any, actionMeta) => {
          if (actionMeta.action === 'select-option' && actionMeta.option && actionMeta.option.value === 'locked') {
            setPremiumBannerModal({
              title: 'Assign to individuals',
              headerText: 'Easily differentiate instruction within your class by assigning texts to individual students.',
            });
          }
          else {
            onStudentSelected({
              classId: classData.classId,
              userIds: selectedStudentOptions.map((x: any) => x.value),
            });
          }
        }}
        noOptionsMessage={() => "No students in the class"}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isSearchable={false}
        customSelectStyles={getStudentSelectStyles(isChecked, isAlreadyAssigned)}
        renderMenuOutside
        menuIsOpen={isAlreadyAssigned ? false : undefined}
        data-assigned={isAlreadyAssigned}
        data-locked={!classData.isOnPrimePlan}
        menuPosition="fixed"
        isMulti
        className="selectStudentsAssignLayer"
        isDisabled={shouldShowExcerptDropdown && selectedTextSectionExcerptId === null}
      />
    </li>
  );
};

export default ExcerptAndClassSelector;
