import React from 'react';
import Menu from '@material-ui/core/Menu';
import { Button } from '../button';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, MenuList } from '@material-ui/core';
import { Tooltip } from '../tooltip';
import { isTouchDevice } from '../../helpers/device';
import { IPropsBtn } from '../button/Button';

interface IDropDown extends IPropsBtn {
  buttonContent?: JSX.Element | string | null;
  dropdownItems: IDropdownItem[];
  title?: JSX.Element | string;
  buttonClass?: string;
  id: string;
  align?: 'left' | 'right' | 'center';
  placement?: 'top' | 'bottom';
  menuClassName?: string;
  PaperProps?: any;
  Element?: React.ElementType | keyof JSX.IntrinsicElements;
  autoFocus?: boolean;
  onClose?: Function;
  showCaretInButton?: boolean;
  handleListItemFocus?: Function;
}

export interface IDropdownItem {
  content?: JSX.Element | string;
  onClick?: Function;
  cssClass?: string;
  ariaLabel?: string;
  subMenu?: IDropdownItem[];
  subMenuPosition?: 'left' | 'right';
  ariaChecked?: any;
  ariaHidden?: boolean;
  [key: string]: any;
  ariaSelected?: any;
}

const DefaultButton = ({ children, ...rest }) => <Button variant="default" {...rest}>{children}</Button>;

const useCaretStyles = makeStyles({
  caretRight: {
    border: '5px solid transparent',
    borderLeftColor: 'currentColor',
    right: 5,
    top: '50%',
    position: 'absolute',
    transform: 'translateY(-50%)',
  },
  caretDown: {
    display: 'inline-block',
    width: 10,
    '&::before': {
      content: '""',
      display: 'block',
      border: '5px solid transparent',
      borderTopColor: 'currentColor',
      right: 5,
      top: '57%',
      position: 'absolute',
      transform: 'translateY(-50%)',
    }
  },
  caretSpacing: {
    width: 20,
  }
});

export function Dropdown({
  buttonContent = null,
  dropdownItems,
  title = '',
  buttonClass = '',
  id,
  align = 'left',
  placement = 'bottom',
  menuClassName,
  PaperProps,
  Element = DefaultButton,
  autoFocus = true,
  onClick,
  onClose,
  showCaretInButton = false,
  keepMounted = true,
  handleListItemFocus,
  ...rest
}: IDropDown) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeItemIndex, setActiveItemIndex] = React.useState(-1);
  const menuRef = React.useRef<HTMLElement>(null);
  const subMenuExists = dropdownItems.some(item => item.subMenu);
  const caretStyles = useCaretStyles();

  const handleClick = ev => {
    openMenu(ev);

    if (onClick) {
      onClick(ev);
    }
  };

  const handleClose = () => {
    setActiveItemIndex(-1);
    setAnchorEl(null);
  };

  const handleMenuClose = () => {
    if (document.body.getAttribute('oneOrMoreTooltip') != 'true') {
      handleClose();
    }

    if (onClose) {
      onClose();
    }
  }

  const handleKeyPress = ev => {
    if (ev.key === 'ArrowDown' || ev.key === 'ArrowUp') {
      openMenu(ev);
    }
  }

  const openMenu = ev => {
    setAnchorEl(ev.currentTarget);

    setTimeout(() => {
      if (menuRef && menuRef.current) {
        const ulElement = menuRef?.current?.querySelector('ul') as HTMLElement;
        ulElement?.focus();
        ulElement?.parentElement?.setAttribute('role', 'region');
      }
    }, 0);
  }

  const setCurrentItem = (index, ev, onItemClick) => {
    setActiveItemIndex(index);
    onItemClick(ev);
  };

  return (
    <React.Fragment>
      <Element
        onClick={handleClick}
        onKeyDown={handleKeyPress}
        title={title}
        className={buttonClass}
        aria-controls={'menu' + id}
        id={'btn' + id}
        aria-haspopup="true"
        aria-expanded={anchorEl === null ? false : true}
        {...rest}>
        {buttonContent}
        {showCaretInButton && (
          <div className={caretStyles.caretDown} />
        )}
      </Element>
      {anchorEl && !!dropdownItems.length &&
        <Menu
          onClose={handleMenuClose}
          anchorEl={anchorEl}
          keepMounted={keepMounted}
          autoFocus={autoFocus}
          open={Boolean(anchorEl)}
          ref={menuRef}
          getContentAnchorEl={null}
          transitionDuration={subMenuExists ? 0 : undefined}
          anchorOrigin={{
            vertical: placement,
            horizontal: align,
          }}
          transformOrigin={{
            vertical: placement === 'bottom' ? 'top' : 'bottom',
            horizontal: align,
          }}
          className={menuClassName}
          PaperProps={PaperProps}
          id={'menu' + id}>
          {dropdownItems.map(({
            content, title, ariaLabel, ariaSelected, onClick = (ev) => null, cssClass = '', subMenu, ariaChecked, ariaHidden, ...rest }, idx) => (
              <MenuItemWithSubMenu
                menuClassName={menuClassName}
                key={idx} content={content}
                activeMenuItem={activeItemIndex}
                currentMenuItem={idx}
                aria-label={ariaLabel}
                role={ariaChecked && "menuitemradio"}
                aria-hidden={ariaHidden}
                onClick={(ev) => setCurrentItem(idx, ev, onClick)} handleClose={handleClose}
                onKeyDown={handleListItemFocus}
                title={title}
                ariaSelected={ariaSelected}
                cssClass={cssClass} subMenu={subMenu} aria-checked={ariaChecked} {...rest} />
            ))}
        </Menu>
      }
    </React.Fragment>
  );
}

const useSubMenuStyles = makeStyles({
  root: {
    position: 'fixed',
    backgroundColor: 'var(--theme-background, white)',
    opacity: 0,
    transition: 'opacity 0.1s ease-in',
    outline: 'none',
    boxShadow: '0 0 15px -3px rgba(0, 0, 0, 0.54)',
    zIndex: 1, // Need this so that the sub-menu appears over any menuItem
  },
});

const useMenuItemStyles = makeStyles({
  root: {
    fontSize: 16,
    padding: '10px 30px 10px 20px',
    '&:focus': {
      outline: '2px solid var(--al-blue)',
      outlineOffset: '-2px'
    }
  },
  button: {
    background: 'none',
    border: 'none',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    fontWeight: 'inherit',
    width: '100%',
    textAlign: 'inherit',
    cursor: 'pointer',
  }
});

const SUB_MENU_MARGIN_LEFT = 3;

function MenuItemWithSubMenu({
  content, onClick, title, ariaSelected = undefined, cssClass = '', activeMenuItem, currentMenuItem,
  subMenu, handleClose, menuClassName, subMenuPosition = 'right', onKeyDown, ...rest
}) {
  const [showSubMenu, setShowSubMenu] = React.useState(false);
  const [menuItemTooltip, setMenuItemTooltip] = React.useState(true);
  const menuItemRef = React.useRef<HTMLLIElement>(null);
  const subMenuRef = React.useRef<HTMLUListElement>(null);
  const subMenuCloseTimer = React.useRef<any>(null);
  const subMenuStyles = useSubMenuStyles();
  const caretStyles = useCaretStyles();
  const menuItemStyles = useMenuItemStyles();
  const [subMenuStyle, setSubMenuStyle] = React.useState({});
  const [activeItemIndex, setActiveItemIndex] = React.useState(-1);
  const touchDevice = isTouchDevice();

  const handleSubMenuOpen = () => {
    clearTimeout(subMenuCloseTimer.current);
    setShowSubMenu(true);
  }

  React.useEffect(() => {
    if (subMenuRef && subMenuRef.current && showSubMenu) {
      subMenuRef.current.focus();
    }
  }, [subMenuRef, showSubMenu]);

  React.useEffect(() => {
    if (touchDevice && currentMenuItem !== activeMenuItem) {
      handleSubMenuClose();
    }
  }, [activeMenuItem]);

  React.useEffect(() => {
    if (menuItemRef && menuItemRef.current && showSubMenu && subMenuRef && subMenuRef.current) {
      const left = subMenuPosition === 'right'
        ? menuItemRef.current.getBoundingClientRect().right
        : menuItemRef.current.getBoundingClientRect().left;
      const top = menuItemRef.current.getBoundingClientRect().top;
      const menuWidth = subMenuRef.current.clientWidth;
      const menuHeight = subMenuRef.current.clientHeight;
      setSubMenuStyle({
        left: subMenuPosition === 'right'
          ? Math.max(Math.min(left + SUB_MENU_MARGIN_LEFT, window.innerWidth - menuWidth), 0)
          : Math.max(left - SUB_MENU_MARGIN_LEFT - menuWidth, 0),
        top: Math.max(Math.min(top, window.innerHeight - menuHeight), 0),
        opacity: 1,
      });
    }
  }, [menuItemRef, subMenuRef, showSubMenu])

  const handleSubMenuClose = () => {
    subMenuCloseTimer.current = setTimeout(() => {
      setShowSubMenu(false);
    }, 100);
  }

  const handleSubMenuCloseWithKey = (ev) => {
    if (ev.key === 'Escape' || ev.key === 'ArrowLeft') {
      ev.stopPropagation();
      setShowSubMenu(false);

      if (menuItemRef && menuItemRef.current) {
        menuItemRef.current.focus();
      }
    }
  }

  const handleSubMenuOpenWithKey = (ev) => {
    if (ev.key === 'ArrowRight') {
      ev.stopPropagation();
      setShowSubMenu(true);
    }
  }

  const setCurrentItem = (index, ev, onItemClick) => {
    setActiveItemIndex(index);
    onItemClick(ev);
  };

  const onSubMenuClick = (e) => {
    handleSubMenuClose();
    handleClose();
  }

  return (
    <>
      {title ?
        (<MenuItem
            aria-label={title}
            classes={menuItemStyles}
            ref={menuItemRef}
            onFocus={(ev) => {
              if (ev.currentTarget.firstChild) {
                const mouseover = new Event('mouseover', {
                  bubbles: true,
                });
                ev.currentTarget.firstChild.dispatchEvent(mouseover);
              }
            }}
            className={cssClass}>
          <Tooltip title={title} isTooltipOpen={menuItemTooltip}>
            <button
              className={menuItemStyles.button}
              onClick={(ev) => {
                if (!subMenu) {
                  handleSubMenuClose();
                  setActiveItemIndex(-1);
                  handleClose();
                }
                else if (touchDevice && subMenu) {
                  if (showSubMenu) {
                    handleSubMenuClose();
                  }
                  else {
                    handleSubMenuOpen();
                  }
                }
                onClick(ev);
              }}
              aria-labelledby={`getLayerTitle-${currentMenuItem}`}
              onMouseEnter={() => subMenu && !touchDevice && handleSubMenuOpen()}
              onMouseLeave={() => subMenu && !touchDevice && handleSubMenuClose()}
              onKeyUp={(ev) => subMenu && handleSubMenuOpenWithKey(ev)}
              onKeyDown={(ev) => onKeyDown(ev, setMenuItemTooltip)}
              {...rest}>
              {content || ''}
              {subMenu?.length > 0 &&
                <>
                  <div className={caretStyles.caretSpacing} />
                  <div className={caretStyles.caretRight} />
                </>
              }
            </button>
          </Tooltip>
        </MenuItem>
        ) :
        (<MenuItem
          ref={menuItemRef}
          onClick={(ev) => {
            if (!subMenu) {
              handleSubMenuClose();
              setActiveItemIndex(-1);
              handleClose();
            }
            else if (touchDevice && subMenu) {
              if (showSubMenu) {
                handleSubMenuClose();
              }
              else {
                handleSubMenuOpen();
              }
            }

            onClick(ev);
          }}
          classes={menuItemStyles}
          aria-describedby={ariaSelected && 'selectedItem'}
          className={cssClass}
          aria-haspopup={subMenu ? true : undefined}
          aria-expanded={subMenu ? (showSubMenu ? true : false) : undefined}
          onMouseEnter={() => subMenu && !touchDevice && handleSubMenuOpen()}
          onMouseLeave={() => subMenu && !touchDevice && handleSubMenuClose()}
          onKeyUp={(ev) => subMenu && handleSubMenuOpenWithKey(ev)}
          {...rest}>
          {content || ''}
          {subMenu?.length > 0 &&
            <>
              <div className={caretStyles.caretSpacing} />
              <div className={caretStyles.caretRight} />
            </>
          }
        </MenuItem>
        )}
      {subMenu && showSubMenu &&
        <MenuList
          classes={subMenuStyles}
          ref={subMenuRef}
          style={subMenuStyle}
          onMouseEnter={() => clearTimeout(subMenuCloseTimer.current)}
          onMouseLeave={handleSubMenuClose}
          onKeyUp={(ev) => subMenu && handleSubMenuCloseWithKey(ev)}
          className={menuClassName}>
          {subMenu
            .map(({ onClick, content, cssClass = '', subMenu, ...rest }, idx) => (
              <MenuItemWithSubMenu
                ariaSelected={ariaSelected}
                title={undefined}
                onKeyDown={undefined}
                menuClassName={menuClassName}
                key={idx} content={content}
                activeMenuItem={activeItemIndex}
                currentMenuItem={idx}
                onClick={(ev) => setCurrentItem(idx, ev, onClick)} handleClose={onSubMenuClick}
                cssClass={cssClass} subMenu={subMenu} {...rest} />
            ))
          }
        </MenuList>
      }
    </>
  );
}
