import React from 'react';
import styles from './styles.module.css';
import SmallInput from './SmallInput';
import classNames from 'classnames';
import { showAlert } from '../../alert';
import { isValidNumber, isValidPercentage } from '../../../helpers/commonHelpers';

interface Props {
  legend: string;
  keys: string[];
  gradeLabels: { [key: string]: string };
  gradePercentages: any;
  maxPercentagesKey: string;
  invalidGradePercentages: object;
  setInvalidGradePercentages: Function;
  handleGradePercentageChange: Function;
}

/**
 *
 * GradePercentagesEditorPanel
 * @description Common panel for editing SA & MCQ percentages
 *
 */

function GradePercentagesEditorPanel({
  legend,
  keys,
  gradeLabels,
  gradePercentages,
  maxPercentagesKey,
  invalidGradePercentages,
  setInvalidGradePercentages,
  handleGradePercentageChange,
}: Props): JSX.Element {
  const [errorMessage, setErrorMessage] = React.useState({});

  function isValidGradePercentage(key, valueToCompare): boolean {
    const parsedValueToCompare = parseFloat(valueToCompare);
    const value = parseFloat(gradePercentages[key]);

    return key === 'incorrect' ? parsedValueToCompare > value : parsedValueToCompare >= value;
  }

  function getAlertMessage (): string {
    return maxPercentagesKey === 'advanced'
      ? 'Percentage for a grade must be higher or equal to percentage from lower grades.'
      : 'Percentage for correct Multi choice responses must be more than incorrect responses';
  }

  function onInputBlur (ev, key): void {
    const invalidPercentages = { ...invalidGradePercentages };
    const value = ev.target.value;

    if (!isValidNumber(value) || !isValidPercentage(value)) {
      invalidPercentages[key] = true;
      setErrorMessage({
        ...errorMessage,
        [key]: "Please enter a valid percentage value in between 1% to 100%.",
      });
    }
    else {
      let isAnyInvalidKey = false;
      delete invalidPercentages[key];

      // Need to check overall grade percentages whenever any input blurs
      keys.forEach((key, index) => {
        const valueToCompare = (index === 0) ? null : gradePercentages[keys[index - 1]];

        if (isValidNumber(valueToCompare) && !isValidGradePercentage(key, valueToCompare)) {
          invalidPercentages[keys[index - 1]] = true;
          isAnyInvalidKey = true;
        }
        else {
          if (errorMessage[key] !== "Please enter a valid percentage value in between 1% to 100%.") {
            delete invalidPercentages[key];
          }
        }
      });

      if (isAnyInvalidKey) {
        setErrorMessage({ ...errorMessage, [key]: getAlertMessage() });
      }
    }
    setInvalidGradePercentages(invalidPercentages);
  }

  const renderGradePercentages = () => (
    keys.map((key, index) => (
      <div className="flex my-lg" key={key}>
        <label className="flex">
          <span className={styles.ansPercentagesLabel}>{gradeLabels[key]}</span>
          <SmallInput
            className={classNames({
              'ml-lg mr-sm': true,
              flash: invalidGradePercentages[key]
            })}
            value={gradePercentages[key]}
            onBlur={(ev) => onInputBlur(ev, key)}
            min={0}
            max={100}
            onChange={(ev) => handleGradePercentageChange(key, ev.target.value)}
            aria-describedby={`gradingSettingsError-${index}`}
          /> %
        </label>
        {(errorMessage && invalidGradePercentages[key]) && 
          <div
            className="warning"
            role="alert"
            aria-live="assertive"
            id={`gradingSettingsError-${index}`}
          >
            {errorMessage[key]}
          </div>
        }
      </div>
    ))
  );

  return (
    <fieldset className="my-lg">
      <legend className="heading">{legend}</legend>
      {renderGradePercentages()}
    </fieldset>
  );
}

export default GradePercentagesEditorPanel;
