import { Modal } from 'sharedfrontend';
import React from 'react';
import { postDataPlain } from '../../../actions/api';
import { closeModal } from '../../../actions/eventEmitter';

interface ILevelSetData {
  benchmarkId: number;
  studentUserId: number;
  problemSetId: number;
};

export const ResumeOrReadministerLevelSetModal = ({ levelSetData, onReset }: {
  levelSetData: ILevelSetData;
  onReset: any;
}): JSX.Element | null => {
  return (
    <Modal
      title="Resume or Readminister LevelSet"
      maxWidth="sm"
      okText=""
      actionButtons={[
        {
          text: 'Readminister',
          callback: () => onSave({ shouldResetResponses: true, levelSetData, onReset }),
        },
        {
          text: 'Resume',
          callback: () => onSave({ shouldResetResponses: false, levelSetData, onReset }),
        },
      ]}
      cancelText="Cancel"
      cancelCallback={closeModal}
    >
      <React.Fragment>
        <p>This student answered 5 problems in less than 2 minutes (and at least 3 problems were incorrect).</p>
        <p>You can readminister which starts the student back at question 1, or you can resume which will allow 
          the student to resume where they left off.</p>
      </React.Fragment>
    </Modal>
  );
};

export const ReadministerLevelSetModal = ({ levelSetData, onReset }: {
  levelSetData: ILevelSetData;
  onReset: any;
}): JSX.Element | null => {
  return (
    <Modal
      title="Readminister LevelSet"
      maxWidth="sm"
      okText="Readminister"
      okCallback={() => onSave({ shouldResetResponses: true, levelSetData, onReset })}
      cancelText="Cancel"
      cancelCallback={closeModal}
    >
      <React.Fragment>
        <p>This will start the student back at question 1.</p>
      </React.Fragment>
    </Modal>
  );
};

const onSave = async ({ shouldResetResponses, levelSetData, onReset }: {
  shouldResetResponses: boolean;
  levelSetData: ILevelSetData;
  onReset: Function;
}) => {
  closeModal();

  await postDataPlain({
    type: 'RESET_LEVELSET',
    params: {
      benchmarkId: levelSetData.benchmarkId,
      studentUserId: levelSetData.studentUserId,
      problemSetId: levelSetData.problemSetId,
      resetResponses: shouldResetResponses,
    }
  });

  onReset();
};
