import React, { useState } from 'react';
import { SelectValidator } from '../../formValidator';
import {
  gradeNameSets,
  mcqGradeNameMaps,
  GRADE_NAME_KEYS_IN_ORDER,
  CUSTOM_GRADE_LEVELS_OPTION,
} from '../../../helpers/constants';
import { MenuItem, Box } from '@material-ui/core';
import ConfirmClassSettingsUpdateModal from './ConfirmClassSettingsUpdateModal';
import CustomGradingLabelsModal from './CustomGradingLabelsModal';
import GradePercentagesEditorPanel from './GradePercentagesEditorPanel';
import { Modal } from '../../modal';
import PencilIcon from '../../../icons/pencil.svg';
import Button from '../../button/Button';
import { Checkbox } from '../../inputs';
import styles from './styles.module.css';
import { isEmptyObj, isEqual } from '../../../helpers/objectHelpers';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

interface Props {
  hideModal: Function;
  isTeacherOwner?: boolean;
  triggeredFromReader?: boolean;
  editGradingSettingsCallback: Function;
  gradebookSettings: {
    gradeLabelsOption: string;
    shortAnswer: {
      gradePercentages: {
        zero: number;
        incomplete: number;
        basic: number;
        proficient: number;
        advanced: number;
      }
    };
    gradeLabels?: string;
    multipleChoice: {
      gradePercentages: {
        correct: number;
        incorrect: number;
      }
    };
  };
}

interface MCQGradePercentagesState {
  correct: number | string;
  incorrect: number | string;
}

interface SAGradePercentagesState {
  zero: number | string;
  incomplete: number | string;
  basic: number | string;
  proficient: number | string;
  advanced: number | string;
}

/**
 *
 * GradingSettingsModal
 * @description Modal for updating grading settings including SA labels, SA & MCQ percentages & Allow SA Grade Tagging
 *
 */

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'var(--gray_verydark)',
    },
    '& .MuiSelect-outlined': {
      display: 'flex',
    }
  },
  editCustomLabelButton: {
    width: '10%',
    height: '48px',
    alignSelf: 'center',
    marginTop: '8px',
    backgroundColor: 'transparent',
    border: '1px solid black',
    borderRadius: '0px 3px 3px 0px',
    borderLeft: 'none',
    '&:focus': {
      outlineOffset: '-1px',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    }
  },
  boxRoot: {
    display: 'flex',
    flexDirection: 'row',
  },
  borderRadiusHandler: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '3px 0px 0px 3px',
    }
  }
});

function GradingSettingsModal({
  hideModal,
  gradebookSettings,
  editGradingSettingsCallback,
  isTeacherOwner,
  triggeredFromReader,
}: Props): JSX.Element {
  const [selectedSAGradeLabel, setSelectedSAGradeLabel] = useState(
    gradebookSettings.gradeLabelsOption
  );
  const [shortAnswerGradePercentages, setShortAnswerGradePercentages] = useState<SAGradePercentagesState>(
    gradebookSettings.shortAnswer.gradePercentages
  );
  const [mcqAnswerGradePercentages, setMCQAnswerGradePercentages] = useState<MCQGradePercentagesState>(
    gradebookSettings.multipleChoice.gradePercentages
  );
  const [invalidGradePercentages, setInvalidGradePercentages] = useState<object>({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [updateForAllClass, setUpdateForAllClass] = useState(false);
  const gradeLabels = gradebookSettings.gradeLabels
    ? JSON.parse(gradebookSettings.gradeLabels)
    : {};
  const [customSAGradeLabels, setCustomSAGradeLabels] = useState(
    Object.keys(gradeLabels).length === 0 ? undefined : gradeLabels
  );
  const [
    showCustomGradingLabelsModal,
    setShowCustomGradingLabelsModal,
  ] = useState(false);

  const customstyles = useStyles();
  const customGradeLabel = customSAGradeLabels ? GRADE_NAME_KEYS_IN_ORDER.map(
    (key) => customSAGradeLabels[key]
  ).join(" . ") : '';
  const boxStyling = classNames({
    [customstyles.boxRoot]: true,
    [customstyles.borderRadiusHandler]: customSAGradeLabels && (selectedSAGradeLabel === '5')
  });

  function handleSAGradeChange(ev): void {
    if (ev.target.value === "addNew") {
      setShowCustomGradingLabelsModal(true);
    }
    else {
      setSelectedSAGradeLabel(ev.target.value.toString());
    }
  }

  function handleSAGradePercentageChange(key: string, value: string): void {
    const newShortAnswerGradePercentages = {
      ...shortAnswerGradePercentages,
      [key]: value,
    };
    setShortAnswerGradePercentages(newShortAnswerGradePercentages);
  }

  function handleMCQGradePercentagesChange(key: string, value: string): void {
    const newMCQAnswerGradePercentages = {
      ...mcqAnswerGradePercentages,
      [key]: value,
    };
    setMCQAnswerGradePercentages(newMCQAnswerGradePercentages);
  }

  function handleSubmit(parsedGradePercentagesData): void {
    const newSubSettings = {
      gradeLabelsOption: selectedSAGradeLabel,
      gradeLabels: customSAGradeLabels
        ? JSON.stringify(customSAGradeLabels)
        : undefined,
      ...parsedGradePercentagesData,
    };

    editGradingSettingsCallback(newSubSettings, updateForAllClass);
    hideModal();
  }

  function parseGradePercentages(
    data: SAGradePercentagesState | MCQGradePercentagesState
  ) : { [key: string]: number | string } {
    return Object.keys(data).reduce((result, item) => {
      result[item] = parseToFloat(data[item]);

      return result;
    }, {});
  }

  function parseToFloat(value: string | number): string | number {
    return isNaN(parseFloat(value.toString()))
      ? ''
      : parseFloat(value.toString());
  }

  function getParsedGradePercentagesData () {
    return {
      shortAnswer: {
        gradePercentages: parseGradePercentages(shortAnswerGradePercentages),
      },
      multipleChoice: {
        gradePercentages: parseGradePercentages(mcqAnswerGradePercentages),
      }
    };
  }

  function validatePreSubmit(modal): void {
    const parsedGradePercentagesData = getParsedGradePercentagesData();
    modal.enableOkButton();

    if (!isEmptyObj(invalidGradePercentages)) {
      return;
    }
    else if (triggeredFromReader) {
      const newSubSettings = {
        gradeLabelsOption: selectedSAGradeLabel,
        gradeLabels: customSAGradeLabels
          ? JSON.stringify(customSAGradeLabels)
          : '{}',
        ...parsedGradePercentagesData,
      };

      if (isEqual(gradebookSettings, newSubSettings)) {
        hideModal();
      }
      else {
        setShowConfirmationModal(true);
      }
    }
    else {
      handleSubmit(parsedGradePercentagesData);
    }
  }

  function handleCancelCallback(): void {
    if (!document.getElementById('customGradingModal')) {
      hideModal();
    }
  }

  function onUpdateForAllClass(): void {
    setUpdateForAllClass(!updateForAllClass);
  }

  React.useEffect(() => {
    setTimeout(() => {
      document.querySelector('#gradingSettings .modal-body')?.removeAttribute('tabindex');
    }, 100);
  }, []);

  return (
    <>
      <Modal
        onOpenCallback={() => {
          setTimeout(() => {
            document.getElementsByClassName('MuiDialogContent-root')[0].removeAttribute('tabindex');
          }, 200);
        }}
        title="Grading settings"
        okText="Save"
        okCallback={validatePreSubmit}
        cancelCallback={handleCancelCallback}
        id="gradingSettings"
        cssClass={customstyles.root}
      >
        <Box
          className={boxStyling}
        >
          <SelectValidator
            value={selectedSAGradeLabel}
            id="selectGradingSettingsSAGradeLabels"
            className="mt-lg"
            onChange={handleSAGradeChange}
            label="Labels for short answer grades"
            fullWidth
            hideAriaLabel
          >
            {Object.keys(gradeNameSets)
              .sort((id1, id2) => parseInt(id1, 10) - parseInt(id2, 10))
              .map((id) => (
                <MenuItem
                  key={id}
                  value={id}
                  className={classNames({
                    "menuitem selected": id === selectedSAGradeLabel
                  })}
                >
                  {Object.values(gradeNameSets[id]).join(" · ")}
                </MenuItem>
              ))}
            {customSAGradeLabels ? (
              <MenuItem key={5} value={5}>
                {customGradeLabel}
              </MenuItem>
            ) : (
              <MenuItem key={5} value="addNew" id="addYourOwnButton">
                <div className="btn-box callout">Add your own</div>
                <span className="visuallyHidden screen-reader-only">Button</span>
              </MenuItem>
            )}
          </SelectValidator>
          {(customSAGradeLabels && selectedSAGradeLabel === '5') &&
            <Button
              aria-label={`Edit custom label ${customGradeLabel}`}
              title="Click here to edit custom label"
              onClick={() => setShowCustomGradingLabelsModal(true)}
              className={customstyles.editCustomLabelButton}
              id="editCustomLabel"
            >
              {/* @ts-ignore */}
              <PencilIcon width={14} height={14} />
            </Button>
          }
        </Box>
        <GradePercentagesEditorPanel
          legend="Percentages for short answer responses"
          keys={[...GRADE_NAME_KEYS_IN_ORDER]}
          gradeLabels={
            gradeNameSets[selectedSAGradeLabel] || customSAGradeLabels
          }
          gradePercentages={shortAnswerGradePercentages}
          handleGradePercentageChange={handleSAGradePercentageChange}
          maxPercentagesKey="advanced"
          setInvalidGradePercentages={setInvalidGradePercentages}
          invalidGradePercentages={invalidGradePercentages}
        />
        <GradePercentagesEditorPanel
          legend="Percentages for multiple choice responses"
          keys={Object.keys(mcqAnswerGradePercentages)}
          gradeLabels={mcqGradeNameMaps}
          gradePercentages={mcqAnswerGradePercentages}
          handleGradePercentageChange={handleMCQGradePercentagesChange}
          maxPercentagesKey="correct"
          setInvalidGradePercentages={setInvalidGradePercentages}
          invalidGradePercentages={invalidGradePercentages}
        />
        {isTeacherOwner && !triggeredFromReader && (
          <div className="hideForMath subsection label">
            <Checkbox
              checked={updateForAllClass}
              setChecked={onUpdateForAllClass}
              className={styles.checkbox}
              label="Apply these settings to all your classes"
            />
          </div>
        )}
      </Modal>
      {showConfirmationModal && (
        <ConfirmClassSettingsUpdateModal
          hideModal={() => setShowConfirmationModal(false)}
          okCallback={() => handleSubmit(getParsedGradePercentagesData())}
        />
      )}
      {showCustomGradingLabelsModal && (
        <CustomGradingLabelsModal
          customSAGradeLabels={customSAGradeLabels}
          hideModal={() => setShowCustomGradingLabelsModal(false)}
          onSubmit={(labels) => {
            setCustomSAGradeLabels(labels);

            if (labels) {
              setSelectedSAGradeLabel(CUSTOM_GRADE_LEVELS_OPTION.toString());
            }
          }}
        />
      )}
    </>
  );
}

export default GradingSettingsModal;
