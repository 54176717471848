/* tslint:disable */
import React from 'react';
import store from './store';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import config from './config';
import * as autoLogoutUtils from './utils/autoLogout';
import * as iframeUtils from './utils/iframe';
import RedirectToAL from './components/redirectToAL';
import PrivateRoute from './components/privateRoute/container';
import Admin from './components/admin/adminReports.container';
import Catalog from './components/catalog/container';
import Reveal from './components/reveal/container';
import ClassData from './components/classData/container';
import StudentData from './components/studentData/studentData.container';
import StudentView from './components/studentView/container';
import Reader from './components/reader/container';
import { eventEmitter, Events } from './actions/eventEmitter';
import { createGenerateClassName, MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import muiTheme from './utils/muiTheme';
import './styles/layout.css';
import './styles/spacing.css';
import './styles/popup.css';
import './styles/text.css';

const generateClassName = createGenerateClassName({
  productionPrefix: 'math',
});

autoLogoutUtils.checkForAutoLogoutOnLoad();
iframeUtils.startListener(store);

const App: React.FC = () => {
  const [modalElements, setModalElements] = React.useState([] as any);
  eventEmitter.subscribe(Events.OPEN_MODAL, (element: JSX.Element) => setModalElements([...modalElements, element]));
  eventEmitter.subscribe(Events.CLOSE_MODAL, (count = 1) => setModalElements([...modalElements].slice(0, -count)));

  return (
    <StylesProvider generateClassName={generateClassName}>
      <MuiThemeProvider theme={muiTheme}>
        <IdleTimer
          element={document}
          debounce={250}
          onAction={autoLogoutUtils.onAction}
          onIdle={autoLogoutUtils.onIdle}
          timeout={config.maxInactiveTime}
        />
        <Provider store={store}>
          <Router>
            <Switch>
              <PrivateRoute path="/mathreader" component={Reader} />
              <PrivateRoute path="/catalog" component={Catalog} />
              <PrivateRoute path="/reveal/:bookTitleSlug?" component={Reveal} />
              <PrivateRoute path="/school" component={Admin} />
              <PrivateRoute path="/classdata/:classId/:tabName" component={ClassData} />
              <PrivateRoute path="/studentdata/:classId/:tabName" component={StudentData} />
              <PrivateRoute path="/studentview/:classId" component={StudentView} />
              <RedirectToAL />
            </Switch>
          </Router>
          {modalElements.map((elem: any, idx: number) => <React.Fragment key={idx}>{elem}</React.Fragment>)}
        </Provider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default App;