import React from 'react';
import ReactPlayer from 'react-player';

interface IProps {
  url: string;
  controls?: boolean;
  config?: any;
  setDuration: Function;
  handleOnPlay: Function;
  setVideoPlayedTime: Function;
}

function VideoPlayer({
  url,
  controls = false,
  config = {},
  setDuration,
  handleOnPlay,
  setVideoPlayedTime,
}: IProps) {
  const handleOnDuration = (duration: number) => {
    setDuration(duration);
  }

  return (
    <ReactPlayer
      width="100%"
      url={url}
      controls={controls}
      config={config}
      onPlay={() => handleOnPlay()}
      onPause={() => setVideoPlayedTime()}
      onDuration={handleOnDuration}
      onBuffer={() => setVideoPlayedTime()}
      onEnded={() => setVideoPlayedTime()}
    />
  );
};

export default VideoPlayer;
