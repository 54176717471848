import ShowOriginalProblem from './component';
import { connect } from 'react-redux';
import { postData } from '../../../../actions/api';
import { closeModal } from '../../../../actions/eventEmitter';

const mapStateToProps = (state: any) => {
  return {
    problemId: state.readerNavigation.currentProblemId,
    numAttempts: state.problemData.numAttempts,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    restartProblem: (problemId: number) => {
      dispatch(postData({
        type: 'RESTART_PROBLEM',
        params: { problemId },
      }));
      closeModal();
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowOriginalProblem);
