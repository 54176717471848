export enum Events {
  OPEN_MODAL = 'openModal',
  CLOSE_MODAL = 'closeModal',
};

export const eventEmitter = {
  _events: ({} as any),
  dispatch(event: Events, data?: any) {
    if (!this._events[event]) {
      return;
    }
    this._events[event](data);
  },
  subscribe(event: Events, callback: (data: any) => any) {
    this._events[event] = callback;
  },
  unsubscribe(event: Events) {
    if (!this._events[event]) {
      return;
    }
    delete this._events[event];
  }
};

export const openModal = (obj: any) => {
  eventEmitter.dispatch(Events.OPEN_MODAL, obj);
};

export const closeModal = (obj?: any) => {
  eventEmitter.dispatch(Events.CLOSE_MODAL, obj);
};
