import GradeFeedbackBar from './component';
import { connect } from 'react-redux';
import { closeFeedbackBar } from '../../../actions/readerNavigation';
import { Role } from '../../../utils/constants';

const mapStateToProps = (state: any) => {
  const { currentProblemId, currentStepId } = state.readerNavigation;
  const isOnStep = !!currentStepId;
  const response = isOnStep ? state.stepData.learnosityJson.responses : state.problemData.learnosityJson.responses;
  const numAttempts = isOnStep ? state.stepData.numAttempts.toString() : '';

  return {
    id: isOnStep ? currentStepId : currentProblemId,
    gradeFeedbackData: state.gradeFeedbackData,
    isOnStep,
    isProblemCompleted: state.problemSetData.completedProblemIds.includes(currentProblemId),
    responseExists: response && state.userData.role === Role.STUDENT,
    role: state.userData.role,
    isBenchmark: state.problemSetData.isBenchmark,
    numAttempts,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    closeFeedbackBar: () => {
      dispatch(closeFeedbackBar());
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GradeFeedbackBar);
