import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chartjs-plugin-labels';
import { Fonts } from '../../helpers/constants';

const demoData = {
  labels: [
    'Red',
    'Blue',
    'Yellow'
  ],
  data: [300, 50, 100],
  colors: [
    '#FF6384',
    '#36A2EB',
    '#FFCE56'
  ],
};

const PieChart = ({ config }: any) => {
  const {
    labels,
    data,
    colors,
    tooltipOptionOverrides,
    additionalOptions,
    pluginLabel,
  } = config

  const chartData = {
    labels: labels || demoData.labels,
    datasets: [{
      data: data || demoData.data,
      borderColor: 'transparent',
      hoverBorderColor: 'transparent',
      backgroundColor: colors || demoData.colors,
      hoverBackgroundColor: colors || demoData.colors,
    }],
  };

  const options = {
    tooltips: {
      backgroundColor: '#fff',
      borderColor: '#0077d1',
      borderWidth: 2,
      titleFontColor: '#0077d1',
      bodyFontColor: '#0077d1',
      titleFontFamily: Fonts.MEDIUM,
      bodyFontFamily: Fonts.MEDIUM,
      bodyFontSize: 15,
      displayColors: false,
      xPadding: 15,
      yPadding: 15,
      titleMarginBottom: 10,
      ...tooltipOptionOverrides,
    },
    legend: { display: false },
    plugins: {
      // chartjs labels plugin - https://github.com/emn178/chartjs-plugin-labels
      labels: {
        showZero: false,
        fontColor: colors || demoData.colors,
        fontSize: 15,
        fontFamily: Fonts.MEDIUM,
        position: 'outside',
        textMargin: 10,
        ...pluginLabel,
      }
    },
    ...additionalOptions
  };

  return <Pie data={chartData} options={options} />;
};

export default PieChart;
