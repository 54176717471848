import GoToFirstStepButton from './component';
import { connect } from 'react-redux';
import { postData } from '../../../../actions/api';
import { goToStep } from '../../../../actions/readerNavigation';

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    disableButton: state.readerNavigation.submittedResponse,
    forSkipping: ownProps.type === 'beforeAnswering',
    problemId: state.readerNavigation.currentProblemId,
    problemSetId: state.problemSetData.problemSetId,
    classId: state.problemSetData.classId,
    firstStepId: state.problemData.stepIds[0],
    ageRange: state.problemData.ageRange,
    role: state.userData.role,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    goToStep: (stepId: number) => {
      dispatch(goToStep(stepId));
    },
    skipToSteps: (problemId: number, stepId: number, problemSetId: number, classId: number) => {
      dispatch(postData({
        type: 'SKIPPED_TO_STEPS',
        params: { problemId, problemSetId, classId },
        mutators: {
          onResolve: (resolveAction: any) => {
            return {
              ...resolveAction,
              body: {
                ...resolveAction.body,
                stepId,
              },
            }
          }
        } 
      }));
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoToFirstStepButton);
