import React from 'react';
import { makeStyles, Theme } from "@material-ui/core/styles";
import MuiTabPanel from '@material-ui/lab/TabPanel';

interface TabPanelProps {
  value: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: '#a1a1a1',
    padding: '20px 10px',

    [theme.breakpoints.up('sm')]: {
      padding: '24px'
    }
  }
}));

const TabPanel: typeof MuiTabPanel = ((props: TabPanelProps) => {
  const styles = useStyles();

  return <MuiTabPanel classes={styles} {...props} />;
});

export default TabPanel;
