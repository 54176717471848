import produce from 'immer';
import { getCurrentSchoolYearDatesAsString } from '../utils/common';

const { startDate, endDate } = getCurrentSchoolYearDatesAsString();

const initialState = {
  startDate,
  endDate,
};

const reducer: any = produce((draft, action: any) => {
  switch (action.type) {
  case 'DATE_RANGE_SELECTED': {
    Object.assign(draft, { ...action.body });
    break;
  }
  }
}, initialState);

export default reducer;