import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Tooltip } from '../tooltip/index';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#fafafa',
    boxSizing: 'border-box',
    border: '1px solid #dedede',
    padding: '15px 25px',
    width: '100%',

    '& h2': {
      fontSize: '16px',
      margin: '0 0 20px',
    },
  },
  bar: {
    backgroundColor: '#e4e4e4',
    height: '10px',
    borderRadius: '15px',
    position: 'relative'
  },
  range: {
    backgroundColor: '#0077d1',
    opacity: 0.3,
    height: '10px',
    borderRadius: '15px',
    position: 'absolute',
  },
  value: {
    height: '26px',
    width: '26px',
    backgroundColor: '#0077d1',
    position: 'absolute',
    bottom: '-8px',
    borderRadius: '50%',
    transform: 'translate(-50%, 0)'
  },
  scale: {
    alignItems: 'flex-end',
    borderBottom: '2px solid #dedede',
    height: '30px',
    display: 'flex',
  },
  ticks: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  minmaxTick: {
    backgroundColor: '#dedede',
    height: '12px',
    width: '2px',
  },
  tick: {
    borderLeft: '2px dashed #dedede',
    height: '20px',
    width: '2px',
  },
  tickLabels: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tickLabel: {
    fontSize: '12px',
    textTransform: 'uppercase',
    color: '#0077d1',
    margin: '4px 0'
  },
  rangeLabels: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  rangeLabel: {
    color: '#a1a1a1',
    margin: '0',
  },
})

interface RangeBound {
  value: number,
  label: string,
}

interface Range {
  label: string,
  lowerBound: RangeBound,
  upperBound: RangeBound,
}

interface PercentileBarChart {
  title: string;
  data: {
    ranges: Range[],
    value: number,
    label: string,
    minUncertainty: number,
    maxUncertainty: number,
  };
  className?: string;
}

const PercentileBarChart: React.FunctionComponent<PercentileBarChart> = (props) => {
  const { title, data, className } = props;
  const styles = useStyles();

  const calculateValuePlacement = (value: number) => {
    const { ranges } = data;
    const rangePercentWidth = 100 / ranges.length;

    // find range value is in
    let rangeValueIsIn = 0;
    let offset = 0;
    for (const range of ranges) {
      if (value >= range.lowerBound.value && value < range.upperBound.value) {
        offset = (value - range.lowerBound.value) / (range.upperBound.value - range.lowerBound.value)
          * rangePercentWidth;
        break;
      }
      else {
        rangeValueIsIn++;
      }
    }

    // find percent in that range * range num * 25 (or 20 if 5 levels)
    const valuePercentage = rangeValueIsIn * rangePercentWidth + offset;

    return {
      leftValue: valuePercentage,
      left: `${valuePercentage}%`,
    };
  }

  const calculateUncertaintyRangePlacement = () => {
    const { minUncertainty, maxUncertainty } = data;

    const uncertaintyStart = calculateValuePlacement(minUncertainty);
    const uncertaintyEnd = calculateValuePlacement(maxUncertainty);

    return {
      left: `${uncertaintyStart.leftValue}%`,
      right: `${100 - uncertaintyEnd.leftValue}%`
    };
  }

  const renderTicks = () => {
    const { ranges } = data;

    return ranges.map((range, index) => {
      let tickStyle = styles.tick;

      if (index === 0) {
        tickStyle = styles.minmaxTick;
      }
      else if (index === ranges.length - 1) {
        return (
          <React.Fragment key={index}>
            <span className={styles.tick}></span>
            <span className={styles.minmaxTick}></span>
          </React.Fragment>
        )
      }

      return (
        <span key={index} className={tickStyle}></span>
      )
    });
  }

  const renderTickLabels = () => {
    const { ranges } = data;

    return ranges.map((range, index) => {
      if (index === ranges.length - 1) {
        return (
          <React.Fragment key={range.lowerBound.label}>
            <span className={styles.tickLabel}>{range.lowerBound.label}</span>
            <span className={styles.tickLabel}>{range.upperBound.label}</span>
          </React.Fragment>
        )
      }
      else {
        return (
          <span key={range.lowerBound.label} className={styles.tickLabel}>{range.lowerBound.label}</span>
        )
      }
    });
  }

  const renderRangeLabels = () => {
    const { ranges } = data;

    return ranges.map((range, index) => (
      <span key={range.label} className={styles.rangeLabel}>{range.label}</span>
    ));
  }

  return (
    <div className={classnames(styles.root, className)}>
      <h2>{title}</h2>
      <div className={styles.bar}>
        <div className={styles.range} style={calculateUncertaintyRangePlacement()}></div>
        <Tooltip title={data.label} tooltipColor="blue" tooltipPlacement="top">
          <div className={styles.value} style={calculateValuePlacement(data.value)}></div>
        </Tooltip>
      </div>
      <div className={styles.scale}>
        <div className={styles.ticks}>
          {renderTicks()}
        </div>
      </div>
      <div className={styles.tickLabels}>
        {renderTickLabels()}
      </div>
      <div className={styles.rangeLabels}>
        {renderRangeLabels()}
      </div>
    </div>
  );
};

export default PercentileBarChart;
