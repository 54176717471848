import React from 'react';
import classnames from 'classnames';
import { Button } from '../../button';
import ExpandIcon from '../../../icons/expand.svg';
import CollapseIcon from '../../../icons/collapse.svg';
import styles from './styles.module.scss';

type MenuItemProps = {
  className?: string;
  content: string | JSX.Element;
  key: string;
  selected?: boolean;
  href?: string;
  target?: string;
  icon?: JSX.Element;
  infoIcon?: JSX.Element;
  onClick?: any;
  [key: string]: any;
};

type MenuSectionProps = {
  buttons?: any[];
  className?: string;
  color?: string;
  items?: MenuItemProps[] | JSX.Element[];
  title?: string;
  showMoreEnabled?: boolean;
  visibleItemsLength?: number;
};

const MenuSection: React.FunctionComponent<MenuSectionProps> = (props) => {
  const { items, title, buttons, className, color, children, showMoreEnabled, visibleItemsLength = 4, ...rest } = props;
  const [showMore, setShowMore] = React.useState(showMoreEnabled);

  const renderItem = (item: MenuItemProps) => {
    const { className, href, onClick, target, icon, content, infoIcon, ...rest } = item;
    if (React.isValidElement(item)) {
      return item;
    }
    else if (href) {
      return (
        <a className={className} href={href} onClick={onClick} target={target} {...rest}>
          {icon}
          {content}
          {infoIcon}
        </a>
      );
    }
    else if (onClick) {
      return (
        <Button className={className} onClick={onClick} {...rest}>
          <span className={styles.buttonContentWrapper}>
            {icon}
            {content}
            {infoIcon}
          </span>
        </Button>
      );
    }
    else {
      return (
        <div className={className} {...rest}>
          {icon}
          {content}
          {infoIcon}
        </div>
      );
    }
  };

  const renderItems = (items) => {
    if (!items) {
      return null;
    }

    let itemsToRender = items;

    if (items.length > visibleItemsLength && showMore) {
      itemsToRender = items.slice(0, visibleItemsLength);
    }

    return (
      <ul className={styles.menuItemSection}>
        {itemsToRender.map((item, i) => (
          <li
            key={item.key || i}
            className={classnames({
              [styles.menuItem]: true,
              [styles.selected]: item.selected,
            })}
          >
            {renderItem(item)}
          </li>
        ))}
        {showMoreEnabled && items.length > visibleItemsLength &&
          <li key={'showMoreBtn'} className={classnames(styles.menuItem, styles.showMore)}>
            <Button className='btnShowMore' onClick={() => setShowMore(!showMore)}>
              <span className={styles.buttonContentWrapper}>
                {showMore
                  ? (
                    <>
                      {/* @ts-ignore */}
                      <ExpandIcon width="14px" height="14px" fill={color === 'light' ? '#253238' : '#fff'} />
                      See more
                    </>
                  )
                  : (
                    <>
                      {/* @ts-ignore */}
                      <CollapseIcon width="14px" height="14px" fill={color === 'light' ? '#253238' : '#fff'} />
                      See less
                    </>
                  )
                }
              </span>
            </Button>
          </li>
        }
      </ul>
    );
  };

  return (
    <div
      className={classnames({
        [styles.menuSection]: true,
        [styles.light]: color === 'light',
        [styles.noTitle]: !title,
        ['className']: !!className,
      })}
      {...rest}
    >
      {title && (
        <div className={styles.menuSectionHeader}>
          <div className={styles.menuTitle}>{title}</div>
          {buttons && (
            <div className={styles.menuSectionActionButtons}>
              {buttons.map((button, i) => (
                <React.Fragment key={i}>{button}</React.Fragment>
              ))}
            </div>
          )}
        </div>
      )}
      {children ? children : renderItems(items)}
    </div>
  );
};

export default MenuSection;
