import React, { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import styles from './styles.module.css';

interface Props {
  isLoading?: boolean;
  hasNoData?: boolean;
  children?: React.ReactNode;
  noDataText?: string;
  type?: 'spinner' | 'text';
  noOverlay?: boolean;
  labelClassName?: string;
  isError?: boolean;
  fullScreen?: boolean;
}

/**
 *
 * LoadingWrapper
 * @description Wrapper component to show loading & noData state based on conditions
 *
 */
function LoadingWrapper({
  isLoading = false,
  hasNoData = false,
  noDataText = 'No Data',
  children = '',
  type = 'text',
  noOverlay = false,
  labelClassName,
  isError = false,
  fullScreen = false,
}: Props) {

  return (
    !isError &&
    (isLoading ? (
      type === 'spinner' ? (
        <div
          className={classNames(styles.wrapper, {
            [styles.overlay]: !noOverlay,
            'my-lg': noOverlay,
            [styles.fullScreen]: fullScreen,
          })}>
          <CircularProgress color="primary" size={noOverlay ? 35 : 50} />
          <span role="alert" className="visuallyHidden screen-reader-only">Loading...</span>
        </div>
      ) : (
          <div className={labelClassName || 'labelText'} role="alert" aria-live="assertive">Loading...</div>
      )
    ) : (
      <>
        {hasNoData
          ? <p className={labelClassName || 'labelText'}>{noDataText}</p>
          : children
        }
      </>
    ))
  );
}

export default LoadingWrapper as React.FC<Props>;
