import ShowSolutionVideo from './component';
import { connect } from 'react-redux';
import { Role } from '../../../../utils/constants';

const mapStateToProps = (state: any) => {
  const stepIds = state.problemData.stepIds;
  const showButton = stepIds[stepIds.length - 1] === state.readerNavigation.currentStepId
    && !!state.problemData.solutionVideoId
    && (state.stepData.numAttempts > 0
      || state.userData.role !== Role.STUDENT);
  
  return {
    showButton
  };
};

export default connect(
  mapStateToProps
)(ShowSolutionVideo);
