import produce from 'immer';
import { ImplementationType } from '../utils/constants';

const initialState = {
  name: null,
  description: null,
  ageRange: null,
  numAttempts: 0,
  learnosityJson: {},
  stepIds: [] as number[],
  stepIdsWithCorrectResponse: [] as number[],
  formulaSheetUrl: null,
  graphJson: null,
  hideForNonSuperAdmin: false,
  solutionVideoId: null,
  showRuler: false,
  showProtractor: false,
};

const reducer: any = produce((draft, action: any) => {
  switch (action.type) {
  case 'RESOLVE_PROBLEM_DATA_FOR_STEP':
  case 'RESOLVE_PROBLEM_DATA': {
    Object.assign(draft, { ...action.body, responseData: undefined });
    break;
  }
  case 'RESOLVE_SAVE_PROBLEM_EDITS': {
    draft.learnosityJson.questions = [action.body];
    break;
  }
  case 'RESOLVE_GRADE_RESPONSE': {
    if (action.body.type === ImplementationType.STEP && action.body.isCorrect) {
      draft.stepIdsWithCorrectResponse.push(action.body.id);
    }
    else if (action.body.type === ImplementationType.PROBLEM && action.body.isValidAttempt) {
      draft.learnosityJson.responses = action.body.response;
      draft.learnosityJson.state = 'review'; // for "show original problem" modal
      draft.numAttempts++;
    }
    break;
  }
  case 'RESOLVE_GRADE_BENCHMARK_RESPONSE': {
    if (action.body.isValidAttempt) {
      draft.learnosityJson.responses = action.body.response;
    }
    break;
  }
  case 'RESOLVE_SHOW_STEPANSWER': {
    draft.stepIdsWithCorrectResponse.push(action.body.stepId);
    break;
  }
  case 'RESOLVE_SKIPPED_TO_STEPS': {
    draft.learnosityJson.responses = {};
    draft.learnosityJson.state = 'review'; // for "show original problem" modal
    draft.numAttempts++;
    break;
  }
  case 'RESOLVE_TOGGLE_HIDE_PROBLEM': {
    draft.hideForNonSuperAdmin = !draft.hideForNonSuperAdmin;
    break;
  }
  case 'RESOLVE_TOGGLE_PROBLEM_FEATURES': {
    draft[action.body] = !draft[action.body];
    break;
  }
  }
}, initialState);

export default reducer;
