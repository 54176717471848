import produce from 'immer';

const initialState = {
  classes: [],
  students: [],
};

const reducer: any = produce((draft, action: any) => {
  switch (action.type) {
  case 'RESOLVE_CLASSES_DATA': {
    draft.classes = action.body.classes;
    draft.students = action.body.students;
  }
  }
}, initialState);

export default reducer;
