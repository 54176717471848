import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Fonts } from '../../helpers/constants';
import { Tab, Tabs } from '@material-ui/core';

interface Tab {
  key: string;
  label: string;
}

interface IProps {
  tabs: Tab[];
  onChange: Function;
  selectedTab: string;
}

const useStyles = makeStyles({
  root: {
    color: '#a1a1a1',
    minHeight: 'initial',
    minWidth: 'initial',
    lineHeight: '1',
    opacity: '1',
  },
  tabButton: {
    fontFamily: Fonts.BOLD,
    padding: '6px 15px',
    textTransform: 'uppercase',
    letterSpacing: '1.7px',
    marginRight: '0',
    backgroundColor: '#e4f3ff',
    border: 'solid 2px #296AA9',
    borderRight: 0,
    color: '#296AA9',
    cursor: 'pointer',

    '&:focus': {
      outline: '2px solid #ffffff',
      outlineOffset: '-4px'
    },

    '&:first-child': {
      borderRadius: '30px 0 0 30px',
    },

    '&:last-child': {
      borderRadius: '0 30px 30px 0',
      borderRight: 'solid 2px #0077d1',
    }
  },
  selected: {
    backgroundColor: '#0077d1',
    color: '#fff',
  }
})


const TabSwitch = (props: IProps): JSX.Element => {
  const { tabs, selectedTab, onChange } = props;
  const styles = useStyles();
  const [value, setValue] = useState(0);

  return (
    <Tabs
      selectionFollowsFocus
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      value={value}
      TabIndicatorProps={{
        style: {
          backgroundColor: "transparent",
        },
      }}
    >
      {tabs.map((tab) => (
        <Tab
          disableRipple
          key={tab.key}
          onFocus={(e) => e.target.click()}
          onClick={() => onChange(tab.key)}
          className={`${styles.tabButton} ${styles.root} ${
            selectedTab === tab.key ? styles.selected : ""
          }`}
          label={tab.label}
        />
      ))}
    </Tabs>
  );
};

export default TabSwitch;
