import GoToProblemButton from './component';
import { connect } from 'react-redux';
import { goToProblem } from '../../../../actions/readerNavigation';

const mapStateToProps = (state: any) => {
  const { currentProblemId, currentStepId } = state.readerNavigation;
  const { problemIds, classId } = state.problemSetData;
  const currentIndex = problemIds.indexOf(currentProblemId);

  return {
    currentProblemId,
    previousProblemId: currentIndex === 0 ? null : problemIds[currentIndex - 1],
    nextProblemId: currentIndex === problemIds.length ? null : problemIds[currentIndex + 1],
    role: state.userData.role,
    classId,
    isOnStep: !!currentStepId,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    goToProblem: (problemId: number) => {
      dispatch(goToProblem(problemId));
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoToProblemButton);
