import React from 'react';
import Loading from '../common/loading/loading.component';
import { levelSetColors } from '../../utils/style';
import ChartHeader from 'sharedfrontend/src/components/dataViz/ChartHeader';
import ALChart, { ChartTypes } from 'sharedfrontend/src/components/dataViz/ALChart';
import { InfoText } from '../../utils/constants';

const StateTestForecastChart = (props: any): JSX.Element => {
  const {
    classId,
    data,
    fetchData,
    selectedGradeLevel,
    startDate,
    selectedSchoolIds,
    stateAbbreviation,
    endDate,
    benchmarkSource,
  } = props;

  React.useEffect(() => {
    fetchData({
      classId,
      gradeLevel: selectedGradeLevel,
      schoolIds: selectedSchoolIds,
      startDate,
      endDate,
      benchmarkSource
    });
  }, [
    classId,
    fetchData,
    selectedGradeLevel,
    selectedSchoolIds,
    startDate,
    endDate,
    benchmarkSource,
  ]);

  if (!data) {
    return <Loading text="State Forecast" />;
  }

  const {
    stateTestName,
    numForecastedStudents,
    levels,
  } = data;

  const zeroStateCopy = benchmarkSource === 'nwea'
    ? 'Test Forecasts are not available for schools using Quantile measures from NWEA.'
    : InfoText.noChartDataText;

  if (numForecastedStudents === 0) {
    return (
      <div>
        <ChartHeader title={`${stateAbbreviation} Test Forecast`} column={false} />
        <p>{zeroStateCopy}</p>
      </div>
    );
  }

  const labels = levels ? levels.map((level: any) => level.name) : [];

  const legend = {
    title: 'End of Year Forecast',
    variant: 'stacked',
    items: labels && labels.length
      ? labels.map((label: string, index: number) => ({
        color: levelSetColors[index],
        label
      }))
      : [],
  };

  const config = {
    data: levels && levels.map((level: any) => level.numStudentsInGroup),
    labels,
    colors: levelSetColors,
    tooltipOptionOverrides: {
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          const { index } = tooltipItem;
          const numStudents = data.datasets[0].data[index];
          const percentage = (numStudents * 100 / numForecastedStudents).toFixed(1);
          const label = `${percentage}% (${numStudents} of ${numForecastedStudents} students)`;
          return label;
        },
      },
    },
    pluginLabel: {
      render: () => { },
    },
  };

  return (
    <ALChart
      header
      title={`${stateAbbreviation} Test Forecast`}
      subtitle={stateTestName}
      variant={ChartTypes.Pie}
      legend={legend}
      config={config}
      column={false}
    />
  );
}

export default StateTestForecastChart;