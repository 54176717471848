import React, { useEffect, useRef } from "react";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { mergeDeep } from "../../helpers/objectHelpers";
import { Fonts } from "../../helpers/constants";

interface IPropsToolTip extends Omit<TooltipProps, "title"> {
  title: string | React.ReactNode;
  children: any;
  tooltipPlacement?: "left" | "right" | "bottom" | "top";
  tooltipColor?: "black" | "white" | "blue";
  isTooltipOpen?: boolean;
  arrow?: boolean;
}

const tooltipStyles: any = {
  tooltip: {
    borderRadius: 0,
    fontFamily: Fonts.MEDIUM,
    fontSize: 12,
    whiteSpace: "normal",
    background: "black",
    color: "white",
    padding: "10px 15px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  arrow: {
    color: "black",
  },
};

const useTooltipStyles: any = makeStyles(tooltipStyles);

const useLightTooltipStyles: any = makeStyles(
  mergeDeep({}, tooltipStyles, {
    tooltip: {
      color: "black",
      background: "white",
    },
    arrow: {
      color: "#fff",
    },
  })
);

const useBlueTooltipStyles: any = makeStyles(
  mergeDeep({}, tooltipStyles, {
    tooltip: {
      color: "#0077d1",
      backgroundColor: "#fff",
      border: "2px #0077d1 solid",
      borderRadius: 4,
      maxWidth: 700,
    },
    arrow: {
      color: "#fff",
      "&::before": {
        border: "2px #0077d1 solid",
      },
    },
  })
);

const getTooltipStyles = (color) => {
  switch (color) {
    case "white": {
      return useLightTooltipStyles();
    }
    case "blue": {
      return useBlueTooltipStyles();
    }
    case "black":
    default:
      return useTooltipStyles();
  }
};

export default function ALTooltip({
  title,
  children,
  tooltipPlacement = "bottom",
  tooltipColor = "black",
  isTooltipOpen = true,
  arrow = true,
  ...rest
}: IPropsToolTip) {
  const styles = getTooltipStyles(tooltipColor);
  const [open, setOpen] = React.useState(false);
  const [hovered, setHovered] = React.useState("");
  const tooltipRef = useRef(null);

  function handleTooltipClose() {
    setOpen(false);
  }

  function handleTooltipOpen() {
    setOpen(true);
  }

  function handleMouseHover(event: any) {
    setHovered(event.target);
  }

  function handleKeyDown(event: any) {
    if (event.key === 'Escape' || tooltipRef.current === hovered) {
      if (open) {
        setOpen(false);
      }
    }
  }

  useEffect(() => {
    document.body.setAttribute('oneOrMoreTooltip', open + '');
    document.addEventListener('mouseover', handleMouseHover);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mouseover', handleMouseHover);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open]);
  return (
    <Tooltip
      ref={tooltipRef}
      classes={styles}
      // @ts-ignore
      title={title}
      placement={tooltipPlacement}
      open={open && isTooltipOpen}
      onClose={handleTooltipClose}
      onOpen={handleTooltipOpen}
      arrow={arrow}
      {...rest}
    >
      {children}
    </Tooltip>
  );
}
