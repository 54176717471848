import SolutionVideoModal from './component';
import { connect } from 'react-redux';
import { postDataPlain } from '../../../../actions/api';

const mapStateToProps = (state: any) => {
  return {
    solutionVideoId: state.problemData.solutionVideoId,
    problemSetId: state.problemSetData.problemSetId,
    problemId: state.readerNavigation.currentProblemId,
  };
};

const mapDispatchToProps = () => {
  return {
    track: (params: any) => {
      postDataPlain({
        type: 'TRACK_SOLUTION_VIDEO',
        params
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SolutionVideoModal);