import request from '../../../helpers/request';
import { getEndpointFullUrl } from '../../../helpers/urlHelper';

export const getLearnEntityOrderHistory = async ({ learnEntityId }): Promise<any> => {
  const data = await request({
    url: getEndpointFullUrl('payment/getlearnentityorderhistory', { learnEntityId })
  });

  return data;
}
