
import React, { useState } from 'react'; 
import StudentDataScoreCards from '../scoreCard/scoreCard.component';
import { getDataPlain, postDataPlain } from '../../../actions/api';
import { getMasterySegment, convertFloatsToPercentages, percent } from '../../../utils/data';
import { plural, toMonthDayAndYear, formatTimeSpent } from '../../../utils/common';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { colors } from '../../../utils/style';
import styles from './styles.module.css';
import '../../../chartjsOverrides.css';
import Button from '../../common/button/component';
import StudentProblemResponse from '../../common/modals/studentProblemResponse/container';
import { openModal, closeModal } from '../../../actions/eventEmitter';
import { FaLevelUpAlt } from 'react-icons/fa';
import { MathAssignmentLabel } from 'sharedfrontend/src/components/mathAssignmentLabel';
import { Tooltip, Modal } from 'sharedfrontend';
import { ReactTable, IRowData, ICellData } from 'sharedfrontend/src/components/tables/ReactTable';
import LineChart from 'sharedfrontend/src/components/dataViz/LineChart';
import tableStyles from '../../../styles/table.module.css';
import ToggleButton from '../../reader/buttons/toggleButton/container';
import { Role } from '../../../utils/constants';

const SkillCell: React.FunctionComponent<any> = (props: any) => {
  const {
    row,
    studentUserId,
    setShouldRefreshData,
    isDemoUser,
    classId,
    isStudent,
  } = props;

  const openResetAssignmentModal = () => {
    openModal(
      <Modal
        title="Reset assignment"
        maxWidth="sm"
        okText="Reset"
        okCallback={async () => {
          await postDataPlain({
            type: 'RESET_PROBLEMSET',
            params: {
              problemSetId: row.problemSetId,
              userId: studentUserId,
              classId,
            }
          });

          setShouldRefreshData(true);
          closeModal();
        }
        }
        cancelText="Cancel"
        cancelCallback={closeModal}
      >
        <React.Fragment>
          <p>
            Resetting the assignment will allow the student to restart the assignment from problem 1.
            Data from previous attempts can be found on the Student Data tab from your account.
          </p>
          <p>
            The student will need to complete at least five problems to achieve mastery.
          </p>
          <p>Do you want to reset?</p>
        </React.Fragment>
      </Modal>
    );
  };

  return (
    <Tooltip title={row.standardCode || 'No standards'} tooltipColor="blue">
      <div className={styles.skillContainer}>
        <div className={styles.standardName}>
          {row.name}
          {row.problemData.length > 0 && !isDemoUser && !isStudent &&
            <div>
              <Button
                size="small"
                additionalStyles={`${styles.btnReset} ${styles.hideForPrint}`}
                onClick={openResetAssignmentModal}
              >
                Reset
              </Button>
            </div>
          }
          {!!row.isIntervention && <MathAssignmentLabel label="Prerequisite" />}
          {!!row.isTeacherAssigned && <MathAssignmentLabel label="Teacher-Assigned" />}
          {!!row.isAutoAssigned && <MathAssignmentLabel label="Auto-Assigned" />}
          {!!row.createdFromProblemSetName &&
            <div className={styles.interventionSource}>
              <FaLevelUpAlt style={{ transform: 'rotate(90deg)', marginRight: 4 }} />
              {row.createdFromProblemSetName}
            </div>
          }
        </div>
        {row.problemData.length > 0 &&
          <div className={styles.yAxisContainer}>
            <div className={styles.yAxisLabel}>Mastery</div>
            <div className={styles.yAxisLabel}>60%</div>
            <div className={styles.yAxisLabel}>30%</div>
          </div>
        }
      </div>
    </Tooltip>
  );
};

const MasteryCell: React.FunctionComponent<any> = (props: any) => {
  const { row, classId, studentUserId, exitReader, isStudent } = props;
  const allProblemData = [row.problemData, ...(row.resetedProblemData || [])];
  const maxLength = Math.max.apply(Math, allProblemData.map((x: unknown[]) => x.length));

  if (maxLength > 0) {
    const containerMaxWidth = getLineGraphContainerMaxWidth(maxLength);
    const lineChartData = getLineChartConfig({
      problemData: allProblemData,
      currentMastery: row.currentMastery,
      classId,
      studentUserId,
      maxLength,
      exitReader,
      isStudent,
    });

    return (
      <div style={{ maxWidth: containerMaxWidth }}>
        <LineChart config={lineChartData} />
      </div>
    );
  }

  return <div className={styles.noProblemsCompleted}>No problems completed!</div>;
};

const MasteryData = (props: any): JSX.Element => {
  const { classId, studentUserId, isDemoUser, isStudent, exitReader } = props;
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState<any | null>(null);
  const [shouldRefreshData, setShouldRefreshData] = React.useState(false);
  const [shouldFetchAllData, setShouldFetchAllData] = useState(false);

  React.useEffect(() => {
    fetchData();
  }, [studentUserId, shouldFetchAllData]); // eslint-disable-line

  React.useEffect(() => {
    if (shouldRefreshData) {
      fetchData();
    }
  }, [shouldRefreshData]); // eslint-disable-line

  const fetchData = async () => {
    const result = await getDataPlain({
      type: 'STUDENT_DATA_MASTERY',
      params: { classId, studentUserId, shouldFetchAllData }
    });

    setData(result);
    setIsLoading(false);
    setShouldRefreshData(false);
  };
  
  const handleOnClickToggle = () => {
    setShouldFetchAllData(!shouldFetchAllData);
    setIsLoading(true);
  };

  if (isLoading) {
    return <div className="ctaInfo">Loading...</div>;
  }

  const tableData: IRowData[] = data.problemSetData.map((data: any) => {
    const rowContent: { [key: string]: ICellData } = {
      name: {
        cellContent: (
          <SkillCell
            row={data}
            studentUserId={studentUserId}
            classId={classId}
            isDemoUser={isDemoUser}
            isStudent={isStudent}
            setShouldRefreshData={setShouldRefreshData}
          />
        ),
        sortValue: data.name,
        className: styles.tableCell
      },
      problemData: {
        cellContent: (
          <MasteryCell
            row={data}
            classId={classId}
            studentUserId={studentUserId}
            exitReader={exitReader}
            isStudent={isStudent}
          />
        ),
        sortValue: data.currentMastery,
        className: styles.tableCell
      },
    };

    return { rowContent };
  });

  return(
    <>
      <div className="flex">
        <div> This school year </div>
        <ToggleButton
          handleToggleSwitch={handleOnClickToggle}
          tooltipText={'Toggle between all data and current school year'}
          validRoles={[Role.SUPERADMIN, Role.TEACHER, Role.STUDENT]}
          isToggleOn={shouldFetchAllData}
        />
        <div> All data </div>
      </div>
      {data.problemSetData.length > 0
        ? (
          <div className={styles.dataContainer}>
          
            <StudentDataScoreCards {...data} />
            <ReactTable
              columns={[
                {
                  Header: 'Skills',
                  accessor: 'name',
                  minWidth: 140,
                },
                {
                  Header: 'Mastery',
                  accessor: 'problemData',
                  minWidth: 400,
                },
              ]}
              data={tableData}
              loading={false}
              gridLines
              type="secondary"
              ignoreHeightAdjust
              fixedHeader={false}
              className={tableStyles.reactTable}
            />
          </div>
        )
        : <div className="ctaInfo">No assignments!</div>}
    </>)
};

export default MasteryData;

const getLineChartConfig = (props: {
  classId: number;
  problemData: any[][];
  currentMastery: number;
  studentUserId: number;
  maxLength: number;
  exitReader: any;
  isStudent: boolean;
}) => {
  const { problemData, currentMastery, maxLength, classId, studentUserId, exitReader, isStudent } = props;

  return {
    labels: new Array(maxLength),
    data: getLineChartData(currentMastery, problemData),
    plugins: [ChartDataLabels],
    options: {
      defaultFontFamily: 'AvenirNext-Bold',
      defaultFontSize: 14,
      yAxisLabel: '',
      xAxisLabel: '',
      layout: { padding: { top: 10, bottom: 10, right: 58, left: 0 } },
      scales: {
        yAxes: [{
          // https://www.chartjs.org/docs/latest/axes/styling.html#grid-line-configuration
          gridLines: {
            display: false,
            drawBorder: false,
            zeroLineColor: 'transparent',
            borderDash: [1, 2],
            borderDashOffset: 0,
          },
          ticks: { display: false, min: 0, max: 100, stepSize: 30 },
        }],
        xAxes: [{
          gridLines: { display: false, drawTicks: false, drawBorder: false },
          ticks: { display: false },
        }]
      },
      maintainAspectRatio: false,
      animation: false,
      plugins: {
        datalabels: {
          color: colors.black,
          font: {
            weight: 'bold',
            size: 14,
          },
          padding: { left: 35 },
          align: 'right',
          formatter: (value: any, ctx: any) => {
            const { datasetIndex, dataIndex } = ctx || {};
            const isReset = problemData[datasetIndex]
              && problemData[datasetIndex][0]
              && problemData[datasetIndex][0].isReset;

            return !isReset && datasetIndex >= 0 && dataIndex === problemData[datasetIndex].length - 1
              ? `${value}%`
              : null;
          }
        }
      },
      onHover: function (e: any, i: any) {
        /*
          When problem sets are reset and a problem is answered a second time, the line chart will
          display two dots on the same x location (one above the other). On hover or on click, the
          second param includes an array of both problems. Instead of taking the first we want to
          find the one the user is hovering/clicking on by using getElementAtEvent.
        */
        const pointHovered = (this as any).getElementAtEvent(e)[0];
        const { _index, _datasetIndex } = pointHovered || {};
        const problem = _datasetIndex >= 0 && _index >= 0
          ? problemData[_datasetIndex][_index]
          : null;
        const isSteps = problem && problem.type === 'steps';
        const hideResetAnswers = problem && problem.isReset && isStudent;
        e.target.style.cursor = isSteps || hideResetAnswers
          ? 'default'
          : 'pointer';
      },
      onClick: function (c: any, i: any[]) {
        const pointClicked: any = (this as any).getElementAtEvent(c)[0];
        const { _index, _datasetIndex } = pointClicked || {};
        const problem = _datasetIndex >= 0 && _index >= 0
          ? problemData[_datasetIndex][_index]
          : null;

        if (problem && problem.isReset && isStudent) {
          return;
        }

        if (problem && problem.type !== 'steps') {
          const { problemId, resetId } = problem;

          openModal(
            <Modal
              title=""
              okText=""
              maxWidth="lg"
              cancelText="Close"
              cancelCallback={() => {
                exitReader();
                closeModal();
              }}
            >
              <StudentProblemResponse
                classId={classId}
                studentUserId={studentUserId}
                problemId={problemId}
                resetId={resetId}
              />
            </Modal>
          );
        }
      },
    },
    tooltipOptionOverrides: {
      enabled: false,
      custom: function (tooltip: any) {
        customTooltips(tooltip, (this as any)._chartInstance, problemData);
      }
    },
  }
};

const getLabel = (x: any, idx: number) => {
  if (x.isCompleted) {
    return `Problem ${idx + 1}`;
  }
  else if (x.type === 'steps') {
    return `${plural(x.numSteps, '%count step response')}`;
  }
  else if (!x.isCompleted) {
    return `Problem ${idx + 1} (in progress)`;
  }
  return null;
};

const getLineChartData = (currentMastery: number, problemData: any[]) => {
  return problemData.map((data: any, index: number) => {
    const masteryAtCompletions = data.map((x: any) => x.masteryAtCompletion);
    const masteryAtCompletionsPercentages = convertFloatsToPercentages(masteryAtCompletions);
    const currentMasteryPercentage: any = percent(currentMastery, 1);
    const dotColor = data[0] && data[0].isReset
      ? colors.gray1
      : (isNaN(currentMasteryPercentage) || masteryAtCompletions.length < 3)
        ? colors.black
        : getMasterySegment(currentMasteryPercentage, masteryAtCompletions.length).backgroundColor;

    return {
      data: masteryAtCompletionsPercentages,
      fill: false,
      type: 'line',
      borderColor: dotColor,
      label: `line ${index}`,
      pointBorderColor: (ctx: any) => {
        const { isCompleted } = data[ctx.dataIndex] || {};
        return !isCompleted ? colors.gray2 : dotColor;
      },
      pointBackgroundColor: dotColor,
    };
  });
};

const getLineGraphContainerMaxWidth = (numProblemsCompleted: number) => {
  const minProblemsFor100PercentWidth = 5;
  const maxCompletedProblemsLineSegmentsForAllRows = minProblemsFor100PercentWidth - 1;

  if (numProblemsCompleted === 0) {
    return '100%';
  }
  else if (numProblemsCompleted === 1) {
    return '220px';
  }
  else if (numProblemsCompleted >= minProblemsFor100PercentWidth) {
    return '100%';
  }
  else {
    const problemsCompletedLineSegments = numProblemsCompleted - 1;
    return Math.round(100 * problemsCompletedLineSegments / maxCompletedProblemsLineSegmentsForAllRows) + '%';
  }
};

const customTooltips = function (tooltip: any, chart: any, problemData: any[]) {
  let tooltipEl = document.getElementById('chartjs-tooltip');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    document.body.appendChild(tooltipEl);
  }

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0%';
    return;
  }

  if (tooltip.dataPoints.length > 0) {
    const { index, datasetIndex } = tooltip.dataPoints[0];
    const problem = datasetIndex >= 0 && index >= 0
      ? problemData[datasetIndex][index]
      : null;
    
    if (problem) {
      const masteryPercentage = percent(problem.masteryAtCompletion, 1);
      const date = toMonthDayAndYear(problem.createDate);
      const title = getLabel(problem, index);
      const time = formatTimeSpent(
        problem.timeSpentInSeconds
        + problem.timeSpentInSecondsInSteps
        + problem.timeSpentInSecondsInSolutionVideos
      );

      // TODO: Show dates for reset-ed problems, hiding now for incorrect dates in historical data 
      tooltipEl.innerHTML = `
        <div style="color:${tooltip.bodyFontColor};" class="title">${title}</div>
        <div style="color:${tooltip.bodyFontColor};" class="mastery">${masteryPercentage}% Mastery</div>
        ${time ? '<div class="time">' + time + '</div>' : ''}
        ${!problem.isReset ? `<div class="date">${date}</div>` : ''}
        ${problem.isReset ? '<div class="reset">Reset</div>' : ''}
      `;

      const position = chart.canvas.getBoundingClientRect();

      tooltipEl.classList.remove('top', 'bottom', 'no-transform', 'left', 'center', 'right');
      tooltipEl.classList.add(tooltip.yAlign || 'no-transform');
      tooltipEl.classList.add(tooltip.xAlign);

      tooltipEl.style.opacity = '100%';

      tooltipEl.style.left = position.x + window.pageXOffset + tooltip.caretX + 'px';
      tooltipEl.style.top = position.y + window.pageYOffset + tooltip.caretY + 'px';

      tooltipEl.style.backgroundColor = tooltip.backgroundColor;
      tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
      tooltipEl.style.fontSize = tooltip.bodyFontSize + 'px';
      tooltipEl.style.borderRadius = tooltip.cornerRadius + 'px';
      tooltipEl.style.borderColor = tooltip.borderColor;
      tooltipEl.style.borderWidth = tooltip.borderWidth;
    }
  }
};