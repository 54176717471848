import Assign from './component';
import { connect } from 'react-redux';
import { assignedProblemSet, unAssignedProblemSet } from '../../../../actions/modal';
import { getDataPlain, postDataPlain } from '../../../../actions/api';
import { ClassSettings } from '../../../../utils/constants'

const mapStateToProps = (state: any) => {
  return {
    ltiAccountSource: state.userData.ltiAccountSource,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    assignedProblemSet: (problemSetId: number) => {
      dispatch(assignedProblemSet(problemSetId));
    },
    unAssignedProblemSet: (problemSetId: number) => {
      dispatch(unAssignedProblemSet(problemSetId));
    },
    toggleAutoAssignForClasses: (classIds: number[], isEnabled: boolean) => {
      postDataPlain({
        type: 'CLASS_SETTING',
        params: {
          classIds,
          type: ClassSettings.AUTO_ASSIGN_ASSIGNMENTS,
          value: isEnabled ? 'true' : 'false',
        },
      });
    },
    unassignAssignments: (problemSetIds: number[], classId: number) => {
      return postDataPlain({
        type: 'UNASSIGN',
        params: {
          assignmentIds: problemSetIds,
          classId: classId,
        },
        returnErrorResponse: true,
      });
    },
    assignProblemSets: ({
      problemSets,
      selectedClassIds,
      selectedStudents,
      checkLevelSetAssignedStudents,
      isAssigningLevelSet,
      assignedFrom,
    }: {
      problemSets: number[];
      selectedClassIds: number[];
      selectedStudents: number[];
      checkLevelSetAssignedStudents: boolean;
      isAssigningLevelSet: boolean;
      assignedFrom?: string;
    }) => {
      return postDataPlain({
        type: 'ASSIGN',
        params: {
          problemSets,
          selectedClassIds,
          selectedStudents,
          checkLevelSetAssignedStudents,
          isAssigningLevelSet,
          assignedFrom,
        },
        returnErrorResponse: true,
      });
    },
    getClassesData: (problemSetIds: number[]) => {
      return getDataPlain({
        type: 'CLASSES_DATA',
        params: { problemSetIds },
      });
    },
    getAssignmentsDates: (problemSetIds: number[], classIds: number[]) => {
      return getDataPlain({
        type: 'GET_ASSIGNMENTS_DATES',
        params: { problemSetIds, classIds },
      });
    },
    getClassSettings: (classId: number) => {
      return getDataPlain({
        type: 'CLASS_SETTING',
        params: {
          classId,
          type: ClassSettings.AUTO_ASSIGN_ASSIGNMENTS,
        },
      });
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Assign);