import StepHeader from './component';
import { connect } from 'react-redux';
import { goToProblem, goToStep } from '../../../../actions/readerNavigation';

const mapStateToProps = (state: any) => {
  return {
    currentStepId: state.readerNavigation.currentStepId,
    stepIds: state.problemData.stepIds,
    stepIdsWithCorrectResponse: state.problemData.stepIdsWithCorrectResponse,
    role: state.userData.role,
    isIntervention: state.problemSetData.isIntervention,
    stepData: state.stepData,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    goToProblem: (problemId: number) => {
      dispatch(goToProblem(problemId));
    },
    goToStep: (stepId: number) => {
      dispatch(goToStep(stepId));
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StepHeader);
