import React, { useRef, useEffect, useState } from 'react';
import { PopoverButton } from '../../../buttonPopover';
import config from '../../../../config';
import {
  weThePeopleLevel2PaidLessonLeIds,
  weThePeopleLevel3PaidLessonLeIds,
  LearnEntityPurchaseSource,
  helpArticleLink,
  weThePeoplePublisher,
  publishersWithMinimumLicensePurchaseRequirement,
  getPublisherName,
} from '../../../../helpers/constants';
import { getDisabledFeaturesListForLearnEntity } from '../../../../helpers/textHelpers';
import LicensePurchaseInformation from './LicensePurchaseInformation';
import RentalPriceView from './RentalPriceView';
import TextPurchaseOrRequestForm from './TextPurchaseOrRequestForm';
import { getRentalTermAndPrice } from './helper';
import styles from './styles.module.scss';
import { isValidEmail } from '../../../../helpers/urlHelper';
import ExternalLink from '../../../ExternalLink';
import { Modal } from '../../../modal';
import {
  getPaymentData,
  getStudentCount,
  purchaseText,
  requestRental,
} from '../api';
import { showAlert } from '../../../alert';
import { PaymentModalData, UserData, ClassData } from '../../types';
import { Divider } from '../../../divider';
import { plural } from '../../../../helpers/textHelpers';
import { TableBody, TableCell, TableContainer, TableRow, TextField, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    '& .MuiTableRow-root': {
      '& .MuiTableCell-root': {
        border: '0px',
        borderBottom: '0px',
        fontSize: '16px',
        height: '2em',
        padding: '0px',
      },
      '&:last-child': {
        '& .MuiTableCell-root': {
          fontWeight: 'bold'
        }
      }
    }
  },
  licenseInput: {
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #d31111',
    },
    '& .MuiInputBase-input': {
      width: '74px',
      padding: '8px 10px',
      borderRadius: '4px',
      background: 'var(--bluegrey_50)',
      fontFamily: 'WorkSans-SemiBold',
      height: '30px',
      textAlign: 'center',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'transparent',
      borderRadius: '4px',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline': {
        border: '1px solid',
        borderColor: 'transparent !important',
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: '#d31111',
      },
      '&:not(.Mui-error):hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        }
      },
    }
  }
});

interface Props {
  data: PaymentModalData;
  cancelCallback: Function;
  showBehindPaywallModal: Function;
  showHideRentalRequestConfirmationModal: Function;
  showHidePaymentConfirmationModal: Function;
  userData: UserData;
  teacherClasses: ClassData[];
  trackGAEvent: Function;
  setShowPaidTextNotAvailableModal: Function;
}

interface LicenseAndCreditDataInterface {
  license: string;
  bookCredit: string;
  remainingCredit: string;
  teacherOrStudentLicenses: string;
  totalToCharge: string;
  creditsUsedInCents: number;
  hasActiveLicenseAssigned: boolean;
}

interface HeadCountDataInterface {
  numTotalLicensesAvailable?: number;
  classStudentLicenseCount: {
    className: string;
    totalCount: number;
    numOfStudentsNeedingLicense: number;
    numOfStudentsUsingLicenses: number;
  }[];
  totalStudentLicenseCount: {
    totalCount: number;
    numOfStudentsNeedingLicense: number;
    numOfStudentsUsingLicenses: number;
  }
  isNoStudentYet?: boolean;
  error?: string;
  fetched?: boolean;
}

const licenseAndCreditDefaultValue = {
  license: '',
  bookCredit: '',
  remainingCredit: '',
  teacherOrStudentLicenses: '',
  totalToCharge: '',
  creditsUsedInCents: 0,
  hasActiveLicenseAssigned: false,
};

function PaymentModal({
  data,
  cancelCallback,
  showBehindPaywallModal,
  showHideRentalRequestConfirmationModal,
  showHidePaymentConfirmationModal,
  userData,
  teacherClasses,
  trackGAEvent,
  setShowPaidTextNotAvailableModal,
}: Props): JSX.Element {
  let minQuantity =
    publishersWithMinimumLicensePurchaseRequirement.includes(data.textM.publisher)
      ? 5
      : 1;

  if (data.source === LearnEntityPurchaseSource.TEACHER_READER) {
    minQuantity = 1;
  }

  const [paymentModalData, setPaymentModalData] = useState({
    fetched: false,
    isLicenseAvailableOnUnlimited: false,
    bookCreditData: {
      available: 0,
      hasActiveLicenseAssigned: null,
    },
  });
  const [priceDataState, setPriceDataState] = useState<{
    show: boolean;
    data: LicenseAndCreditDataInterface;
  }>({
    show: false,
    data: licenseAndCreditDefaultValue,
  });
  const [showRequestTypeForm, setShowRequestTypeForm] = useState(false);
  const isFirstTimePurchase = getIsFirstTimePurchase();
  const [requestType, setRequestType] = useState('purchase');
  const [adminName, setAdminName] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [paymentErrors, setPaymentErrors] = useState<any>({});
  const [flashOnFields, setFlashOnFields] = useState<string[]>([]);
  const [inputQuantity, setInputQuantity] = useState<number | undefined>(
    data.textM.rentalPrices && isFirstTimePurchase ? minQuantity - 1 : 0
  );
  const [inputQuantityErrorState, setInputQuantityErrorState] = useState('');
  const [paymentFormDisabled, setPaymentFormDisabled] = useState(false);
  const [headCountData, setHeadCountData] = useState<HeadCountDataInterface>({
    classStudentLicenseCount: [],
    totalStudentLicenseCount: {
      totalCount: 0,
      numOfStudentsNeedingLicense: 0,
      numOfStudentsUsingLicenses: 0,
    },
    fetched: false,
  });
  const [allowCoTeacherToUseLicense, setAllowCoTeacherToUseLicense] = useState(false);
  const [isTextAccessibleInCountry, setIsTextAccessibleInCountry] = useState(true);
  const [popoverState, setPopoverState] = useState(true);
  const disabledFeatures = getDisabledFeaturesListForLearnEntity(data.textM);
  const modalEl = useRef(null);
  let totalStudents = 0;
  let totalLicensesNeeded = 0;
  let totalLicensesUsed = 0;
  const customStyles = useStyles();

  useEffect(() => {
    if (data.textM.publisher === weThePeoplePublisher) {
      if (userData.isOnPrimePlan) {
        setShowPaidTextNotAvailableModal({
          show: true,
          title: data.textM.title,
        });
        cancelCallback();
      }
      else {
        showBehindPaywallModal();
        cancelCallback();
      }
    }
    else {
      initializePaymentForm();
    }

    function initializePaymentForm(): void {
      if ((window as any).Stripe) {
        (window as any).Stripe.setPublishableKey(config.stripeKey);

        if (data.textM.learnEntityId) {
          getPaymentData({
            isUserUnlimitedAndTextPaid: userData.isOnPrimeUnlimited,
            learnEntityId: data.textM.learnEntityId,
            callback: (
              bookCreditData,
              isTextAccessibleInCountry,
              isLicenseAvailableOnUnlimited
            ) => {
              setPaymentModalData({
                bookCreditData,
                isLicenseAvailableOnUnlimited,
                fetched: true,
              });
              setIsTextAccessibleInCountry(isTextAccessibleInCountry);
              updateTotal(inputQuantity, bookCreditData);
            },
          });
        }
        trackGAEvent('teacher', 'purchase:start');
      }
    }
  }, []);

  function getModalTitle(): string {
    if (data.title) {
      return data.title;
    }

    if (weThePeopleLevel2PaidLessonLeIds.includes(data.textM.learnEntityId)) {
      return 'We the People, Level 2';
    }
    else if (
      weThePeopleLevel3PaidLessonLeIds.includes(data.textM.learnEntityId)
    ) {
      return 'We the People, Level 3';
    }
    else {
      return data.textM.title;
    }
  }

  function updateTotal(
    qty = inputQuantity,
    bookCreditData = paymentModalData.bookCreditData
  ): void {
    let quantityVal =
      data.source === LearnEntityPurchaseSource.TEACHER_READER ? 1 : qty || 0;
    const licenseAndCreditData: LicenseAndCreditDataInterface = licenseAndCreditDefaultValue;
    const rentalData = getRentalTermAndPrice(data.textM.rentalPrices);
    const priceInCents = rentalData.price;
    const numStudentLicenses = quantityVal;
    const isFirstTimePurchase = getIsFirstTimePurchase();

    if (isFirstTimePurchase) {
      quantityVal++; // +1 for teacher themselves

      if (quantityVal < minQuantity) {
        showMinQuantityMessage();
      }

      licenseAndCreditData.license = (priceInCents / 100).toFixed(2);
    }

    let totalInCents = priceInCents * quantityVal;

    const availableBookCreditsInCents = bookCreditData.available || null;
    if (availableBookCreditsInCents && availableBookCreditsInCents > 0) {
      const creditsUsedInCents =
        availableBookCreditsInCents > totalInCents
          ? totalInCents
          : availableBookCreditsInCents;

      licenseAndCreditData.bookCredit = (creditsUsedInCents / 100).toFixed(2);
      licenseAndCreditData.remainingCredit = (
        (availableBookCreditsInCents - creditsUsedInCents) /
        100
      ).toFixed(2);
      licenseAndCreditData.creditsUsedInCents = creditsUsedInCents;

      totalInCents -= creditsUsedInCents;
    }
    setShowRequestTypeForm(totalInCents > 0);

    licenseAndCreditData.teacherOrStudentLicenses = (
      (priceInCents * numStudentLicenses) /
      100
    ).toFixed(2);
    licenseAndCreditData.totalToCharge = (totalInCents / 100).toFixed(2);

    licenseAndCreditData.hasActiveLicenseAssigned =
      bookCreditData.hasActiveLicenseAssigned || false;
    setPaymentFormDisabled(totalInCents === 0);
    setPriceDataState({
      show: true,
      data: licenseAndCreditData,
    });
  }

  function showMinQuantityMessage(): void {
    if (publishersWithMinimumLicensePurchaseRequirement.includes(data.textM.publisher)) {
      showAlert({
        message: `This publisher requires a minimum of ${minQuantity} licenses
        to be purchased under their K-12 Pay Per use model.`,
        type: 'error',
      });
    }
    else {
      showAlert({
        message: `A minimum of ${minQuantity} licenses must be purchased!`,
        type: 'error',
      });
    }
  }

  function handleSubmit(modal): void {
    if (
      userData.isSchoolUsingContentCreditsOrUnlimited ||
      requestType === 'purchase'
    ) {
      submitPayment(modal);
    }
    else {
      submitRentalRequest(modal);
    }
  }

  function onRequestTypeChange(e): void {
    if (e.target.value === 'purchase') {
      (modalEl.current as any).enableOkButton('Pay Now');
    }
    else {
      (modalEl.current as any).enableOkButton('Request Invoice');
    }

    setRequestType(e.target.value);
    updateTotal();
  }

  function onAdminNameChange(e): void {
    setAdminName(e.target.value);
  }
  function onAdminEmailChange(e): void {
    setAdminEmail(e.target.value);
  }

  function getQuantity(): number {
    const quantity =
      data.source === LearnEntityPurchaseSource.TEACHER_READER
        ? 1
        : inputQuantity || 0;

    return getIsFirstTimePurchase() ? quantity + 1 : quantity;
  }

  function submitPayment(modal): void {
    const rentalData = getRentalTermAndPrice(data.textM.rentalPrices);
    const term = rentalData?.term;
    const quantity = getQuantity();

    function submit(stripeResponse?): void {
      const reqData: any = {
        token: stripeResponse ? stripeResponse.id : '',
        cardtype: stripeResponse ? stripeResponse.card.type : '',
        lastfour: stripeResponse ? stripeResponse.card.last4 : '',
        term,
        learnentityid: data.textM.learnEntityId,
        layerid: data.textM.layerId,
        teacheronly: data.source === LearnEntityPurchaseSource.TEACHER_READER,
        creditsUsed: priceDataState.data.creditsUsedInCents > 0,
        isAllowedCoTeacherToUseLicense: allowCoTeacherToUseLicense,
        quantity: (paymentModalData.isLicenseAvailableOnUnlimited || userData.isOnPrimeUnlimited) ? undefined : quantity,
      };

      purchaseText({
        data: reqData,
        callback: (resp) => {
          if (resp) {
            modal.hideModalWithTransition();
            trackGAEvent('teacher', 'purchase:complete');
            let callback;

            if (data.source === LearnEntityPurchaseSource.CATALOG_TILE) {
              callback = function () {
                showHidePaymentConfirmationModal(false);

                if (data.afterPurchaseCallback) {
                  data.afterPurchaseCallback(resp.layerId);
                }
              };
            }
            else if (data.source === LearnEntityPurchaseSource.PREVIEW_READER) {
              callback = function () {
                showHidePaymentConfirmationModal(false);

                if (data.afterPurchaseCallback) {
                  data.afterPurchaseCallback(resp.layerId);
                }
                else {
                  location.href = location.pathname.replace(
                    'preview/' + data.textM.layerId,
                    resp.layerId
                  );
                }
              };
            }
            else if (data.source === LearnEntityPurchaseSource.TEACHER_READER) {
              callback = function () {
                showHidePaymentConfirmationModal(false);
                window.location.reload();
              };
            }
            else if (
              data.source &&
              [
                LearnEntityPurchaseSource.NEED_MORE_WHILE_ASSIGNING,
                LearnEntityPurchaseSource.WORKSPACE_TILE,
                LearnEntityPurchaseSource.MANAGE_LICENSES,
              ].includes(data.source)
            ) {
              callback = function () {
                showHidePaymentConfirmationModal(false);

                if (data.afterPurchaseCallback) {
                  data.afterPurchaseCallback(quantity);
                }
              };
            }

            if (callback) {
              if (paymentModalData.isLicenseAvailableOnUnlimited) {
                callback();
              }
              else {
                showHidePaymentConfirmationModal(true, callback);
              }
            }
          }
          else {
            modal.enableOkButton(getOkText());
            showAlert({ type: 'error', message: resp, autoClose: false });
          }
        },
      });
    }

    if (userData.isOnPrimeUnlimited || validatePaymentForm(quantity)) {
      modal.disableOkButton(getOkText());

      if (userData.isOnPrimeUnlimited || parseFloat(priceDataState.data.totalToCharge) === 0) {
        submit();
      }
      else {
        const form = document.getElementById('payment-form');
        (window as any).Stripe.createToken(form, function (status, response) {
          if (response.error) {
            setPaymentErrors({ generic: [response.error.message] });
            modal.enableOkButton(getOkText());
          }
          else {
            submit(response);
          }
        });
      }
    }
    else {
      modal.enableOkButton(getOkText());
    }
  }

  function submitRentalRequest(modal): void {
    const quantity = getQuantity();

    function submit(): void {
      if (data.textM) {
        const rentalData = getRentalTermAndPrice(data.textM.rentalPrices);
        const term = rentalData.term;

        const reqData: any = {
          adminName,
          adminEmail,
          term,
          learnentityid: data.textM.learnEntityId,
          layerid: data.textM.layerId,
          quantity,
        };

        requestRental({
          data: reqData,
          callback: (resp) => {
            if (resp) {
              modal.handleClose();
              showHideRentalRequestConfirmationModal(true, {
                title: data.textM.title || '',
                adminEmail,
                quantity,
              });
            }
            else {
              modal.enableOkButton(getOkText());
              showAlert({
                type: 'error',
                message: 'Something went wrong. Please try again.',
                autoClose: false,
              });
            }
          },
        });
      }
    }

    if (validateRequestForm(quantity)) {
      modal.disableOkButton(getOkText());
      submit();
    }
    else {
      modal.enableOkButton(getOkText());
    }
  }

  function validatePaymentForm(quantity): boolean {
    const isFirstTimePurchase = getIsFirstTimePurchase();

    if (quantity === 0) {
      setPaymentErrors({ quantity: 'Please enter a quantity.' });
      setFlashOnFields(['quantity']);

      return false;
    }
    else if (isFirstTimePurchase && quantity < minQuantity) {
      showMinQuantityMessage();

      return false;
    }
    else {
      setFlashOnFields([]);
    }

    if (!paymentModalData.isLicenseAvailableOnUnlimited && parseInt(priceDataState.data.totalToCharge) > 0) {
      const numValid = (window as any).Stripe.card.validateCardNumber(
        (document.getElementsByClassName('cc-number')[0] as HTMLInputElement)
          .value
      );
      const expValid = (window as any).Stripe.card.validateExpiry(
        (document.getElementsByClassName('cc-month')[0] as HTMLInputElement)
          .value,
        (document.getElementsByClassName('cc-year')[0] as HTMLInputElement)
          .value
      );
      const cvcValid = (window as any).Stripe.card.validateCVC(
        (document.getElementsByClassName('cc-cvc')[0] as HTMLInputElement).value
      );
      const errorStrings: string[] = [];

      if (!numValid) {
        errorStrings.push('Your credit card number is invalid.');
      }
      else if (!expValid) {
        errorStrings.push('Your expiration date is invalid.');
      }
      else if (!cvcValid) {
        errorStrings.push('Your cvc number is invalid.');
      }

      if (errorStrings.length > 0) {
        setPaymentErrors({ card: errorStrings });
        setTimeout(() => {
          const input = document.getElementById('ccNumber');
          input?.focus();
        }, 200);
        return false;
      }
    }

    return true;
  }

  function validateRequestForm(quantity): boolean {
    let status = true;

    const isFirstTimePurchase = getIsFirstTimePurchase();

    if (quantity === 0) {
      setPaymentErrors({ quantity: 'Please enter a quantity.' });
      setFlashOnFields(['quantity']);
      status = false;
    }
    else if (isFirstTimePurchase && quantity < minQuantity) {
      showMinQuantityMessage();
      status = false;
    }
    else if (!paymentModalData.isLicenseAvailableOnUnlimited && parseInt(priceDataState.data.totalToCharge) > 0) {
      const adminEmailValid = isValidEmail(adminEmail);

      const errorStrings: any = {};
      const flashFields: string[] = [];

      if (!adminName) {
        errorStrings['adminName'] = 'Please enter a valid admin name';
        flashFields.push('adminName');
      }

      if (!adminEmailValid) {
        errorStrings['adminEmail'] = 'Please enter a valid admin email';
        flashFields.push('adminEmail');
      }
      setFlashOnFields(flashFields);

      if (Object.keys(errorStrings).length > 0) {
        setPaymentErrors(errorStrings);
        return false;
      }
    }
    return status;
  }

  function fetchHeadCountData(): void {
    if (!headCountData.fetched) {
      getStudentCount({
        data: { learnEntityId: data.textM.learnEntityId },
        callback: (resp) => {
          const headCountData: HeadCountDataInterface = {
            classStudentLicenseCount: [],
            totalStudentLicenseCount: {
              totalCount: 0,
              numOfStudentsNeedingLicense: 0,
              numOfStudentsUsingLicenses: 0,
            },
            fetched: true,
          };

          if (resp) {
            headCountData.numTotalLicensesAvailable = resp.numTotalLicensesAvailable;
            headCountData.classStudentLicenseCount = resp.classStudentLicenseCount;
            headCountData.isNoStudentYet = resp.classStudentLicenseCount.length === 0;
            headCountData.totalStudentLicenseCount = resp.totalStudentLicenseCount;
          }
          else {
            headCountData.error = 'An unexpected error occurred!';
          }

          setHeadCountData(headCountData);
        },
      });
    }
  }

  function getIsFirstTimePurchase(): boolean {
    if (data.source !== undefined) {
      return [
        LearnEntityPurchaseSource.CATALOG_TILE,
        LearnEntityPurchaseSource.PREVIEW_READER,
      ].includes(data.source);
    }

    return false;
  }

  function onQuantityChange(event): void {
    if (parseInt(event.target.value) <= 1000) {
      setInputQuantity(parseInt(event.target.value));
      updateTotal(event.target.value);
    }
    else if (parseInt(event.target.value) > 1000) {
      setInputQuantityErrorState('Exceeded 1000 licenses limit.');
      setInputQuantity(1000);
      setTimeout(() => setInputQuantityErrorState(''), 4000);
    }
    else {
      setInputQuantity(undefined);
    }
  }

  function getOkText(): string {
    return (paymentModalData.isLicenseAvailableOnUnlimited || userData.isOnPrimeUnlimited)
      ? 'Continue'
      : showRequestTypeForm && requestType !== 'purchase'
        ? 'Request Invoice'
        : 'Pay Now';
  }

  function onCancelClick(): void {
    if (popoverState) {
      cancelCallback();
    }
    trackGAEvent('teacher', 'purchase:cancel');
  }

  let availableLicenses = (headCountData.numTotalLicensesAvailable || 0);

  let priceList: any = []

  if (priceDataState.data.license && !priceDataState.data.hasActiveLicenseAssigned) {
    priceList.push(['$' + priceDataState.data.license, '(your license)']);
  }
  else {
    priceList.push(['$0.00', '(your license)']);
  }

  if (priceDataState.data.teacherOrStudentLicenses) {
    let description = `(${inputQuantity || 0} student licenses)`;
    priceList.push(['$' + priceDataState.data.teacherOrStudentLicenses, description ]);
  }

  if (priceDataState.data.bookCredit && priceDataState.data.remainingCredit) {
    let description = `(book credits, $${priceDataState.data.remainingCredit} remaining)`;
    priceList.push(['-$' + priceDataState.data.bookCredit, description]);
  }

  let value = inputQuantity!! + (priceDataState.data.license &&  !priceDataState.data.hasActiveLicenseAssigned ? 1 : 0);
  if (isNaN(value)) {
    value = 0;
  }

  priceList.push([
    `Total $${priceDataState.data.totalToCharge}`,
    `(${value} ${plural(value, 'license', 'licenses')})`,
  ]);

  return (
    <Modal
      okText={getOkText()}
      id="paymentModal"
      cancelCallback={onCancelClick}
      title={getModalTitle()}
      okCallback={handleSubmit}
      maxWidth="md"
      cssClass={styles.modal}
      ref={modalEl}
    >
      {paymentModalData.fetched && (
        <div>
          <div>
            <LicensePurchaseInformation
              paymentModalData={paymentModalData}
              data={data}
              userData={userData}
              isFirstTimePurchase={isFirstTimePurchase}
            />
            {(!userData.isOnPrimeUnlimited ||
              paymentModalData.isLicenseAvailableOnUnlimited) &&
              (userData.isSchoolUsingContentCreditsOrUnlimited ? (
                <ExternalLink href={helpArticleLink.contentCredits}>
                  Learn more about Manage-rental-licenses
                </ExternalLink>
              ) : (
                <ExternalLink href={helpArticleLink.managerentals}>
                  Learn more about Manage-rental-licenses
                </ExternalLink>
              ))}
            {!isTextAccessibleInCountry && (
              <p className="warning">
                Note: The publisher has not made this text available for schools
                in {userData.primarySchool.country}. Your students may not be
                able to access this text.
              </p>
            )}
          </div>
          {disabledFeatures.length && (
            <div className={`${styles.disabledFeatures} my-lg py-md error`}>
              Note: The publisher{' '}
              {getPublisherName(data.textM.publisher)
                ? `(${getPublisherName(data.textM.publisher)})`
                : ''}{' '}
              of this text prevents:
              <ul>
                {disabledFeatures.map((feature) => (
                  <li>{feature}</li>
                ))}
              </ul>
            </div>
          )}
          <Divider />
          <div className="bodyText-lg bold mb-md">Rental Price</div>
          <RentalPriceView
            data={data}
            isAvailableForFree={paymentModalData.isLicenseAvailableOnUnlimited}
          />
          <form id="payment-form">
            <div className="flex flexWrap spaceBetween ">

              <div className={`${styles.paymentColumnOne} pt-lg mt-lg`}>
                {!paymentModalData.isLicenseAvailableOnUnlimited && !userData.isOnPrimeUnlimited &&
                  data.textM.rentalPrices &&
                  data.source !== LearnEntityPurchaseSource.TEACHER_READER && (
                  <div>
                    <div>
                      <div className="mb-sm bold">
                        Number of Student Licenses
                      </div>
                      <div className="mb-md">
                        <i>Include licenses for any co-teachers</i>
                      </div>
                      <div className="flex alignCenter">
                        <TextField
                          tabIndex={0}
                          error={!!inputQuantityErrorState}
                          className={`
                              ${customStyles.licenseInput} 
                              my-lg mr-lg ${
                                flashOnFields.includes('quantity')
                                  ? 'flash'
                                  : ''
                            }`}
                          variant="outlined"
                          type="number"
                          onChange={onQuantityChange}
                          value={inputQuantity}
                          inputProps={{
                            'aria-label': 'select number of licenses',
                            min: 0,
                            maxLength: 4,
                            max: 1001,
                          }}
                        />
                        <PopoverButton
                          content="View head count"
                          onClose={() => {
                            setPopoverState(false);
                            setTimeout(() => setPopoverState(true), 200);
                          }}
                          className="btn-box ml-lg"
                          onClick={fetchHeadCountData}
                        >
                          {!headCountData.fetched ? (
                            <div>Loading...</div>
                          ) : headCountData.error ? (
                            <div>An unexpected error occurred!</div>
                          ) : headCountData.isNoStudentYet ? (
                            <div>You don't have any students yet!</div>
                          ) : (
                            <div className={styles.headCountPopup}>
                              <div className={`${styles.info}`}>
                                <p>
                                  You have {availableLicenses} available
                                  licenses.
                                </p>
                              </div>
                              <Divider />
                              <table className={styles.headCountTable}>
                                <caption className="visuallyHidden">Student Licenses</caption>
                                <tr>
                                  <th>Class Name</th>
                                  <th>Total Students</th>
                                  <th>Licenses Used</th>
                                  <th>Licenses Needed</th>
                                </tr>
                                {headCountData.classStudentLicenseCount.map(
                                  (studentLicenseData, i) => {
                                    if (
                                      availableLicenses > 0 &&
                                      studentLicenseData.numOfStudentsNeedingLicense >
                                        0
                                    ) {
                                      availableLicenses = Math.max(
                                        0,
                                        availableLicenses -
                                          studentLicenseData.numOfStudentsNeedingLicense
                                      );
                                    }

                                    totalStudents +=
                                      studentLicenseData.totalCount;
                                    totalLicensesNeeded +=
                                      studentLicenseData.numOfStudentsNeedingLicense;
                                    totalLicensesUsed +=
                                      studentLicenseData.numOfStudentsUsingLicenses;
                                    return (
                                      <tr key={i}>
                                        <td role="rowheader">{studentLicenseData.className}</td>
                                        <td>{studentLicenseData.totalCount}</td>
                                        <td>
                                          {
                                            studentLicenseData.numOfStudentsUsingLicenses
                                          }
                                        </td>
                                        <td>
                                          {
                                            studentLicenseData.numOfStudentsNeedingLicense
                                          }
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                                <tr className={styles.totalsRow}>
                                  <th>Total</th>
                                  <td>{headCountData.totalStudentLicenseCount.totalCount}</td>
                                  <td>{headCountData.totalStudentLicenseCount.numOfStudentsUsingLicenses}</td>
                                  <td>{headCountData.totalStudentLicenseCount.numOfStudentsNeedingLicense}</td>
                                </tr>
                              </table>
                            </div>
                          )}
                        </PopoverButton>
                      </div>
                      {!!inputQuantityErrorState && (
                        <div className="warning py-md" role="alert">
                          {inputQuantityErrorState}
                        </div>
                      )}
                      {!!paymentErrors.quantity && (
                        <div className="warning py-md" role="alert">
                          {paymentErrors.quantity}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {!paymentModalData.isLicenseAvailableOnUnlimited && !userData.isOnPrimeUnlimited &&
                data.textM.rentalPrices &&
                data.source !== LearnEntityPurchaseSource.TEACHER_READER &&
                priceDataState.show && (
                <div className="pt-lg mt-lg">
                  <TableContainer component="div" role="presentation">
                    <TableBody className={customStyles.root}>
                      {priceList.map((val, index) => 
                        <TableRow key={`${index}`}>
                          <TableCell align="right">
                            {`${val[0]} :`}&nbsp;
                          </TableCell>
                          <TableCell align="left">
                            {`${val[1]}`}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </TableContainer>
                </div>
                )
              }
            </div>
            <div>
              <Divider />
              {!paymentModalData.isLicenseAvailableOnUnlimited && !userData.isOnPrimeUnlimited &&
                showRequestTypeForm && (
                <TextPurchaseOrRequestForm
                  requestType={requestType}
                  onRequestTypeChange={onRequestTypeChange}
                  onAdminNameChange={onAdminNameChange}
                  onAdminEmailChange={onAdminEmailChange}
                  adminName={adminName}
                  adminEmail={adminEmail}
                  paymentFormDisabled={paymentFormDisabled}
                  flashOnFields={flashOnFields}
                  paymentErrors={paymentErrors}
                />
              )}
              {paymentErrors.generic?.length > 0 &&
                paymentErrors.generic.map((paymentError) => (
                  <div key={paymentError} className="warning py-md">
                    {paymentError}
                  </div>
                ))}
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
}

export default PaymentModal;