import produce from 'immer';

const initialState = {
  currentProblemId: null,
  currentStepId: null,
  hasEntityLoaded: false,
  submittedResponse: false,
  studentUserId: null,
  hasEntityRendered: false,
};

const reducer: any = produce((draft, action: any) => {
  switch (action.type) {
  case 'RESOLVE_PROBLEMSET_DATA': {
    draft.currentProblemId = action.body.problemId || null;
    draft.currentStepId = action.body.stepId || null;
    draft.hasEntityLoaded = false;
    draft.submittedResponse = false;
    break;
  }
  case 'GO_TO_PROBLEM': {
    draft.currentProblemId = action.body.problemId;
    draft.currentStepId = null;
    draft.hasEntityLoaded = false;
    draft.submittedResponse = false;
    draft.hasEntityRendered = false;
    break;
  }
  case 'GO_TO_STEP':
  case 'RESOLVE_SKIPPED_TO_STEPS': {
    draft.currentStepId = action.body.stepId;
    draft.hasEntityLoaded = false;
    draft.submittedResponse = false;
    draft.hasEntityRendered = false;
    break;
  }
  case 'RESOLVE_PROBLEM_DATA':
  case 'RESOLVE_GET_STEP_DATA': {
    draft.hasEntityLoaded = true;
    break;
  }
  case 'PROBLEM_RENDERED': {
    draft.hasEntityRendered = true;
    break;
  }
  case 'REQUEST_SAVE_PROBLEM_EDITS':
  case 'REQUEST_SAVE_STEP_EDITS':
  case 'RESOLVE_SHOW_STEPANSWER': {
    draft.hasEntityLoaded = false;
    break;
  }
  case 'SUBMITTED_RESPONSE': {
    draft.submittedResponse = true;
    break;
  }
  case 'RESOLVE_GRADE_RESPONSE': {
    draft.submittedResponse = false;
    break;
  }
  case 'RESOLVE_GRADE_BENCHMARK_RESPONSE': {
    draft.submittedResponse = false;

    if (action.body.nextProblemId && action.body.isValidAttempt) {
      draft.currentProblemId = action.body.nextProblemId;
      draft.hasEntityLoaded = false;
      draft.hasEntityRendered = false;
    }
    break;
  }
  case 'RESOLVE_RESTART_PROBLEM': {
    draft.hasEntityLoaded = false;
    draft.submittedResponse = false;
    draft.hasEntityRendered = false;
    draft.currentStepId = null;
    break;
  }
  }
}, initialState);

export default reducer;
