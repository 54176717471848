import React from 'react';
import { Route } from 'react-router-dom';
import RedirectToAL from '../redirectToAL';

class PrivateRoute extends React.PureComponent<any, any> {
  componentDidMount() {

    if (!this.props.userId) {
      this.props.fetchData();
    }
  }

  render() {
    if (this.props.userId) {
      const Component = this.props.component;
      const newProps = { ...this.props, component: undefined };

      return (
        <Route
          {...newProps}
          render={(props) => (
            this.props.userId
              ? <Component {...props} />
              : <RedirectToAL /> 
          )}
        />
      )
    }
    else {
      return null;
    }
  }
};

export default PrivateRoute;
