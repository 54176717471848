import produce from 'immer';
import { sortArrayByGivenSortOrder } from '../utils/common';

const initialState = {
  problemSetId: null,
  isLocked: null,
  startDate: null,
  endDate: null,
  classId: null,
  problemSetName: null,
  description: null,
  problemIds: [] as number[],
  completedProblemIds: [] as number[],
  mastery: 0,
  isBenchmark: false,
  levelSetLocation: null,
  isInvalidLevelSetAttempt: false,
  isIntervention: false,
  createdFromProblemSetId: null,
  createdFromProblemSetName: null,
  isAssigned: false,
};

const reducer: any = produce((draft, action: any) => {
  switch (action.type) {
  case 'RESOLVE_PROBLEMSET_DATA': {
    Object.assign(draft, { ...action.body, problemId: undefined, stepId: undefined });
    break;
  }
  case 'RESOLVE_GRADE_RESPONSE':
  case 'RESOLVE_SHOW_STEPANSWER': {
    if (action.body.isSameProblemSetId) {
      draft.mastery = action.body.newMastery;
    }

    if (action.body.completedProblemId) {
      draft.completedProblemIds.push(action.body.completedProblemId);
      draft.problemIds = sortArrayByGivenSortOrder(draft.problemIds, draft.completedProblemIds);
    }
    break;
  }
  case 'RESOLVE_GRADE_BENCHMARK_RESPONSE': {
    if (action.body.completedProblemId) {
      draft.completedProblemIds.push(action.body.completedProblemId);
    }
    draft.isInvalidLevelSetAttempt = action.body.isInvalidLevelSetAttempt;
    break;
  }
  case 'RESOLVE_SKIPPED_TO_STEPS': {
    draft.mastery = action.body.newMastery;
    break;
  }
  case 'RESOLVE_TRACK_LEVELSET_LOCATION': {
    draft.levelSetLocation = action.body.levelSetLocation;
    break;
  }
  case 'RESOLVE_MOVE_PROBLEM': {
    draft.problemIds = action.body;
    break;
  }
  case 'RESOLVE_UPDATE_PROBLEM_PROBLEMSET': {
    draft.problemSetId = action.body.newProblemSetId;
  }
  }
}, initialState);

export default reducer;
