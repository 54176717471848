import React, { useEffect } from 'react';
import {
  FaFileAlt,
  FaToggleOn,
  FaToggleOff,
} from 'react-icons/fa';
import {
  AiOutlineLineChart,
} from 'react-icons/ai';
import Button from '../../../common/button/component';
import styles from './styles.module.css';
import './toolbarOverrides.css';
import LearnosityQuestion from '../../common/learnosityQuestion/container';
import LearnosityQuestionEditorModal from '../../modals/learnosityQuestionEditor/container';
import { closeModal, openModal } from '../../../../actions/eventEmitter';
import { Modal, Tooltip } from 'sharedfrontend';
import { Role } from '../../../../utils/constants';
import { addReadSpeakerClickEvents, destroyReadSpeakerActivePlayer } from '../../../../utils/readspeaker';
import config from '../../../../config';
import classNames from 'classnames';

interface IProps {
  features: any[];
  formulaSheetUrl: string | null;
  questionType?: string;
  role: string;
  showGraphingTool: boolean;
  targetId: string;
  showProtractor: boolean;
  showRuler: boolean;
  stepId: number;
  problemId: number;
  toggleFeatures: any;
}

const Toolbar = (props: IProps) => {
  const {
    stepId,
    problemId,
    toggleFeatures,
    targetId,
    features,
    formulaSheetUrl,
    showGraphingTool,
    questionType,
    role,
    showRuler,
    showProtractor,
  } = props;

  useEffect(() => {
    addReadSpeakerClickEvents();
  }, []);

  useEffect(() => {
    return () => {
      destroyReadSpeakerActivePlayer();
    };
  }, [targetId]);

  const onToggleFeature = (feature: string) => {
    if (toggleFeatures) {
      if (stepId) {
        toggleFeatures({ stepId, feature });
      }
      else if (problemId) {
        toggleFeatures({ problemId, feature });
      }
    }
  };

  const href = `https://app-na.readspeaker.com/cgi-bin/rsent?customerid=13049&lang=en_us&readid=${
    targetId}&url=${encodeURI(process.env.REACT_APP_ENV === 'local' ? 'localhost:5001' : config.alFrontendUrl)}`;

  return (
    <>
      <div id="readspeaker_button1" className="rs_skip rsbtn rs_preserve">
        <a
          rel="nofollow"
          className="rsbtn_play"
          title="Listen to this page"
          href={href}
        >
          <span className="rsbtn_left rsimg rspart">
            <span className="rsbtn_text">
              <span>Listen</span>
            </span>
          </span>
          <span className="rsbtn_right rsimg rsplay rspart"></span>
        </a>
      </div>
      <div className={classNames('toolbar', {[styles.toolbar]: true})}>
        {role === Role.SUPERADMIN && toggleFeatures && (
          <>
            <React.Fragment>
              <TooltipWrapper title={`${showRuler ? 'Shown' : 'Hidden'} for Ts and Ss`}>
                <Button
                  iconLabel="Ruler"
                  iconSize="large"
                  onClick={() => onToggleFeature('showRuler')}
                >
                  {showRuler ? <FaToggleOn /> : <FaToggleOff />}
                </Button>
              </TooltipWrapper>
              <TooltipWrapper title={`${showProtractor ? 'Shown' : 'Hidden'} for Ts and Ss`}>
                <Button
                  iconLabel="Protractor"
                  iconSize="large"
                  onClick={() => onToggleFeature('showProtractor')}
                >
                  {showProtractor ? <FaToggleOn /> : <FaToggleOff />}
                </Button>
              </TooltipWrapper>
            </ React.Fragment>
            {(features.length > 0 || formulaSheetUrl || showGraphingTool) &&
              <div className={styles.divider}></div>
            }
          </>
        )}
        {features.map((feature: any) => {
          const title = getFeatureTitle(feature);

          return (
            <TooltipWrapper title={title} key={feature.feature_id}>
              <span className={`learnosity-feature feature-${feature.feature_id}`}></span>
            </TooltipWrapper>
          );
        })}
        {formulaSheetUrl && <FormulaSheet url={formulaSheetUrl} />}
        {showGraphingTool && <Graph type={questionType} role={role} />}
      </div>
    </>
  );
};

const Graph = (props: { type?: string; role: string }): JSX.Element => {
  return (
    <TooltipWrapper title="Graph">
      <Button
        additionalStyles={styles.graphButton + ' btnGraph'}
        iconSize="large"
        onClick={() => {
          const params = {
            type: props.type,
            isGraphingTool: true,
          };

          if (props.role === Role.SUPERADMIN) {
            openModal(<LearnosityQuestionEditorModal {...params} />);
          }
          else {
            openModal(
              <Modal
                title=""
                maxWidth="xl"
                okText=""
                cancelText="Close"
                cancelCallback={closeModal}
              >
                <LearnosityQuestion {...params} />
              </Modal>
            );
          }
        }}
      >
        <AiOutlineLineChart />
      </Button>
    </TooltipWrapper>
  );
};

const FormulaSheet = (props: { url: string }): JSX.Element => {
  return (
    <TooltipWrapper title="Formula sheet">
      <Button
        additionalStyles={styles.formulaSheetButton + ' btnFormulaSheet'}
        iconSize="large"
        onClick={() => {
          openModal(
            <Modal
              title=""
              maxWidth="xl"
              okText=""
              cancelText="Close"
              cancelCallback={closeModal}
            >
              <iframe
                src={props.url + '#toolbar=0&zoom=100'}
                className={styles.iframe}
                height="100%"
                width="100%"
                title="Formula Sheet"
                style={{ height: '100vh', width: 'min(95vw, 900px)', minWidth: Math.min(window.innerWidth * 0.9, 600) }}
              />
            </Modal>
          );
        }}
      >
        <FaFileAlt />
      </Button>
    </TooltipWrapper>
  );
};

const TooltipWrapper = (props: { title: string; children: any }): JSX.Element => {
  return (
    <Tooltip title={props.title} tooltipColor="white">
      <span>
        {props.children}
      </span>
    </Tooltip>
  )
};

const getFeatureTitle = (feature: any) => {
  const { calculator_type, label, mode, type } = feature;

  switch (type) {
  case 'imagetool':
    return label;
  case 'calculator':
    if (mode === 'basic') {
      return 'Calculator';
    }
    if (mode === 'scientific') {
      return 'Scientific Calculator';
    }
    break;
  case 'customfeature':
    if (calculator_type === 'fourfunction') {
      return 'Desmos Calculator';
    }
    if (calculator_type === 'graphing') {
      return 'Desmos Graphing Calculator';
    }
    if (calculator_type === 'scientific') {
      return 'Desmos Scientific Calculator';
    }
    break;
  default:
    return '';
  }
};

export default Toolbar;
