import React from 'react';
import { Button } from '../button';

interface IPropsMenu {
  dropdownClass?: string;
  buttonClass?: string;
  buttonId?: string;
  buttonTitle?: string;
  children: any;
  buttonChild?: any;
  menuCustomClass?: string;
  onClick?: any;
  customProps?: any;
  [rest: string]: any;
};

export default function DropdownMenu({
  dropdownClass = '', buttonClass = '', buttonId = '',
  buttonTitle = '', children, buttonChild = null,
  menuCustomClass = '', onClick = (e) => { }, customProps, ...rest
}: IPropsMenu) {

  return <div className={'dropdown ' + dropdownClass} {...customProps}>
    <Button
      className={buttonClass}
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      title={buttonTitle}
      id={buttonId}
      onClick={onClick}
      {...rest}>
      {buttonChild}
    </Button>
    <ul className={'dropdown-menu ' + menuCustomClass} aria-labelledby={buttonId}>
      {children}
    </ul>
  </div>;
};

interface IPropsItem {
  key?: any;
  className?: string;
  onClick?: any;
  text: any;
  href?: string;
};

export const DropdownItem = ({
  className = '',
  onClick = (e) => { },
  text,
  href = 'javascript:void(0)',
  ...rest
}: IPropsItem) => {
  return <li>
    <a className={className} href={href} onClick={onClick} {...rest}>{text}</a>
  </li>;
};
