import React from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { updateHash } from '../../utils/iframe';

const useStyles = makeStyles({
  root: {
    minWidth: 280,
    margin: 20,
    cursor: 'pointer',
  },
  media: {
    height: '320px'
  },
});

const Tile = (props: { 
  title: string;
  src: string;
  titleSlug: string;
}) => {
  const openRevealBook = (titleSlug: string) => {
    updateHash({ path: `/reveal/${titleSlug}`, redirect: true });
  };

  const classes = useStyles();

  return (
    <Card 
      className={classes.root}
      onClick={() => openRevealBook(props.titleSlug)} 
      onKeyDown={() => openRevealBook(props.titleSlug)}
    >
      <CardMedia
        className={classes.media}
        image={props.src}
      />
      <CardContent>
        <Typography variant="h6" component="h6" align="center">
          {props.title}
        </Typography>
      </CardContent>
    </Card>
  )
};

export default Tile;