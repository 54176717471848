import React, { useState, useEffect } from 'react';
import DayJsUtils from '@date-io/dayjs';
import { DateTimePickerViewsProps, KeyboardDateTimePicker, MuiPickersUtilsProvider, DateTimePicker as MuiDateTimePicker } from "@material-ui/pickers";
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

const useStyles = makeStyles({
  dateTimePicker: {
    fontWeight: 600,
    maxWidth: 250,
  },
  withoutKeyboard: {
    '& input': {
      cursor: 'pointer',
    },
  },
});

export interface IPropsDateTimePicker extends DateTimePickerViewsProps {
  id: string;
  label?: string;
  value: Date | null | string;
  onChange,
  format?: string;
  inputVariant?: 'standard' | 'outlined' | 'filled';
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  autoOk?: boolean;
  allowKeyboardControl?: boolean;
  ampm?: boolean;
  clearable?: boolean;
  disableToolbar?: boolean;
  className?: string;
  placeholder?: string;
  minDateMessage?: string;
  [key: string]: any;
};

export default function DateTimePicker({
  id,
  label = '',
  value,
  onChange,
  withKeyBoard = false, // Don't use with keyboard as am/pm typing does not work currently
  format = 'MM/DD/YYYY hh:mm a',
  inputVariant = 'outlined',
  disabled = false,
  disableFuture = false,
  disablePast = true,
  autoOk = !withKeyBoard,
  allowKeyboardControl = true,
  ampm = true,
  clearable = false,
  disableToolbar = false,
  className = '',
  placeholder = withKeyBoard ? 'mm/dd/yyyy hh:mm' : '',
  minDateMessage = 'Date should not be in the past',
  showTodayButton = true,
  ...rest
}: IPropsDateTimePicker) {
  const styles = useStyles();
  // Need local state to set value locally but do not update in parent state when value is invalid
  const [val, setVal] = useState(value);

  useEffect(() => {
    // sync local state with parent state
    setVal(value);
  }, [value]);

  const classes = classnames([styles.dateTimePicker], className, { [styles.withoutKeyboard]: !withKeyBoard });

  function handleKeyboardDateTimeChange(date, value) {
    let valueToSet;

    if (value && (value.endsWith(' p') || value.endsWith(' a')) && isNaN(Date.parse(`${val}`))) {
      valueToSet = `${value}m`;
    }
    else {
      valueToSet = value;
    }

    setVal(valueToSet);

    // Only change state in parent component if value is either valid or null
    if (!valueToSet) {
      onChange(valueToSet);
    }
    else if (!valueToSet.includes('_')) {
      const dateVal = getDateString(valueToSet);

      if (!isNaN(Date.parse(dateVal))) {
        onChange(new Date(dateVal));
      }
    }
  }

  function getDateString(value) {
    const valueToParse = (value.endsWith(' p') || value.endsWith(' a'))
      ? `${value}m`
      : value;

    // if user enters time like 16:00 pm / 16:00 am
    if (isNaN(Date.parse(valueToParse))) {
      return valueToParse.replace(/ am| pm/, '');
    }
    else {
      return valueToParse;
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DayJsUtils}>
      {withKeyBoard
        ? <KeyboardDateTimePicker
            id={id}
            label={label}
            value={val}
            onChange={handleKeyboardDateTimeChange}
            format={format}
            inputVariant={inputVariant}
            disabled={disabled}
            disableFuture={disableFuture}
            disablePast={disablePast}
            autoOk={autoOk}
            allowKeyboardControl={allowKeyboardControl}
            ampm={ampm}
            clearable={clearable}
            disableToolbar={disableToolbar}
            className={classes}
            placeholder={placeholder}
            minDateMessage={minDateMessage}
            showTodayButton={showTodayButton}
            {...rest} />
        : <MuiDateTimePicker
            id={id}
            label={label}
            value={value}
            onChange={(date) => onChange(date)}
            format={format}
            inputVariant={inputVariant}
            disabled={disabled}
            disableFuture={disableFuture}
            disablePast={disablePast}
            autoOk={autoOk}
            allowKeyboardControl={allowKeyboardControl}
            ampm={ampm}
            clearable={clearable}
            disableToolbar={disableToolbar}
            className={classes}
            placeholder={placeholder}
            minDateMessage={minDateMessage}
            showTodayButton={showTodayButton}
            {...rest} />
      }
    </MuiPickersUtilsProvider>
  );
};
