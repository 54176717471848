import React from 'react';
import { connect } from 'react-redux';
import { FaHeart } from 'react-icons/fa';
import classnames from 'classnames';
import AssignButton from '../common/assignButton/container';
import Button from '../common/button/component'
import { updateHash } from '../../utils/iframe';
import { handleEnterKey } from '../../utils/common';
import styles from './styles.module.css';
import { Role } from '../../utils/constants';
import { Modal } from 'sharedfrontend';
import { closeModal, openModal } from '../../actions/eventEmitter';

const ProblemSet = (props: {
  code: string;
  name: string;
  numProblems: number;
  problemSetId: number;
  description: string;
  isBenchmark: boolean;
  toggleFavorite: Function;
  favoriteProblemSetIds: number[];
  isReveal?: boolean;
  bookTitleSlug: string;
  assignedProblemSetIds?: number[];
  shouldShowInCatalog?: boolean;
  role?: Role;
  availableFromDate?: Date;
}): JSX.Element => {
  const isFavorite = props.favoriteProblemSetIds.includes(props.problemSetId);
  const standardCodes = props.code ? props.code.split(',') : [];
  const favoriteClassName = classnames({
    [styles.favorite]: true,
    [styles.redFavorite]: isFavorite,
  });

  const toggleFavorite = async (e: any) => {
    e.stopPropagation();
    props.toggleFavorite({
      problemSetIds: [props.problemSetId],
      isFavorited: !isFavorite,
    });
  };

  return (
    <div>
      {props.isBenchmark
        ? (
          <li>
            <div
              className={styles.subStandardLevelSet}
              onClick={
                onClickPreview.bind(
                  null, 
                  props.problemSetId, 
                  props.isReveal || false, 
                  props.bookTitleSlug,
                  props.availableFromDate,
                )
              }
              onKeyDown={(e) => handleEnterKey(e, onClickPreview.bind(
                null, 
                props.problemSetId,
                props.isReveal || false, 
                props.bookTitleSlug,
                props.availableFromDate,
              ))}
              role="button"
              tabIndex={0}
            >
              <div className={styles.subStandardNameDescContainer}>
                <div className={styles.subStandardNameContainer}>
                  <div className={`${styles.subStandardName} spaceBetween`}>
                    {props.name}
                    {props.role === Role.SUPERADMIN && !props.shouldShowInCatalog &&
                    <span className={styles.unpublished}>
                      Unpublished
                    </span>
                    }
                  </div>
                </div>
                <div className={styles.subStandardDescription}>{props.description}</div>
              </div>
            </div>
          </li>
        )
        : (
          <li>
            <div
              className={styles.subStandard}
              onClick={
                onClickPreview.bind(
                  null, 
                  props.problemSetId, 
                  props.isReveal || false, 
                  props.bookTitleSlug,
                  props.availableFromDate,
                )
              }
              onKeyDown={(e) => handleEnterKey(e, onClickPreview.bind(
                null, 
                props.problemSetId,
                props.isReveal || false, 
                props.bookTitleSlug,
                props.availableFromDate,
              ))}
              role="button"
              tabIndex={0}
            >
              <div className={styles.subStandardNameDescContainer}>
                <div className={styles.subStandardNameContainer}>
                  <div className={styles.subStandardName}>{props.name}</div>
                  <div className={styles.subStandardCode}>
                    {standardCodes.map(code => <span key={code} className={styles.standardCode}>{code}</span>)}
                  </div>
                </div>
                <div className={styles.subStandardDescription}>{props.description}</div>
              </div>
              <div className={styles.buttonContainerLevelSet}>
                {!props.isReveal && 
                <Button
                  onClick={toggleFavorite}
                  additionalStyles={favoriteClassName}
                >
                  <FaHeart />
                </Button>
                }
                <AssignButton
                  problemSetName={props.name}
                  problemSetId={props.problemSetId}
                  isBenchmark={props.isBenchmark}
                  description={props.description}
                  validRoles={[Role.SUPERADMIN, Role.TEACHER]}
                  revealBookTitle={props.bookTitleSlug}
                />
              </div>
            </div>
          </li>
        )
      }
    </div>
  );
};

const onClickPreview = (
  problemSetId: number, isReveal: boolean, bookTitleSlug: string, availableFromDate: Date,
) => {
  if (!!availableFromDate) {
    openModal(<ProblemSetUnavailableInfo />);
  }
  else {
    let path = `/mathreader?problemSetId=${problemSetId}`;

    if (isReveal) {
      path = `${path}&reveal=${bookTitleSlug}`;
    }

    updateHash({ path, redirect: true });
  }
};

const ProblemSetUnavailableInfo = () => {
  return (
    <Modal
      title=""
      okText=""
      cancelText="Close"
      cancelCallback={closeModal}
    >
      <p>This assignment is not available yet.</p>
    </Modal>
  );
}

const mapStateToProps = (state: any) => {
  return {
    favoriteProblemSetIds: state.catalogData.favoriteProblemSetIds,
    bookTitleSlug: state.catalogData.revealBookTitleSlug || '',
    role: state.userData.role,
  };
};

export default connect(
  mapStateToProps,
)(ProblemSet);