import React, { useEffect, useRef } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Collapse, createMuiTheme, FormControlLabel, ThemeProvider } from '@material-ui/core';
import { RadioButton, RadioButtonBox, RadioButtonGroup } from '../button';
import { MenuSection } from '../menus';
import { FromToDayPickers } from './FromToDayPickers';
import { showAlert } from '../alert';
import {
  DateFilterType,
  getFilterType,
  getCurrentSchoolYearDatesAsObj,
  getLastSchoolYearDatesAsObj,
  getLast7DaysDatesAsObj,
  getLast30DaysAsObj,
} from '../../helpers/dateHelpers';

export enum RadioButtonTypes {
  Standard = 'standard',
  Box = 'box',
}

interface IReportDateFiltersProps {
  defaultStart: Dayjs;
  defaultEnd: Dayjs;
  onChange: Function;
  radioType?: RadioButtonTypes;
  callBack? :Function;
  dataRangeOptions?: { [key: string]: string }[];
}

const activelyLearnTheme = createMuiTheme({
  overrides: {
    MuiTypography: {
      body1: {
        fontFamily: '\"WorkSans-Medium\", \"Helvetica\", \"Arial\", sans-serif',
        fontSize: 14,
      },
    }
  },
});

function usePrevious(value): any {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const ReportDateFilters: React.FunctionComponent<IReportDateFiltersProps> = (props) => {
  const { defaultStart, defaultEnd, onChange, radioType, dataRangeOptions, callBack } = props;
  const options = dataRangeOptions || getDateRangeOptions();
  const [selectedFilter, setSelectedFilter] = React.useState(getFilterType(defaultStart, defaultEnd));
  const [dynamicChange, setDynamicChange] = React.useState(false);
  const [filterDates, setFilterDates] = React.useState({ start: defaultStart, end: defaultEnd });
  const prevFilterDates = usePrevious(filterDates);

  useEffect(() => {
    if (prevFilterDates && ((dynamicChange) || (( typeof prevFilterDates.start=='string' ) ||
      (!prevFilterDates.start.isSame(filterDates.start) || !prevFilterDates.end.isSame(filterDates.end))))
    ) {
      setDynamicChange(false);
      onChange(filterDates);
    }
  }, [filterDates.start, filterDates.end]);

  useEffect(() => {
    if (dataRangeOptions && !dataRangeOptions.find((x) => x.value === selectedFilter)) {
      if(dataRangeOptions.find((x)=>x.value==DateFilterType.CustomRange))
        setSelectedFilter(DateFilterType.CustomRange);
      else
        setSelectedFilter(DateFilterType.Last30Days);
    }
  }, [dataRangeOptions]);

  useEffect(()=>{
    if(callBack)
      callBack(selectedFilter);
  }, [selectedFilter])
  function getDateRangeOptions() {
    return [
      {
        label: 'Last 7 Days',
        value: DateFilterType.Last7Days
      },
      {
        label: 'Last 30 Days',
        value: DateFilterType.Last30Days
      },
      {
        label: 'Current School Year',
        value: DateFilterType.CurrentSchoolYear
      },
      {
        label: 'Last School Year',
        value: DateFilterType.LastSchoolYear
      },
      {
        label: 'Custom Range',
        value: DateFilterType.CustomRange
      },
    ];
  }

  const handleFilterChange = (e) => {
    const { value } = e.target; 
    setDynamicChange(value != selectedFilter);
    setSelectedFilter(value);
    let alertMessage = 'Content has been updated';

    switch (value) {
      case DateFilterType.Last7Days:
        setLast7Days();
        alertMessage = 'Content has been updated with last 7 days data';
        break;
      case DateFilterType.Last30Days:
        setLast30Days();
        alertMessage = 'Content has been updated with last 30 days data';
        break;
      case DateFilterType.LastSchoolYear:
        setLastSchoolYear();
        alertMessage = 'Content has been updated with last school year data';
        break;
      case DateFilterType.CustomRange:
        handleCustomRangeStartChange(defaultStart || dayjs().startOf("month"));
        alertMessage = 'Content has been updated with custom range data';
        break;
      case DateFilterType.CurrentSchoolYear:
      default:
        setCurrentSchoolYear();
        alertMessage = 'Content has been updated with current school year data';
        break;
    }

    showAlert({
      message: alertMessage,
      resetFocus: false,
      type: 'error'
    });
  };

  const setLast7Days = () => {
    const { start, end } = getLast7DaysDatesAsObj();
    setFilterDates({ start, end });
  };

  const setLast30Days = () => {
    const { start, end } = getLast30DaysAsObj();
    setFilterDates({ start, end });
  };

  const setLastSchoolYear = () => {
    const { start, end } = getLastSchoolYearDatesAsObj();
    setFilterDates({ start, end });
  };

  const setCurrentSchoolYear = () => {
    const { start, end } = getCurrentSchoolYearDatesAsObj();
    setFilterDates({ start, end });
  };

  const handleCustomRangeStartChange = (date) => {
    setFilterDates({ start: date, end: defaultEnd || dayjs().endOf('day') });
  };

  const handleCustomRangeEndChange = (date) => {
    setFilterDates({ start: filterDates.start, end: date });
  };

  if (radioType === RadioButtonTypes.Standard) {
    return (
      <ThemeProvider theme={activelyLearnTheme}>
        <MenuSection title="Report Filters" color="light">
          <RadioButtonGroup
            aria-label="report date filter"
            name="report-date-filters"
            onChange={handleFilterChange}
            value={selectedFilter}
          >
            {options.map((option) => (
              <FormControlLabel
                value={option.value}
                key={option.label}
                control={<RadioButton />}
                label={option.label}
              />
            ))}
            <Collapse in={selectedFilter === DateFilterType.CustomRange}>
              <FromToDayPickers
                fromLabel="From"
                fromOnChange={handleCustomRangeStartChange}
                toLabel="To"
                toOnChange={handleCustomRangeEndChange}
                selectedDates={filterDates}
                className="reportDateFilterPicker"
              />
            </Collapse>
          </RadioButtonGroup>
        </MenuSection>
      </ThemeProvider>
    );
  }
  else {
    return (
      <ThemeProvider theme={activelyLearnTheme}>
        <MenuSection title="Report Filters" color="light">
          <RadioButtonGroup
            aria-label="report date filter"
            name="report-date-filters"
            onChange={handleFilterChange}
            value={selectedFilter}
          >
            <RadioButtonBox value={DateFilterType.Last7Days}label="Last 7 Days" />
            <RadioButtonBox
              value={DateFilterType.CurrentSchoolYear}
              label="Current School Year"
            />
            <RadioButtonBox value={DateFilterType.LastSchoolYear} label="Last School Year" />
            <RadioButtonBox value={DateFilterType.CustomRange} label="Custom Range" />
            <Collapse in={selectedFilter === DateFilterType.CustomRange}>
              <FromToDayPickers
                fromLabel="From"
                fromOnChange={handleCustomRangeStartChange}
                toLabel="To"
                toOnChange={handleCustomRangeEndChange}
                selectedDates={filterDates}
                className="reportDateFilterPicker"
              />
            </Collapse>
          </RadioButtonGroup>
        </MenuSection>
      </ThemeProvider>
    );
  }
};

export default ReportDateFilters;
