import React from 'react';
import InstructionalVideoPopup from './ALInstructionVideoModal';
import { makeStyles } from '@material-ui/core/styles';
import { Fonts } from '../../helpers/constants';
import ExternalLink from '../ExternalLink';

const useStyles = makeStyles({
  banner: {
    border: '1px solid #0077D1',
    padding: '15px 10px 25px',
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 20,
  },

  caption: {
    marginBottom: 15,
    fontFamily: Fonts.SEMI_BOLD,
  },

  contactUs: {
    color: 'inherit !important',
    textDecoration: 'underline',
  },

  buttonCaption: {
    marginTop: 10,
    color: '#fff',
    zIndex: 1,
  },

  buttons: {
    display: 'flex',
    justifyContent: 'space-around',
  },

  video: {
    backgroundColor: '#000 !important',
    backgroundSize: '100% !important',
  },

  features: {
    backgroundColor: '#0077D1',
    textDecoration: 'none',
  },

  button: {
    width: 240,
    padding: '15px 0px 20px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    position: 'relative',
  },

  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.7)',
  },

  icon: {
    '&:before': {
      color: 'white',
      fontSize: 50,
    },
    zIndex: 1,
  },
});

interface Props {
  videoId?: string;
  caption?: string;
  premiumVideoThumbnail?: string;
  premiumIcon?: React.ReactNode;
  videoIcon?: React.ReactNode;
  videoEndCallback?: Function;
  configId?: string;
  className?: string;
};

export default function PremiumBanner({
  videoId = 'b7xdcm6c0i',
  caption = 'This feature is available on our Prime plan.',
  premiumVideoThumbnail = '/img/PRO_premium_video_thumbnail.png',
  premiumIcon,
  videoIcon,
  videoEndCallback,
  configId,
  className = '',
}: Props) {
  const [showingOverviewModal, setShowingOverviewModal] = React.useState(false);
  const classes = useStyles();

  return <div className={`premiumBanner ${classes.banner} ${className}`}>
    <div className={classes.caption}>
      {caption} <ExternalLink
        className={classes.contactUs}
        href="https://www.mheducation.com/prek-12/contact/contact-rep/contact-rep-supplemental.html?cid=web|mhse&utm_SEG_Product_Interest=Actively Learn.html">
          Contact us
        </ExternalLink> to learn more.
    </div>

    <div className={`${classes.buttons}`}>
      <a onClick={() => setShowingOverviewModal(true)}
        style={{ background: `url(${premiumVideoThumbnail})` }}
        className={`button ${classes.button} ${classes.video}`}>
        <span className={classes.overlay} />
        {videoIcon || <span className={`icon-video-play ${classes.icon}`} />}
        <span className={classes.buttonCaption}>Watch an overview</span>
      </a>

      <ExternalLink
        href="https://www.mheducation.com/prek-12/explore/actively-learn/plans.html"
        className={`button ${classes.button} ${classes.features}`}>
        {premiumIcon || <span className={`icon-premium-features ${classes.icon}`} />}
        <span className={classes.buttonCaption}>See plan features</span>
      </ExternalLink>
    </div>
    {showingOverviewModal &&
      <InstructionalVideoPopup
        title="Actively Learn Prime Features"
        videoId={videoId}
        introText="This video will introduce some of the features on our Prime plan that can help you and your school achieve your reading goals."
        hideModal={() => setShowingOverviewModal(false)}
        cssClass="modal-al-instructional-video"
        maxWidth="lg"
        videoEndCallback={videoEndCallback}
        configId={configId}
        autoPlay
      />
    }
  </div>;
};
