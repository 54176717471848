import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  divider: {
    borderBottom: '1px solid var(--divider-color, #C7C7C7)',
    width: '100%',
    margin: '15px 0',
    display: 'block',
  }
});

interface DividerProps {
  className?: string,
}

const Divider: React.FunctionComponent<DividerProps> = (props) => {
  const { className } = props;
  const styles = useStyles();

  return <div className={classnames(styles.divider, className)}></div>
}

export default Divider;
