import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { FormControlLabel, RadioGroup, RadioGroupProps } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#0077d1',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  checked: {
    backgroundColor: '#0077d1',
  },
});

const useRadioButtonBoxStyles = makeStyles({
  root: {
    height: 1,
    width: 1,
    position: 'absolute',
    opacity: 0,

    '& + span': {
      border: '1px solid #0077d1',
      borderBottom: 0,
      textAlign: 'center',
      padding: '8px 25px',
      width: '100%',
    },
  },
  checked: {
    '& + span': {
      backgroundColor: '#0077d1',
      color: '#fff',
    },
  },
});

const useFormControlLabelStyles = makeStyles({
  root: {
    margin: 0,

    '&:last-of-type span': {
      borderBottom: '1px solid #0077d1'
    }
  }
});

const useRadioButtonGroupStyles = makeStyles({
  root: {
    marginTop: 10,
  },
});

interface RadioButtonBoxProps extends RadioProps {
  label: string;
}

export function RadioButtonBox(props: RadioButtonBoxProps) {
  const { value, label } = props;
  const classes = useRadioButtonBoxStyles();
  const labelClasses = useFormControlLabelStyles();

  return (
    <FormControlLabel
      classes={labelClasses}
      value={value}
      control={
        <Radio
          classes={classes}
          disableRipple
          color='default'
          checkedIcon={null}
          icon={null}
          {...props}
        />
      }
      label={label}
    />
  );
}

export function RadioButtonGroup(props: RadioGroupProps) {
  const { children, ...rest } = props;
  const classes = useRadioButtonGroupStyles();

  return (
    <RadioGroup
      classes={classes}
      {...rest}
    >
      {children}
    </RadioGroup>
  );
}

export default function RadioButton(props: RadioProps) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color='default'
      checkedIcon={<span className={classnames(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}
