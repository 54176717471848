import dayjs, { Dayjs } from 'dayjs';

export enum DateFilterType {
  CurrentSchoolYear = 'currentSchoolYear',
  LastSchoolYear = 'lastSchoolYear',
  CustomRange = 'customRange',
  Last30Days = 'Last 30 Days',
  Last7Days = 'Last 7 Days'
};

export const getDatesAsObj = (start: string, end: string) => {
  return {
    start: dayjs(start),
    end: dayjs(end).endOf('day'),
  };
};

export const getCurrentSchoolYearDatesAsObj = () => {
  // if today is in August or later -> current school year is current calendar year
  // if today is before August -> current school year is last calendar year
  const currentSchoolYear = dayjs().month() + 1 >= 8
    ? dayjs().year()
    : dayjs().year() - 1;

  return {
    start: dayjs(new Date(`August 1, ${currentSchoolYear}`)).startOf('day'),
    end: dayjs().endOf('day'),
  };
};

export const getLastSchoolYearDatesAsObj = () => {
  // if today's date is Jul or before, the previous school year started 2 years ago
  // if it's later than Jul, the previous school year started 1 year ago
  const lastSchoolYear = dayjs().year() - (dayjs().month() < 7 ? 2 : 1);
  const lastSchoolYearStart = dayjs().year(lastSchoolYear).month(7).startOf('month');
  const lastSchoolYearEnd = dayjs().year(lastSchoolYear + 1).month(6).endOf('month');

  return {
    start: lastSchoolYearStart.startOf('day'),
    end: lastSchoolYearEnd.endOf('day'),
  };
};

export const getLast7DaysDatesAsObj = () => {
  return {
    start: dayjs().subtract(6, 'day').startOf('day'),
    end: dayjs().endOf('day'),
  };
};

export const getLast30DaysAsObj = () => {
  // use prior 2 week's Saturday if it's Sun-Fri at the moment
  return {
    start: dayjs().subtract(29, 'day').startOf('day'),
    end: dayjs().endOf('day'),
  };
};

export const getFilterType = (start: Dayjs, end: Dayjs): DateFilterType => {
  const currentSchoolYear = getCurrentSchoolYearDatesAsObj();
  const lastSchoolYear = getLastSchoolYearDatesAsObj();
  const last7Days = getLast7DaysDatesAsObj();
  const last30Days = getLast30DaysAsObj();

  if (currentSchoolYear.start.diff(start, 'day') === 0 && currentSchoolYear.end.diff(end, 'day') === 0) {
    return DateFilterType.CurrentSchoolYear;
  }
  else if (lastSchoolYear.start.diff(start, 'day') === 0 && lastSchoolYear.end.diff(end, 'day') === 0) {
    return DateFilterType.LastSchoolYear;
  }
  else if (last7Days.start.diff(start, 'day') === 0 && last7Days.end.diff(end, 'day') === 0) {
    return DateFilterType.Last7Days;
  }
  else if (last30Days.start.diff(start, 'day') === 0 && last30Days.end.diff(end, 'day') === 0) {
    return DateFilterType.Last30Days;
  }
  else {
    return DateFilterType.CustomRange;
  }
};