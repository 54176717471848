import React from 'react';
import classnames from 'classnames';
import styles from './styles.module.css';

interface IProps {
  additionalStyles?: string;
  theme?: 'light' | 'dark';
  iconSize?: 'small' | 'large';
  iconLabel?: string;
  orangeType?: 'empty' | 'filled';
  size?: 'small';
  type?: 'button' | 'submit';
  disabled?: boolean;
  selected?: boolean;
  onClick?: any;
  children?: any;
}

const Button = (props: IProps): JSX.Element => {
  const { theme = '', iconLabel, iconSize = '', orangeType, size = '', additionalStyles = '', children } = props;
  const isSmallIcon = iconSize === 'small';
  const isLargeIcon = iconSize === 'large';
  const classNames = classnames({
    [styles.button]: true,
    [styles[theme]]: theme,
    [styles[iconSize + 'Icon']]: iconSize,
    [styles[orangeType + 'Orange']]: orangeType,
    [styles[size]]: size,
    [additionalStyles]: additionalStyles,
    'px-lg': !iconLabel && isSmallIcon,
    'px-xl': !iconLabel && isLargeIcon,
    'px-null font-xs': iconLabel,
    'py-lg': isSmallIcon,
    'py-xl': isLargeIcon,
    [styles.textTransformNone]: iconLabel,
  });

  const buttonProps = { ...props };
  delete buttonProps.orangeType;
  delete buttonProps.iconSize;
  delete buttonProps.theme;
  delete buttonProps.additionalStyles;

  return (
    <button
      className={classNames}
      {...buttonProps}
    >
      <div className="flexCol">
        {iconLabel}
        {children}
      </div>
    </button>
  );
};

export default Button;
