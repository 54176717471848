import React from 'react';
import classnames from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ChartHeader from './ChartHeader';
import HorizontalBarChart from './HorizontalBarChart';
import LineChart from './LineChart';
import PieChart from './PieChart';
import { LegendProps } from './Legend';

export enum ChartTypes {
  Line = 'line',
  Pie = 'pie',
  HorizontalBar = 'horizontalBar'
}

interface IProps {
  className?: string;
  column?: boolean;
  config: any;
  title: string;
  variant?: ChartTypes;
  subtitle?: string;
  header?: boolean;
  legend?: LegendProps;
  legendTooltip?: JSX.Element;
  tooltip?: JSX.Element;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '35px',
    overflow: 'hidden',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  title: {
    marginBottom: '15px'
  },
  legend: {
    marginTop: '10px'
  },
  column: {
    flexDirection: 'column'
  },
  row: {

  },
  chartSection: {
    flex: '3 0',
    width: '100%'
  }
}));

const ALChart = ({
  header,
  title,
  subtitle,
  legend,
  legendTooltip,
  tooltip,
  config,
  variant,
  column = true
}: IProps) => {
  const styles = useStyles();

  const renderChart = () => {
    switch (variant) {
      case 'line':
        return <LineChart config={config} />;
      case 'horizontalBar':
        return <HorizontalBarChart config={config} />;
      case 'pie':
      default:
        return <PieChart config={config} />
    }
  }

  return (
    <div className={classnames(styles.root, column ? styles.column : styles.row)}>
      {header &&
        <ChartHeader
          column={!column}
          title={title}
          subtitle={subtitle}
          legend={legend}
          legendTooltip={legendTooltip}
          tooltip={tooltip}
        />
      }
      <div className={styles.chartSection}>
        {renderChart()}
      </div>
    </div>
  );
}

export default ALChart;
