import React from 'react';
import { FaLevelUpAlt } from 'react-icons/fa';
import styles from './styles.module.css';

export enum LabelType {
  ASSIGNED = 'ASSIGNED',
  PREREQ = 'PREREQ',
  LEVELSET = 'LEVELSET',
};

export const getLabelFromAssignment = (assignment: any) => {
  let label = LabelType.ASSIGNED;
  if (assignment.createdFromId) {
    label = LabelType.PREREQ;
  }
  else if (assignment.isBenchmark) {
    label = LabelType.LEVELSET;
  }

  return label;
}

const colors = {
  [LabelType.ASSIGNED]: '#5e89b0',
  [LabelType.PREREQ]: '#786482',
  [LabelType.LEVELSET]: '#c49832',
};

interface IProps {
  label: LabelType;
}

const Label = (props: IProps) => {
  const {
    label,
  } = props;

  return (
    <div className={styles.labelContainer}>
      {label === LabelType.PREREQ &&
        <FaLevelUpAlt style={{
          fontSize: 16,
          transform: 'scale(-1, 1)',
          marginRight: 8,
        }}
        />
      }
      <div className={styles.label} style={{ backgroundColor: colors[label] }}>{label}</div>
    </div>
  );
};

export default Label;
