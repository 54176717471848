import React from 'react';
import classnames from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles'

interface IProps {
  canPreviousPage: boolean,
  canNextPage: boolean,
  gotoPage: any,
  pageCount: number,
  nextPage: any,
  previousPage: any,
  state: any,
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 15,
  },
  pageButton: {
    backgroundColor: 'transparent',
    border: 0,
    borderRadius: '50%',
    color: '#a1a1a1',
    cursor: 'pointer',
  },
  pageButtonWithNumber: {
    height: 30,
    width: 30,
  },
  pageButtonWithEllipsis: {
    width: 'auto'
  },
  active: {
    backgroundColor: '#0077d1',
    color: '#fff'
  }
}));

const filterPages = (visiblePages, totalPages) => {
  return visiblePages.filter(page => page <= totalPages);
};

const getVisiblePages = (page, total) => {
  if (total < 7) {
    return filterPages([1, 2, 3, 4, 5, 6], total);
  }
  else {
    if (page % 5 >= 0 && page > 4 && page + 2 < total) {
      return [1, page - 1, page, page + 1, total];
    }
    else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
      return [1, total - 3, total - 2, total - 1, total];
    }
    else {
      return [1, 2, 3, 4, 5, total];
    }
  }
};

const Pagination: React.FunctionComponent<IProps> = (props) => {
  const {
    canPreviousPage,
    canNextPage,
    gotoPage,
    pageCount,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = props;
  const classes = useStyles();

  const [visiblePages, setVisiblePages] = React.useState(getVisiblePages(1, pageCount));

  React.useEffect(() => {
    setVisiblePages(getVisiblePages(pageIndex, pageCount))
  }, [pageIndex, pageCount]);

  return (
    <div className={classes.root}>
      <button className={classes.pageButton} onClick={() => previousPage()} disabled={!canPreviousPage}>
        {'<'}
      </button>
      {visiblePages.map((page, index, array) => {
        return (
          <button
            key={page}
            className={
              pageIndex + 1 === page
                ? classnames(classes.pageButton, classes.pageButtonWithNumber, classes.active)
                : classnames(classes.pageButton, classes.pageButtonWithNumber, array[index - 1] + 2 < page ? classes.pageButtonWithEllipsis : '')
            }
            onClick={() => gotoPage(page - 1)}
          >
            {array[index - 1] + 2 < page ? `... ${page}` : page}
          </button>
        );
      })}
      <button className={classes.pageButton} onClick={() => nextPage()} disabled={!canNextPage}>
        {'>'}
      </button>
    </div>
  );
}


export default Pagination;
