import React from 'react';
import { getDataPlain } from '../../../../actions/api';
import { closeModal } from '../../../../actions/eventEmitter';
import { Modal } from 'sharedfrontend';

class LearnosityQuestionEditorModal extends React.Component<any, any> {
  authorApp: any;
  learnosityQuestionEditorSignedRequest: any;

  async componentDidMount() {
    this.learnosityQuestionEditorSignedRequest = await getDataPlain({
      type: 'LEARNOSITY_EDITOR_SIGNED_REQUEST',
    });

    this.renderQuestion();
  }

  renderQuestion = () => {
    this.authorApp = (window as any).LearnosityAuthor.init(this.learnosityQuestionEditorSignedRequest, {
      errorListener: (e: Error) => {
        rg4js('send', { error: e, tags: ['LearnosityAuthor.init'] });
      },
      readyListener: () => {
        this.authorApp
          .on('render:item', () => {
            this.authorApp.setWidget(this.props.learnosityJson.questions[0]);
          });
      },
    });
  }

  saveAndClose = () => {
    this.authorApp.destroy();
    this.props.saveQuestionJsonEdits(this.props.id, this.authorApp.getWidget());
    closeModal();
  }

  render() {
    return (
      <Modal
        title=""
        maxWidth="xl"
        okText="Save and close"
        okCallback={this.saveAndClose}
        cancelText="Cancel"
        cancelCallback={closeModal}
        disableEnforceFocus={true}
      >
        <div id="learnosity-author"></div>
      </Modal>
    );
  }
};

export default LearnosityQuestionEditorModal;
