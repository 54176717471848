import React, { useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ReactDOM from 'react-dom';
import { Fonts } from '../../helpers/constants';

enum DURATIONS {
  short = 3000,
  medium = 4000,
  long = 6000,
};

const useStyles = makeStyles({
  root: {
    top: 0,
    '&.alert-error div': {
      backgroundColor: '#E80062',
    },
    '&.alert-warn div': {
      backgroundColor: '#D23D0F',
    },
    '&.alert-info div': {
      backgroundColor: '#0077D1',
    },
    '&.alert-success div': {
      backgroundColor: '#2E7D32',
    }
  },
});

const useButtonStyles = makeStyles({
  root: {
    marginTop: 4,
    height: '1em',
    color: 'white',
    padding: '0 5px',
  },
});

const useContentStyles = makeStyles({
  root: {
    flexWrap: 'nowrap',
    fontFamily: Fonts.MEDIUM,
  },
  message: {
    fontSize: 16,
  },
  action: {
    alignSelf: 'flex-start',
  }
})

export interface AlertProps {
  message: string | React.ReactNode;
  type?: 'error' | 'warn' | 'info' | 'success';
  duration?: 'short' | 'medium' | 'long';
  autoClose?: boolean;
  closable?: boolean;
  onClose: Function;
  parent?: string;
  resetFocus?: boolean;
}

export function Alert({
  message,
  type = 'info',
  duration = 'short',
  autoClose = true,
  closable = true,
  onClose,
  resetFocus = true,
}: AlertProps) {
  const styles = useStyles();
  const contentStyles = useContentStyles();
  const buttonStyles = useButtonStyles();

  useEffect(() => {
    const activeElement: any = document.activeElement;
    const elem = document.getElementById("closeAlertButton");

    if (elem) {
      elem.focus();
    }

    return () => {
      activeElement.focus();
    };
  }, [autoClose]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    if (resetFocus) {
      const el = document.getElementById('pageActions');
      el?.querySelector('div')?.querySelector('button')?.focus();
    }

    onClose();
  };

  return (
    <Snackbar
      open={true}
      autoHideDuration={autoClose ? DURATIONS[duration] : null}
      onClose={handleClose}
      classes={styles}
      className={'alert-' + type}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      ContentProps={{ classes: contentStyles }}
      message={message}
      action={
        closable && !autoClose && (
          <IconButton
            color="secondary"
            id="closeAlertButton"
            aria-label="Close"
            classes={buttonStyles}
            onClick={(ev) => handleClose(ev, "")}
          >
            &times;
          </IconButton>
        )
      }
    />
  );
}

export async function showAlert(props: Partial<AlertProps>) {
  const element = props.parent === 'modal'
    ? document.getElementById('alertContainerForModal')
    : document.getElementById('alertContainer');

  if (element) {
    await ReactDOM.unmountComponentAtNode(element);
    element.parentElement?.removeAttribute('aria-hidden');
    ReactDOM.render(<Alert message={props.message} {...props} onClose={() => {
      if (props.onClose) {
        props.onClose();
      }

      hideAlert();
    }} />, element);
  }
}

export function hideAlert() {
  const element = document.getElementById('alertContainer');
  const alertContainerForModal = document.getElementById('alertContainerForModal');

  if (element) {
    ReactDOM.unmountComponentAtNode(element);
  }

  if (alertContainerForModal) {
    ReactDOM.unmountComponentAtNode(alertContainerForModal);
  }
}

export function AlertContainer() {
  return <div id="alertContainer" aria-live="polite" />
}

export function AlertContainerForModal() {
  return <div id="alertContainerForModal" aria-live="polite" />
}