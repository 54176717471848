import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  label: {
    display: 'inline-block',
    padding: '2px 6px',
    fontSize: 12,
    borderRadius: 10,
    background: '#eee',
    color: 'rgba(0, 0, 0, 0.38)',
    whiteSpace: 'nowrap',
  }
});

const MathAssignmentLabel = (props: any): JSX.Element => {
  const styles = useStyles();
  return (
    <div>
      <span className={styles.label}>
        {props.label}
      </span>
    </div>
  );
};

export default MathAssignmentLabel;