import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Tabs, { TabsProps } from '@material-ui/core/Tabs';

const useStyles = makeStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    display: 'none',
  },
})

const ChartTabs = ((props: TabsProps) => {
  const styles = useStyles();

  return <Tabs classes={styles} {...props} />
});


export default ChartTabs;
