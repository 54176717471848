import React from 'react';
import { Checkbox } from '../inputs';
import ExternalLink from '../ExternalLink';

interface Props {
  checked: boolean;
  setChecked: Function;
  termsLink?: string;
  label?: string;
}

function CopyrightReminderCheckbox({ 
  checked,
  setChecked,
  termsLink = 'https://www.activelylearn.com/terms',
  label = '',
}: Props) {
  const checkboxLabel: any = label || (
    <p>
      Please make sure your submission complies with our{' '}
      <ExternalLink
        href={termsLink}
        className="infoLink">Terms of Service</ExternalLink>.
    </p>
  );

  return (
    <Checkbox
      label={checkboxLabel}
      checked={checked}
      setChecked={setChecked} />
  );
}

export default CopyrightReminderCheckbox;