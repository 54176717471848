import CompletionProgressBar from './component';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
  const { isBenchmark, problemIds, completedProblemIds } = state.problemSetData;

  return {
    denominator: isBenchmark ? problemIds.length : 5,
    numProblemsCompleted: completedProblemIds.length,
    role: state.userData.role,
    isIntervention: state.problemSetData.isIntervention,
  }
}

export default connect(
  mapStateToProps,
)(CompletionProgressBar);
