import Step from './component';
import { connect } from 'react-redux';
import { getData } from '../../../actions/api';
import { Role } from '../../../utils/constants';

const mapStateToProps = (state: any) => {
  return {
    hasEntityLoaded: state.readerNavigation.hasEntityLoaded,
    hasEntityRendered: state.readerNavigation.hasEntityRendered,
    stepId: state.readerNavigation.currentStepId,
    problemId: state.readerNavigation.currentProblemId,
    classId: state.problemSetData.classId,
    problemSetId: state.problemSetData.problemSetId,
    studentUserId: state.readerNavigation.studentUserId,
    interventionId: state.problemSetData.interventionId,
    hasProblemDataLoaded: !!state.problemData.learnosityJson.consumer_key,
    responseExists: state.gradeFeedbackData.feedbackMessage && state.userData.role === Role.STUDENT,
    showRuler: state.stepData.showRuler,
    showProtractor: state.stepData.showProtractor,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    fetchData: ({
      stepId, problemSetId, classId, problemId, hasProblemDataLoaded, studentUserId,
    }: {
      stepId: number;
      problemSetId: number;
      classId: number;
      problemId: number;
      hasProblemDataLoaded: boolean;
      studentUserId: number;
    }) => {
      if (hasProblemDataLoaded) {
        dispatch(getData({ 
          type: 'GET_STEP_DATA',
          params: { stepId, problemSetId, classId, studentUserId },
        }));
      }
      else {
        dispatch(getData({
          type: 'PROBLEM_DATA_FOR_STEP',
          params: { problemId, problemSetId, classId, studentUserId },
        }));
      }
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Step);
