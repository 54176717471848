import produce from 'immer';

const initialState = {
  benchmarkData: [],
};

const reducer: any = produce((draft, action: any) => {
  switch (action.type) {
  case 'RESOLVE_ADMIN_REPORT_QUANTILE_BENCHMARKS_DATA': {
    draft.benchmarkData = action.body.benchmarkResults
    break;
  }
  }
}, initialState);

export default reducer;
