import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from "@material-ui/core/styles";
import Checkbox from '../inputs/Checkbox';
import { ValidatorForm } from '../formValidator/FormValidator';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { Fonts } from '../../helpers/constants';
import classNames from 'classnames';
import ScrollableAreaWithShadow from '../scrollableAreaWithShadow';
import { AlertContainerForModal } from '../alert/Alert';
import OnDemandLiveRegion from 'on-demand-live-region';
import CloseIcon from '../../icons/cancel.svg';
import PinFilled from '../../icons/pinfilled.svg';
import Pin from '../../icons/pin.svg';
import { Button as CustomButton } from '../button';

const styles = () => {
  return {
    root: {
      display: 'block',
    },
    needsReloadNote: {
      fontFamily: 'var(--font-med)',
      fontSize: '14px',
      color: 'var(--label-text-color)',
      flexGrow: '1',
      marginLeft: '18px',
    },
    mdxMaxWidth: {
      '&.MuiDialog-paperWidthMd': {
        maxWidth: '850px !important',
      },
    },
    paper: {
      fontFamily: Fonts.REGULAR,
      fontSize: 16,
      minWidth: Math.min(window.innerWidth * 0.9, 600),
      overflowY: 'unset',
      margin: 30,

      '&.MuiDialog-paperWidthMd': {
        maxWidth: 730,
      },

      '&.MuiDialog-paperWidthLg': {
        maxWidth: 1000,
      },

      '& select': {
        fontFamily: Fonts.REGULAR,
        color: 'unset !important',
        fontSize: 'inherit',
        padding: 0,
      },

      '& input': {
        boxSizing: 'unset',
      },

      '& .MuiButtonBase-root input[type="checkbox"]': {
        opacity: '0',
      },

      '& #chkDontShow': {
        width: '100% !important',
      },

      '& .dontShow': {
        fontFamily: Fonts.MEDIUM,
        color: 'inherit',
        marginLeft: -15,
        marginTop: 10,
        fontSize: 14,
      },

      '& .MuiDialogContent-root:first-child': {
        paddingTop: 0,
      },

      ['@media(max-width: 600px)']: {
        minWidth: '90%'
      },

      ['@media(max-width: 350px)']: {
        minWidth: '95%'
      }
    },
    dialogContent: {
      padding: '8px 24px !important',
      margin: '0 !important',
      overflowY: 'auto',

      ['@media(max-width: 600px)']: {
        padding: '8px 15px !important',
      },

      ['@media(max-width: 350px)']: {
        padding: '2px 15px !important',
      }
    },
    dialogContainer: {
      alignItems: 'baseline',
      '& .closeButton': {
        position: 'absolute',
        top: '10px',
        right: '5px',
        padding: '16px',
        minWidth: 'auto',
        zIndex: 1,
        lineHeight: 1,
        '& svg': {
          color: 'var(--black_c)',
          fill: 'var(--black_c)',
          width: '15px',
          height: '15px'
        },
        '&:focus': {
          outline: '5px auto -webkit-focus-ring-color',
          outlineOffset: '-2px'
        },
        ['@media(max-width: 350px)']: {
          right: 0,
          padding: '12px',
          top: '5px'
        }
      },
      '& .pinButton': {
        right: '35px',
      },
      '& #dialog-title': {
        '&.dialogHeader': {
          '& h2': {
            ['@media(max-width: 600px)']: {
              margin: '10px 0 !important'
            }
          }
        }
      }
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      border: 'none',
      maxHeight: '100%',
      background: 'inherit',
      '& .okButton:focus, & .cancelButton:focus': {
        outline: '5px auto -webkit-focus-ring-color'
      }
    },
    overflowHidden: {
      overflow: 'hidden',
    },
    overflowVisible: {
      overflow: 'visible !important',
    },
    overflowAuto: {
      overflow: 'auto',
    },
    header: {
      padding: '15px 40px 15px 24px',

      '& h2': {
        fontFamily: Fonts.SEMI_BOLD,
        fontSize: 22,
      },

      ['@media(max-width: 600px)']: {
        padding: '15px 15px 0 15px',
      },

      ['@media(max-width: 350px)']: {
        padding: '2px 20% 0 8px',
        height: 47,
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
        lineHeight: 1.13,

        '& h2': {
          fontSize: 18,
        },
      }
    },
    cancelButton: {
      whiteSpace: 'nowrap',
    },
  };
};

interface ModalProps extends Omit<DialogProps, 'maxWidth' | 'title' | 'open'> {
  cancelText?: string | null;
  cssClass?: string;
  children: React.ReactNode;
  title: string | React.ReactNode;
  shouldShowDoNotShowAgainContainer?: boolean;
  okText?: string;
  okCallback?: Function;
  cancelCallback?: Function;
  classes?: any;
  maxWidth?: 'xs' | 'sm' | 'md' | 'mdx' | 'lg' | 'xl' | false;
  actionButtons?: Array<{
    text: string;
    callback: any;
    classes?: string;
  }>;
  disabledOkText?: string;
  onOpenCallback?: Function;
  okSubmitEnabled?: boolean;
  customValidations?: {
    name: string;
    func: Function;
  }[];
  shouldOverflow?: boolean;
  onUpdateDoNotShowPopupSetting?: Function;
  id?: string;
  shouldShowShadowIfScrollable?: boolean;
  disableOkButton?: boolean;
  disableEscapeKeyDown?: boolean;
  setAriaLabelledBy?: boolean;
  titleText?: string;
  closeButton?: boolean;
  pinButton?: boolean;
  handlePinButtonClick?: Function;
  preventSubmitOnEnter?: boolean;
  ariaLabel?: string;
  headerMessage?: string | React.ReactNode;
  isSelectMenuOpen?: boolean;
  titleVisuallyHidden?: boolean;
  needsReload?: boolean;
}

class Modal extends React.Component<ModalProps, any> {
  static defaultProps = {
    cssClass: '',
    cancelText: 'Cancel',
    shouldShowDoNotShowAgainContainer: false,
    okText: 'Ok',
    actionButtons: [],
    disableOkButton: false,
    disableEscapeKeyDown: true,
    closeButton: true,
    pinButton: false,
    preventSubmitOnEnter: false,
    titleVisuallyHidden: false,
    needsReload: false,
    handlePinButtonClick: null,
  }

  state = {
    chkDoNotShowAgain: false,
    disableOkButton: false,
    okText: this.props.okText,
    open: true,
    isScrollable: false,
    activeElement: null,
    closeButton: this.props.closeButton,
    isPinned: false,
  }

  componentDidMount() {
    this.addValidationRules();

    if (this.props.disableOkButton) {
      this.setState({ disableOkButton: true });
    }
    document.addEventListener('keydown', this.handleKeyDown);

    setTimeout(() => {
      const selectedModal = document.querySelectorAll(".modal div[data-test^='sentinel'], .modal .modal-body");
      selectedModal?.forEach((elm) => {
        elm.removeAttribute('tabindex');
      });

      this.handleFocusOnModalCloseButton();
    }, 50);
  }

  componentDidUpdate(prevProps) {
    if (this.props.okText !== prevProps.okText) {
      this.setState({ okText: this.props.okText });
    }
    if (this.props.disableOkButton !== prevProps.disableOkButton) {
      this.setState({ disableOkButton: this.props.disableOkButton });
    }
    this.addValidationRules();
  }

  componentWillUnmount() {
    this.removeValidationRules();
    document.removeEventListener('keydown', this.handleKeyDown); 
  }

  handleKeyDown = (e) => {
    e.stopPropagation();
    const isTooltipVisible = document.body.getAttribute('oneOrMoreTooltip') === 'true';
    const dropdown = document.body.querySelector('[class="MuiPopover-root"]');
    
    if (e.key === 'Escape' && (!dropdown && !isTooltipVisible && !this.props.isSelectMenuOpen)) {
      if (this.props.cancelCallback) {
        this.props.cancelCallback(this);
      }
      else {
        this.hideModalWithTransition();
      }
    }
 
    const modalElement = document.querySelectorAll('.modal');
    const ActiveModalElement = modalElement?.[modalElement.length - 1];
    const focusableElements = ActiveModalElement?.querySelectorAll(
      'button:not([disabled]), input[type="checkbox"]:not([disabled]), ' +
      'textarea:not([disabled]), [tabindex]:not([tabindex="-1"])'
    );
    const firstElement: any = focusableElements?.[0];
    const lastElement: any = focusableElements?.[focusableElements.length - 1];
 
    if (e.key === 'Tab') {
      if (e.shiftKey && document.activeElement === firstElement) {
        e.preventDefault();
        lastElement?.focus();
      }
      else if (!e.shiftKey && document.activeElement === lastElement) {
        e.preventDefault();
        firstElement?.focus();
      }
    }
  };

  addValidationRules = () => {
    if (this.props.customValidations) {
      for (const customValidation of this.props.customValidations) {
        ValidatorForm.addValidationRule(customValidation.name, customValidation.func);
      }
    }
  }

  removeValidationRules = () => {
    if (this.props.customValidations) {
      for (const customValidation of this.props.customValidations) {
        ValidatorForm.removeValidationRule(customValidation.name);
      }
    }
  }

  handleClose = () => {
    if (this.state.chkDoNotShowAgain && this.props.onUpdateDoNotShowPopupSetting) {
      if (this.props.okText && this.props.cancelText) {
        this.setState({
          chkDoNotShowAgain: false,
        })
      }
      else {
        this.props.onUpdateDoNotShowPopupSetting();
      }
    }

    if (this.props.okCallback && this.props.okText === 'Close') {
      this.props.okCallback(this);
    }
    else if (this.props.cancelCallback) {
      this.props.cancelCallback(this);
    }
    else {
      this.hideModalWithTransition();
    }
  }

  handleOk = (ev) => {
    ev.stopPropagation();
    
    if (this.state.chkDoNotShowAgain && this.props.onUpdateDoNotShowPopupSetting) {
      this.props.onUpdateDoNotShowPopupSetting();
    }

    if (this.props.okCallback) {
      if(!this.props.okSubmitEnabled)
        this.disableOkButton(this.props.disabledOkText);
      this.props.okCallback(this);
    }
    else {
      this.hideModalWithTransition();
    }
  }

  hideModalWithTransition = () => {
    this.setState({ open: false });
  }

  handleFocusOnModalCloseButton = () => {
    const elm = document.querySelector<HTMLInputElement>('.modal:last-child .closeButton');
    const elm2 = document.querySelector<HTMLInputElement>('.modal:last-child .cancelButton');
    if (elm) {
      elm?.focus();
    } else if (elm2) {
      elm2?.focus();
    }
  }

  onEntered = () => {
    if (this.props.onOpenCallback) {
      this.props.onOpenCallback(this);
    }
  }

  disableOkButton = (okText) => {
    this.setState({ disableOkButton: true, okText: okText || this.props.okText });
  }

  enableOkButton = (okText) => {
    this.setState({ disableOkButton: false, okText: okText || this.props.okText });
  }

  handleDoNotShowAgain = () => {
    this.setState({ chkDoNotShowAgain: !this.state.chkDoNotShowAgain });
  }

  handleModalSubmit = (e) => {
    if (this.props.preventSubmitOnEnter && e.key === 'Enter' && !(e.target instanceof HTMLButtonElement)) {
      e.preventDefault();
    }
  }
  
  handleValidationErrors = (errors) => {
    const onDemandLiveRegion = new OnDemandLiveRegion({
      delay: 500
    });

    let errorElement: HTMLElement | null | undefined = document.getElementById(errors[0].props.id);

    if (errorElement) {
      if (errorElement.getAttribute("type") === "hidden") {
        errorElement = errorElement.parentElement?.querySelector(
          "[role='button']"
        );
      } else if (errorElement.tagName !== "INPUT") {
        errorElement = errorElement.querySelector("input");
      }

      if (errorElement) {
        setTimeout(() => {
          (errorElement as any).focus();
        }, 600);
      }
    }

    onDemandLiveRegion.say(errors[0].getErrorMessage());
  }

  render() {
    const {
      classes,
      cancelText,
      cssClass,
      children,
      title,
      shouldShowDoNotShowAgainContainer,
      okText,
      okCallback,
      cancelCallback,
      maxWidth,
      actionButtons,
      disabledOkText,
      onOpenCallback,
      customValidations,
      shouldOverflow,
      onUpdateDoNotShowPopupSetting,
      id,
      disableBackdropClick = true,
      shouldShowShadowIfScrollable = true,
      titleText,
      closeButton,
      pinButton,
      handlePinButtonClick,
      ariaLabel,
      titleVisuallyHidden,
      needsReload,
      ...rest
    } = this.props;

    return (
      <Dialog
        aria-labelledby={title ? `dialog-title${id || ''}` : undefined}
        open={this.state.open}
        onEntered={this.onEntered}
        onClose={this.handleClose}
        onKeyDown={this.handleModalSubmit}
        TransitionComponent={Transition}
        maxWidth={maxWidth === 'mdx' ? 'md' : maxWidth}
        PaperProps={{
          'aria-modal': true,
          'aria-label': ariaLabel
        }}
        classes={{
          root: `${classes.root} ${shouldOverflow ? classes.overflowAuto : ''}`,
          paper: `${classes.paper} ${cssClass} ${maxWidth === 'mdx' ? classes.mdxMaxWidth : ''}`,
          container: `${classes.dialogContainer}`,
        }}
        className="modal"
        id={id}
        disableBackdropClick={disableBackdropClick}
        disableAutoFocus
        disableEnforceFocus 
        {...rest}
      >
        {!!title && (
          <DialogTitle
            id={`dialog-title${id || ""}`}
            className={
              `${closeButton
                ? `${classes.header} header-with-close-icon dialogHeader`
                : `${classes.header} dialogHeader`}
              ${titleVisuallyHidden ? 'visuallyHidden screen-reader-only' : ''}`
            }
            disableTypography
          >
            <h2 title={typeof title === 'string' ? title : ''}>{title}</h2>
           {rest.headerMessage && <>{rest.headerMessage}</>}
          </DialogTitle>
        )}
        {!!pinButton && !!handlePinButtonClick && (
          <CustomButton
            onClick={() => {
              this.setState({
                isPinned: !this.state.isPinned
              });

              handlePinButtonClick(!this.state.isPinned);
            }}
            className="btn closeButton pinButton"
            title={this.state.isPinned ? 'Unpin' : 'Pin'}>
            {this.state.isPinned ? <PinFilled /> : <Pin />}
          </CustomButton>
        )}
        {!!closeButton && (
          <CustomButton
            onClick={this.handleClose}
            className={["btn", "closeButton", classes.cancelButton].join(" ")}
            aria-label="Close"
            title="Close"
            aria-describedby={null}
            autoFocus
          >
            <CloseIcon />
          </CustomButton>
        )}
        <ValidatorForm
          className={classNames(classes.form, 'modal-content', { [classes.overflowHidden]: !shouldOverflow })}
          onError={this.handleValidationErrors}
          onSubmit={this.handleOk}
          margin="dense">
          {!!children &&
            <ScrollableAreaWithShadow 
              Tag={DialogContent}
              role="presentation"
              className={classNames(classes.dialogContent, 'modal-body', {
                [classes.scrollable]: this.state.isScrollable,
                [classes.overflowVisible]: shouldOverflow,
              })}
              shouldShowShadow={!shouldOverflow && shouldShowShadowIfScrollable}
            >
              {children}
              <DoNotShowAgainContainer
                shouldShowDoNotShowAgainContainer={shouldShowDoNotShowAgainContainer}
                chkDoNotShowAgain={this.state.chkDoNotShowAgain}
                handleDoNotShowAgain={this.handleDoNotShowAgain}
              />
            </ScrollableAreaWithShadow>
          }
          <AlertContainerForModal />
          <DialogActions>
            {needsReload &&
              <div className={classes.needsReloadNote} id="actionReloadNote">
                <p>Note: Actively Learn will reload to reflect the changes.</p>
              </div>
            }
            {!!cancelText &&
              <Button
                role="button"
                onClick={this.handleClose}
                className={['btn', 'btn-primary', 'cancelButton', classes.cancelButton].join(' ')}
                color="primary"
                disableRipple
              >
                {cancelText}
              </Button>
            }
            {actionButtons && actionButtons.map((a, idx) => (
              <Button
                color="primary"
                role="button"
                onClick={a.callback}
                className={['btn', 'btn-primary', 'actionButton', a.classes || ''].join(' ')}
                key={idx}
              >
                {a.text}
              </Button>
            ))}
            {this.state.okText &&
              <Button
                role="button"
                className={['btn', 'btn-primary', 'okButton'].join(' ')}
                color="primary"
                type="submit"
                disabled={this.state.disableOkButton}
                disableFocusRipple
                aria-describedby={needsReload ? "actionReloadNote" : undefined}
              >
                {this.state.okText}
              </Button>
            }
          </DialogActions>
        </ValidatorForm>
      </Dialog>
    );
  }
};

const DoNotShowAgainContainer = ({ shouldShowDoNotShowAgainContainer, handleDoNotShowAgain, chkDoNotShowAgain }) => {
  return shouldShowDoNotShowAgainContainer &&
    <Checkbox
      checked={chkDoNotShowAgain}
      setChecked={handleDoNotShowAgain}
      label="Do not show this message again"
      id="chkDontShow"
    />;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" timeout={150} ref={ref} {...props} />;
});

// @ts-ignore
export default withStyles(styles)(Modal) as React.ComponentClass<ModalProps, any>;