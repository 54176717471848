import React from 'react';
import config from '../config';

class RedirectToAL extends React.PureComponent<any, any> {
  componentDidMount() {
    window.location.assign(`${config.alFrontendUrl}`);
  }

  render() {
    return <main>Redirecting...</main>;
  }
};

export default RedirectToAL;
