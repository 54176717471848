import React from 'react';
import bowser from 'bowser';
import { Button } from '../../button';
import AddExternalCourses from './AddExternalCourses';
import AddExternalCoursesMultiTerm from './AddExternalCoursesMultiTerm';
import { UserAccountSource } from '../../../helpers/constants';
import styles from './externalClassImportButton.module.css';
import achieve3000Logo from '../../../icons/Achieve3000Logo128.png';
import schoologyLogo from '../../../icons/Schoology.png';
import edmodoLogo from '../../../icons/Edmodo.png';
import cleverLogo from '../../../icons/Clever.png';
import brightspaceLogo from '../../../icons/Brightspace.png';
import canvasLogo from '../../../icons/Canvas.png';
import { showAlert } from '../../alert';

export default function ExternalClassImportButtons({
  onGradeLevelUpdated: handleGradeLevelChange,
  googleImportHelper,
  edmodoImportHelper,
  cleverImportHelper,
  canvasImportHelper,
  brightspaceImportHelper,
  oneRosterImportHelper,
  schoologyImportHelper,
  achieve3KImportHelper,
  schoolInfo,
  hideModal,
  showModal,
  closeParentModal,
}) {
  function onGradeLevelUpdated(data) {
    if (handleGradeLevelChange) {
      handleGradeLevelChange(data);
      showAlert({
        message: 'Grade level updated successfully!'
      })
    }
  }

  async function openExternalClassImportModal(source) {
    closeParentModal();

    if (source === UserAccountSource.EDMODO) {
      const courses = await edmodoImportHelper.getCourses();
      showModal(
        <AddExternalCourses
          title='Import Edmodo Groups'
          cssClass='modal-al-import-classes-from-edmodo'
          courses={courses}
          schoolInfo={schoolInfo}
          apiCallback={edmodoImportHelper.apiCallback}
          source={source}
          cancelCallback={hideModal}
          infoText='Select the Edmodo groups you want to import. Note: Importing classes that you co-teach is not yet supported.'
        />
      );
    }
    else if (source === UserAccountSource.GOOGLE) {
      const courses = await googleImportHelper.getGoogleCourses();
      showModal(
        <AddExternalCourses
          title='Import Google Classroom courses'
          cssClass='modal-al-import-classes-from-google-classroom'
          courses={courses}
          schoolInfo={schoolInfo}
          apiCallback={googleImportHelper.apiCallback}
          onGradeLevelUpdated={onGradeLevelUpdated}
          source={source}
          cancelCallback={hideModal}
          infoText='Select the Google Classroom courses and their grade levels you want to import.'
        />
      );
    }
    else if (source === UserAccountSource.CLEVER) {
      const sections = await cleverImportHelper.getCourses();

      showModal(
        <AddExternalCourses
          title='Import Clever sections'
          cssClass='modal-al-import-classes-from-clever'
          courses={sections}
          schoolInfo={schoolInfo}
          apiCallback={cleverImportHelper.apiCallback}
          source={source}
          cancelCallback={hideModal}
          infoText='Select the Clever sections you want to import.'
          errorMessage={cleverImportHelper.getErrorMessage()}
        />
      );
    }
    else if (source === UserAccountSource.CANVAS) {
      const courses = await canvasImportHelper.getCourses();

      showModal(
        <AddExternalCoursesMultiTerm
          title='Import Canvas sections'
          cssClass='modal-al-import-classes-from-canvas'
          courses={courses}
          schoolInfo={schoolInfo}
          apiCallback={canvasImportHelper.apiCallback}
          onGradeLevelUpdated={onGradeLevelUpdated}
          source={source}
          cancelCallback={hideModal}
          infoText='Note: Only published sections of your Canvas courses will appear here.'
        />
      );
    }
    else if (source === UserAccountSource.BRIGHTSPACE) {
      const courses = await brightspaceImportHelper.getCourses();

      showModal(
        <AddExternalCoursesMultiTerm
          title='Import Brightspace sections'
          cssClass='modal-al-import-classes-from-brightspace'
          courses={courses}
          schoolInfo={schoolInfo}
          apiCallback={brightspaceImportHelper.apiCallback}
          onGradeLevelUpdated={onGradeLevelUpdated}
          source={source}
          cancelCallback={hideModal}
          infoText='Note: Only published courses and sections in Brightspace in which you are a teacher will appear.'
        />
      );
    }
    else if (source === UserAccountSource.SCHOOLOGY) {
      const courses = await schoologyImportHelper.getCourses();

      showModal(
        <AddExternalCoursesMultiTerm
          title='Import Schoology sections'
          cssClass='modal-al-import-classes-from-schoology'
          courses={courses}
          schoolInfo={schoolInfo}
          apiCallback={schoologyImportHelper.apiCallback}
          onGradeLevelUpdated={onGradeLevelUpdated}
          source={source}
          cancelCallback={hideModal}
          infoText={`Note: Only active 'grading periods' sections in Schoology in which you are a teacher will appear.`}
        />
      );
    }
    else if (source === UserAccountSource.ONEROSTER) {
      const courses = await oneRosterImportHelper.getCourses();

      showModal(
        <AddExternalCoursesMultiTerm
          title='Import classes from my district SIS'
          cssClass='modal-al-import-classes-from-oneroster'
          courses={courses}
          schoolInfo={schoolInfo}
          apiCallback={oneRosterImportHelper.apiCallback}
          onGradeLevelUpdated={onGradeLevelUpdated}
          source={source}
          cancelCallback={hideModal}
          infoText={`Note: Only active classes in your district's SIS in which you are a teacher will appear.`}
        />
      );
    }
    else if (source === UserAccountSource.ACHIEVE3K) {
      const courses = await achieve3KImportHelper.getCourses();

      showModal(
        <AddExternalCoursesMultiTerm
          title='Import Achieve3000 sections'
          cssClass='modal-al-import-classes-from-achieve3k'
          courses={courses}
          schoolInfo={schoolInfo}
          apiCallback={achieve3KImportHelper.apiCallback}
          onGradeLevelUpdated={onGradeLevelUpdated}
          cancelCallback={hideModal}
          source={source}
        />
      );
    }
  }

  return (
    <React.Fragment>
      {googleImportHelper && (
        <ExternalClassImportButton
          iconUrl="https://www.gstatic.com/classroom/logo_square_48.svg"
          btnClass="btnGoogleCourses"
          importText="courses from Google Classroom"
          onClick={() => openExternalClassImportModal(UserAccountSource.GOOGLE)} />
      )}
      {edmodoImportHelper &&
        <ExternalClassImportButton
          iconUrl={edmodoLogo}
          btnClass="btnEdmodoGroups"
          importText="groups from Edmodo"
          onClick={() => openExternalClassImportModal(UserAccountSource.EDMODO)} />
      }
      {cleverImportHelper &&
        <ExternalClassImportButton
          iconUrl={cleverLogo}
          btnClass="btnCleverSections"
          importText="sections from Clever"
          onClick={() => openExternalClassImportModal(UserAccountSource.CLEVER)} />
      }
      {canvasImportHelper &&
        <ExternalClassImportButton
          iconUrl={canvasLogo}
          btnClass="btnCanvasSections"
          importText="canvas sections as classes"
          onClick={() => openExternalClassImportModal(UserAccountSource.CANVAS)} />
      }
      {brightspaceImportHelper &&
        <ExternalClassImportButton
          iconUrl={brightspaceLogo}
          btnClass="btnBrightspaceSections"
          importText="courses from Brightspace"
          onClick={() => openExternalClassImportModal(UserAccountSource.BRIGHTSPACE)} />
      }
      {oneRosterImportHelper &&
        <ExternalClassImportButton
          btnClass="btnOneRosterSections"
          importText="classes from your district's SIS"
          onClick={() => openExternalClassImportModal(UserAccountSource.ONEROSTER)} />
      }
      {schoologyImportHelper &&
        <ExternalClassImportButton
          iconUrl={schoologyLogo}
          btnClass="btnSchoologySections"
          importText="courses from Schoology"
          onClick={() => openExternalClassImportModal(UserAccountSource.SCHOOLOGY)} />
      }
      {achieve3KImportHelper &&
        <ExternalClassImportButton
          iconUrl={achieve3000Logo}
          btnClass="btnAchieve3K"
          importText="courses from Achieve3000"
          onClick={() => openExternalClassImportModal(UserAccountSource.ACHIEVE3K)} />
      }
    </React.Fragment>
  );
}

const ExternalClassImportButton = ({
  iconUrl = '',
  btnClass,
  onClick,
  importText,
}) => {
  return (
    <React.Fragment>
      <Button
        className={`${btnClass} ${styles.classImportButton}`}
        variant="secondary"
        onClick={onClick}
        type="reset"
      >
        {!!iconUrl && <img src={iconUrl} alt="" />}
        Import {!bowser.mobile ? " " + importText : ""}
      </Button>
      <div className={`${styles.divider}`}>OR</div>
    </React.Fragment>
  );
};
