import React from 'react'
import styles from './styles.module.css';
import { getDataPlain } from '../../actions/api';
import HeaderLayout from '../common/header/headerLayout.component';
import { capitalize } from '../../utils/common';
import Tile from './tile'
import Strand from '../catalog/strand';
import ExitButton from '../common/exitButton/container';
import { PageView, Role } from '../../utils/constants';

class Reveal extends React.Component<any, any> {
  state = {
    bookTitle: null,
    problemSets: [],
    books: [],
    openBook: false,
  };

  async componentDidMount() {
    const bookTitleSlug: any = this.props.match.params.bookTitleSlug || null;

    if (bookTitleSlug) {
      const bookTitle = bookTitleSlug.replaceAll('-', ' ');
      const data = await getDataPlain({
        type: 'REVEAL_BOOK_UNITS',
        params: { bookTitleSlug }
      });
      const { problemSets, assignedProblemSetIds } = data;
      this.props.setAssignedProblemSets(assignedProblemSetIds);
      this.props.setRevealBookTitleSlug(bookTitleSlug);
      this.setState({ bookTitle, problemSets, openBook: true });
    } 
    else {
      const books = await getDataPlain({
        type: 'REVEAL_BOOKS'
      });
      this.setState({ bookTitle: null, books, openBook: false });
    }
  }

  render() {
    return (
      <div>
        {this.state.openBook 
          ? (
            <BookProblemSets
              bookTitle={this.state.bookTitle}
              problemSets={this.state.problemSets}
            />
          )
          : (
            <BooksSeries 
              books={this.state.books}
            />
          )
        }
      </div>
    )
  }
};

const BooksSeries = (props: {
  books: any[];
}): JSX.Element => {
  return (
    <React.Fragment>
      <main className={styles.wrapper}>
        {props.books.map((book: any) => 
          <Tile 
            key={book.titleSlug} 
            title={book.title} 
            src={book.coverArtUrl} 
            titleSlug={book.titleSlug}
          />
        )}
      </main>
    </React.Fragment>
  );
};

const BookProblemSets = (props: {
  bookTitle: any;
  problemSets: any[];
}): JSX.Element => {
  return (
    <React.Fragment>
      <div className={styles.headerWrapper}>
        <ExitButton
          validRoles={[Role.STUDENT, Role.TEACHER, Role.SUPERADMIN]}
          pageView={PageView.REVEAL}
          revealBookTitle=""
          className="btnHeaderExitReveal"
        />
        <HeaderLayout
          title={`Reveal Math: ${capitalize(props.bookTitle || '')}`}
          headerClassName={styles.headerLayout}
        >
          <span></span>
        </HeaderLayout>
      </div>
      <main className={styles.contentWrapper}>
        {props.problemSets.map((x: any) => 
          <Strand 
            key={x.name} 
            name={x.name} 
            problemSets={x.problemSets} 
            isReveal={true} 
          /> 
        )}
      </main>
    </React.Fragment> 
  );
};

export default Reveal;