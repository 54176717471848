import React from 'react';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  small: {
    fontSize: '16px !important',
  },
  big: {
    fontSize: '24px !important',
  },
  divider: {
    fontSize: 20,
    fontWeight: 'bold',
    margin: '20px 0px 0px',
    overflow: 'hidden',
    textAlign: 'center',

    '&:before': {
      content: '""',
      verticalAlign: 'top',
      display: 'inline-block',
      width: '6%',
      height: '.8em',
      borderBottom: '1px solid #000',
      margin: '0 2% 0 -55%',
    },
    '&:after': {
      content: '""',
      verticalAlign: 'top',
      display: 'inline-block',
      width: '6%',
      height: '.8em',
      borderBottom: '1px solid #000',
      margin: '0 -55% 0 2%',
    },
  },
  text: {
    maxWidth: '80%',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
})

interface DividerTextProps {
  text: string | JSX.Element;
  big?: boolean;
  small?: boolean;
  className?: string;
}

const DividerText: React.FunctionComponent<DividerTextProps> = (props) => {
  const { text, big, small, className = '' } = props;
  const styles = useStyles();

  return (
    <div className={classnames(styles.divider, {
      [styles.big]: big,
      [styles.small]: small,
      [className]: !!className
    })}>
      <span className={styles.text}>{text}</span>
    </div>
  );
}

export default DividerText;
