import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { Fonts } from "../../helpers/constants";

const useStyles = makeStyles({
  scorecard: {
    boxSizing: "border-box",
    display: "inline-block",
    maxWidth: "300px",
    padding: 20,
    textAlign: "center",
    wordBreak: "break-word",
  },
  description: {
    fontFamily: Fonts.REGULAR,
    fontSize: 18,
  },
  value: {
    fontFamily: Fonts.BOLD,
    fontSize: 36,
    margin: 0,
  },
});

interface ScorecardProps {
  className?: string;
  description: string;
  value: string | string[];
}

const Scorecard: React.FunctionComponent<ScorecardProps> = (props) => {
  const { className, value, description } = props;
  const styles = useStyles();

  const renderValue = () => {
    if (Array.isArray(value)) {
      return value.map((v, index) => {
        return <p key={`${index}_${v}`} className={styles.value}>{v}</p>;
      });
    } else {
      return <p className={styles.value}>{value}</p>;
    }
  };

  return (
    <div className={classnames(styles.scorecard, className)}>
      {renderValue()}
      <p className={styles.description}>{description}</p>
    </div>
  );
};

export default Scorecard;
