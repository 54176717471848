import Catalog from './component';
import { connect } from 'react-redux';
import { getData, postData } from '../../actions/api';

const mapStateToProps = (state: any) => {
  return {
    data: state.catalogData.data,
    benchmarks: state.catalogData.benchmarks,
    gradeLevelId: state.userData.gradeLevelId,
    stateAbbreviation: state.userData.stateAbbreviation,
    favoriteProblemSetIds: state.catalogData.favoriteProblemSetIds,
    filters: state.catalogData.filters,
    userId: state.userData.userId,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setFilters: (updates: any) => {
      dispatch({
        type: 'UPDATE_CATALOG_FILTERS',
        body: updates,
      });
    },
    fetchData: ({ gradeLevel, searchTerm }: { gradeLevel: string; searchTerm: string }) => {
      dispatch(getData({
        type: 'CATALOG_DATA',
        params: {
          gradeLevel,
          searchTerm,
        },
      }));
    },
    toggleFavorite: ({
      problemSetIds, isFavorited
    }: { problemSetIds: number[]; isFavorited: boolean }) => {
      dispatch(postData({
        type: 'FAVORITE_PROBLEM_SET',
        params: {
          problemSetIds,
          isFavorited,
        },
      }));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Catalog);
