import React from 'react';
import DayJsUtils from '@date-io/dayjs';
import { Dayjs } from 'dayjs';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';

const activelyLearnTheme = createMuiTheme({
  typography: {
    fontFamily: '\"WorkSans-Medium\", \"Helvetica\", \"Arial\", sans-serif',
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: "#0077d1",

        "&$focused": {
          color: "#0077d1",
        },
      },
    },
    MuiInput: {
      root: {
        fontSize: 14,
      },
      underline: {
        "&:after": {
          borderBottom: "2px solid #0077d1",
        },
      },
    },
    MuiButton: {
      label: {
        color: "#0077d1",
      },
    },
  },
});

interface IDisabledDatePickerPickerProps {
  label: string;
  onChange: Function;
  selectedDate: Dayjs;
  minDate?: Dayjs;
  maxDate?: Dayjs;
}

const DisabledDatePicker: React.FunctionComponent<IDisabledDatePickerPickerProps> = (props) => {
  const { label, selectedDate, onChange, minDate, maxDate } = props;

  return (
    <ThemeProvider theme={activelyLearnTheme}>
      <MuiPickersUtilsProvider utils={DayJsUtils}>
        <DatePicker
          label={label}
          inputProps={{
            "aria-label": label,
          }}
          value={selectedDate}
          format="MMM DD, YYYY"
          onChange={(date) => onChange(date)}
          minDate={minDate}
          maxDate={maxDate}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default DisabledDatePicker;